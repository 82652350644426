html,
body {
	/* For non-webkit */
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);

	/* Prevent Safari from allowing you to overscroll the page/app */
	overscroll-behavior: none;
}

/* These are necessary because webkit doesn't support `scrollbar-color` */
::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: var(--scrollbar-track);
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: var(--scrollbar-thumb);
}

div[id^="DndLiveRegion"] {
	/* This is a hack to prevent the @dnd-kit/core DndContext live region from affecting the scroll height of the navigation pane */
	top: 0;
}
