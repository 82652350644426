.theme-dark {
	/* ====================================================
    ========== FIXED > DO NOT EDIT THIS SECTION! ==========
    ==================================================== */

	--brand-primary-light: theme(colors.indigo.400);
	--brand-primary-dark: theme(colors.indigo.950);

	--brand-primary: theme(colors.indigo.600);
	--brand-primary-hover: theme(colors.indigo.700);
	--brand-primary-focus: theme(colors.indigo.800);
	--brand-primary-active: theme(colors.indigo.900);
	--brand-primary-disabled: theme(colors.indigo.600 / 60%);

	--brand-primary-translucent: theme(colors.indigo.500 / 8%);
	--brand-primary-translucent-hover: theme(colors.indigo.500 / 10%);
	--brand-primary-translucent-focus: theme(colors.indigo.500 / 12%);
	--brand-primary-translucent-active: theme(colors.indigo.500 / 16%);
	--brand-primary-translucent-disabled: theme(colors.indigo.500 / 5%);

	--brand-primary-translucent-dark: theme(colors.indigo.800 / 80%);
	--brand-primary-translucent-dark-hover: theme(colors.indigo.800 / 84%);
	--brand-primary-translucent-dark-focus: theme(colors.indigo.800 / 88%);
	--brand-primary-translucent-dark-active: theme(colors.indigo.800 / 92%);
	--brand-primary-translucent-dark-disabled: theme(colors.indigo.800 / 70%);

	--text-fixed-light-default: theme(colors.white / 89%);
	--text-fixed-light-hover: theme(colors.white / 92%);
	--text-fixed-light-focus: theme(colors.white / 92%);
	--text-fixed-light-active: theme(colors.white / 95%);
	--text-fixed-light-disabled: theme(colors.white / 35%);

	--text-fixed-dark-default: theme(colors.black / 85%);
	--text-fixed-dark-hover: theme(colors.black / 88%);
	--text-fixed-dark-focus: theme(colors.black / 88%);
	--text-fixed-dark-active: theme(colors.black / 91%);
	--text-fixed-dark-disabled: theme(colors.black / 75%);

	--icon-fixed-light-default: theme(colors.white / 75%);
	--icon-fixed-light-hover: theme(colors.white / 85%);
	--icon-fixed-light-focus: theme(colors.white / 85%);
	--icon-fixed-light-active: theme(colors.white / 90%);
	--icon-fixed-light-disabled: theme(colors.white / 50%);

	--icon-fixed-dark-default: theme(colors.black / 40%);
	--icon-fixed-dark-hover: theme(colors.black / 50%);
	--icon-fixed-dark-focus: theme(colors.black / 60%);
	--icon-fixed-dark-active: theme(colors.black / 90%);
	--icon-fixed-dark-disabled: theme(colors.black / 20%);

	--bg-fixed-dark-translucent-primary: theme(colors.black / 60%);
	--bg-fixed-dark-translucent-secondary: theme(colors.black / 70%);
	--bg-fixed-dark-translucent-tertiary: theme(colors.black / 80%);
	--bg-fixed-dark-translucent-quaternary: theme(colors.black / 90%);
	--bg-fixed-dark-translucent-quinary: theme(colors.black / 95%);

	--border-interactive-fixed-light: theme(colors.white / 8%);
	--border-interactive-fixed-dark: theme(colors.black / 12%);

	--state-negative: theme(colors.rose.600);
	--state-negative-hover: theme(colors.rose.700);
	--state-negative-focus: theme(colors.rose.800);
	--state-negative-active: theme(colors.rose.900);
	--state-negative-disabled: theme(colors.rose.600 / 60%);

	--state-positive: theme(colors.emerald.600);
	--state-positive-hover: theme(colors.emerald.700);
	--state-positive-focus: theme(colors.emerald.800);
	--state-positive-active: theme(colors.emerald.900);
	--state-positive-disabled: theme(colors.emerald.600 / 60%);

	--state-warning: theme(colors.amber.500);
	--state-warning-hover: theme(colors.amber.600);
	--state-warning-focus: theme(colors.amber.700);
	--state-warning-active: theme(colors.amber.800);
	--state-warning-disabled: theme(colors.amber.500 / 50%);

	--state-info: theme(colors.sky.600);
	--state-info-hover: theme(colors.sky.700);
	--state-info-focus: theme(colors.sky.800);
	--state-info-active: theme(colors.sky.900);
	--state-info-disabled: theme(colors.sky.600);

	/* ======== BEGIN EDITABLE SECTION BELOW ======== */

	/* ============================================================================================
	 * BRAND COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #4F46E5
	 *   - gray color #8B8D98
	 *   - background color #0F172A
	 * ========================================================================================= */

	/* Normal sRBG palette. */
	--palette-brand-1: #141724;
	--palette-brand-2: #16182b;
	--palette-brand-3: #1f2251;
	--palette-brand-4: #272671;
	--palette-brand-5: #2f2f82;
	--palette-brand-6: #383a90;
	--palette-brand-7: #4347a5;
	--palette-brand-8: #5054c4;
	--palette-brand-9: #4f46e5;
	--palette-brand-10: #4434d4;
	--palette-brand-11: #a0adff;
	--palette-brand-12: #d9e0ff;

	/* Alpha palette. */
	--palette-brand-alpha1: #e4180006;
	--palette-brand-alpha2: #ef374a08;
	--palette-brand-alpha3: #6653fe2f;
	--palette-brand-alpha4: #5744ff55;
	--palette-brand-alpha5: #5c51fd6a;
	--palette-brand-alpha6: #6460fd7b;
	--palette-brand-alpha7: #6969fe94;
	--palette-brand-alpha8: #696cfeb9;
	--palette-brand-alpha9: #584cffe0;
	--palette-brand-alpha10: #513bffcc;
	--palette-brand-alpha11: #a0adff;
	--palette-brand-alpha12: #d9e0ff;

	--palette-brand-contrast: #fff;
	--palette-brand-surface: #1d192c80;
	--palette-brand-indicator: #4f46e5;
	--palette-brand-track: #4f46e5;

	/* Wide-gamut palette for, e.g., Apple P3 displays. Alpha blending and saturation work
	differently on these devices. */
	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			/* Normal P3 palette. */
			--palette-brand-1: oklch(20.8% 0.0274 277);
			--palette-brand-2: oklch(21.8% 0.0365 277);
			--palette-brand-3: oklch(27.8% 0.0855 277);
			--palette-brand-4: oklch(32.1% 0.1258 277);
			--palette-brand-5: oklch(36% 0.1355 277);
			--palette-brand-6: oklch(40% 0.139 277);
			--palette-brand-7: oklch(44.9% 0.1495 277);
			--palette-brand-8: oklch(50.7% 0.1717 277);
			--palette-brand-9: oklch(51.1% 0.2301 277);
			--palette-brand-10: oklch(46.1% 0.2301 277);
			--palette-brand-11: oklch(77.6% 0.162 277);
			--palette-brand-12: oklch(91.4% 0.0521 277);

			/* Alpha palette. */
			--palette-brand-a1: color(display-p3 0.9922 0.0941 0 / 0.017);
			--palette-brand-a2: color(display-p3 0.9608 0.2706 0.3412 / 0.022);
			--palette-brand-a3: color(display-p3 0.4078 0.3412 1 / 0.173);
			--palette-brand-a4: color(display-p3 0.3373 0.2745 1 / 0.318);
			--palette-brand-a5: color(display-p3 0.3686 0.3333 1 / 0.393);
			--palette-brand-a6: color(display-p3 0.4039 0.3922 1 / 0.458);
			--palette-brand-a7: color(display-p3 0.4275 0.4314 1 / 0.552);
			--palette-brand-a8: color(display-p3 0.4314 0.4353 1 / 0.692);
			--palette-brand-a9: color(display-p3 0.349 0.3098 1 / 0.837);
			--palette-brand-a10: color(display-p3 0.3137 0.2471 1 / 0.762);
			--palette-brand-a11: color(display-p3 0.6549 0.6941 1 / 0.972);
			--palette-brand-a12: color(display-p3 0.8667 0.8902 1 / 0.986);

			--palette-brand-contrast: #fff;
			--palette-brand-surface: color(display-p3 0.102 0.0941 0.1647 / 0.5);
			--palette-brand-indicator: oklch(51.1% 0.2301 277);
			--palette-brand-track: oklch(51.1% 0.2301 277);
		}
	}

	/* ============================================================================================
	 * CONTRAST COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #CBD5E1
	 *   - gray color #8B8D98
	 *   - background color #0F172A
	 * ========================================================================================= */

	--palette-brand-contrast-1: #14181d;
	--palette-brand-contrast-2: #161b21;
	--palette-brand-contrast-3: #1e2937;
	--palette-brand-contrast-4: #283442;
	--palette-brand-contrast-5: #333f4e;
	--palette-brand-contrast-6: #3f4b5a;
	--palette-brand-contrast-7: #4c5968;
	--palette-brand-contrast-8: #5c6979;
	--palette-brand-contrast-9: #cbd5e1;
	--palette-brand-contrast-10: #c1cbd7;
	--palette-brand-contrast-11: #a8b7c9;
	--palette-brand-contrast-12: #d8e2ee;

	--palette-brand-contrast-alpha1: #e4420006;
	--palette-brand-contrast-alpha2: #ef970008;
	--palette-brand-contrast-alpha3: #cffdd014;
	--palette-brand-contrast-alpha4: #d7ffea20;
	--palette-brand-contrast-alpha5: #e0fffb2c;
	--palette-brand-contrast-alpha6: #e2fcfe3a;
	--palette-brand-contrast-alpha7: #def8fd4b;
	--palette-brand-contrast-alpha8: #def4ff5f;
	--palette-brand-contrast-alpha9: #e9f3fedc;
	--palette-brand-contrast-alpha10: #e9f4fed0;
	--palette-brand-contrast-alpha11: #dbecfebf;
	--palette-brand-contrast-alpha12: #e9f3ffeb;

	--palette-brand-contrast-contrast: #132334;
	--palette-brand-contrast-surface: #1d1f1980;
	--palette-brand-contrast-indicator: #cbd5e1;
	--palette-brand-contrast-track: #cbd5e1;

	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-brand-contrast-1: oklch(20.8% 0.0112 252.9);
			--palette-brand-contrast-2: oklch(22.1% 0.0134 252.9);
			--palette-brand-contrast-3: oklch(27.9% 0.0298 252.9);
			--palette-brand-contrast-4: oklch(32.1% 0.0298 252.9);
			--palette-brand-contrast-5: oklch(36.4% 0.0298 252.9);
			--palette-brand-contrast-6: oklch(40.9% 0.0298 252.9);
			--palette-brand-contrast-7: oklch(45.9% 0.0298 252.9);
			--palette-brand-contrast-8: oklch(51.6% 0.0298 252.9);
			--palette-brand-contrast-9: oklch(86.9% 0.0198 252.9);
			--palette-brand-contrast-10: oklch(83.8% 0.0198 252.9);
			--palette-brand-contrast-11: oklch(77.4% 0.0298 252.9);
			--palette-brand-contrast-12: oklch(91% 0.0198 252.9);

			--palette-brand-contrast-alpha1: color(display-p3 0.9922 0.3216 0 / 0.017);
			--palette-brand-contrast-alpha2: color(display-p3 0.9725 0.6941 0 / 0.026);
			--palette-brand-contrast-alpha3: color(display-p3 0.8235 0.9961 0.8157 / 0.078);
			--palette-brand-contrast-alpha4: color(display-p3 0.851 1 0.9137 / 0.125);
			--palette-brand-contrast-alpha5: color(display-p3 0.8863 1 0.9804 / 0.173);
			--palette-brand-contrast-alpha6: color(display-p3 0.9059 1 1 / 0.225);
			--palette-brand-contrast-alpha7: color(display-p3 0.8941 0.9843 1 / 0.29);
			--palette-brand-contrast-alpha8: color(display-p3 0.8824 0.9608 1 / 0.37);
			--palette-brand-contrast-alpha9: color(display-p3 0.9294 0.9608 1 / 0.856);
			--palette-brand-contrast-alpha10: color(display-p3 0.9294 0.9647 1 / 0.809);
			--palette-brand-contrast-alpha11: color(display-p3 0.8863 0.9412 1 / 0.739);
			--palette-brand-contrast-alpha12: color(display-p3 0.9294 0.9608 1 / 0.916);

			--palette-brand-contrast-contrast: #132334;
			--palette-brand-contrast-surface: color(display-p3 0.1098 0.1176 0.0902 / 0.5);
			--palette-brand-contrast-indicator: oklch(86.9% 0.0198 252.9);
			--palette-brand-contrast-track: oklch(86.9% 0.0198 252.9);
		}
	}

	/* ============================================================================================
	 * GRAY COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #9DA7D5
	 *   - gray color #8B8D98
	 *   - background color #0F172A
	 * ========================================================================================= */

	--palette-gray-1: #16171f;
	--palette-gray-2: #1a1c25;
	--palette-gray-3: #222639;
	--palette-gray-4: #282d48;
	--palette-gray-5: #2f3554;
	--palette-gray-6: #383e60;
	--palette-gray-7: #444b71;
	--palette-gray-8: #565f8d;
	--palette-gray-9: #9da7d5;
	--palette-gray-10: #929cc9;
	--palette-gray-11: #a8b2e1;
	--palette-gray-12: #e4e8f8;

	--palette-gray-alpha1: #ef180008;
	--palette-gray-alpha2: #f982000c;
	--palette-gray-alpha3: #f6cee015;
	--palette-gray-alpha4: #c1b3ff24;
	--palette-gray-alpha5: #afadfc33;
	--palette-gray-alpha6: #b0b0fe41;
	--palette-gray-alpha7: #aeb3ff55;
	--palette-gray-alpha8: #a8b2ff77;
	--palette-gray-alpha9: #c0caffcd;
	--palette-gray-alpha10: #bec8febf;
	--palette-gray-alpha11: #c0cbfedc;
	--palette-gray-alpha12: #ebeefff7;

	--palette-gray-contrast: #fff;
	--palette-gray-surface: #25212180;
	--palette-gray-indicator: #9da7d5;
	--palette-gray-track: #9da7d5;

	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-gray-1: oklch(20.8% 0.0149 275.1);
			--palette-gray-2: oklch(23% 0.0168 275.1);
			--palette-gray-3: oklch(27.4% 0.0362 275.1);
			--palette-gray-4: oklch(30.7% 0.0488 275.1);
			--palette-gray-5: oklch(33.9% 0.0551 275.1);
			--palette-gray-6: oklch(37.6% 0.0588 275.1);
			--palette-gray-7: oklch(42.4% 0.0646 275.1);
			--palette-gray-8: oklch(49.7% 0.0751 275.1);
			--palette-gray-9: oklch(73.7% 0.0676 275.1);
			--palette-gray-10: oklch(70.1% 0.0676 275.1);
			--palette-gray-11: oklch(77.3% 0.0676 275.1);
			--palette-gray-12: oklch(93.2% 0.0222 275.1);

			--palette-gray-alpha1: color(display-p3 0.9608 0.0941 0 / 0.022);
			--palette-gray-alpha2: color(display-p3 0.9961 0.6078 0 / 0.038);
			--palette-gray-alpha3: color(display-p3 0.9961 0.8667 0.9373 / 0.076);
			--palette-gray-alpha4: color(display-p3 0.7608 0.7255 1 / 0.136);
			--palette-gray-alpha5: color(display-p3 0.702 0.702 1 / 0.192);
			--palette-gray-alpha6: color(display-p3 0.7137 0.7216 1 / 0.243);
			--palette-gray-alpha7: color(display-p3 0.698 0.7216 1 / 0.323);
			--palette-gray-alpha8: color(display-p3 0.6824 0.7098 1 / 0.454);
			--palette-gray-alpha9: color(display-p3 0.7725 0.8078 1 / 0.786);
			--palette-gray-alpha10: color(display-p3 0.7686 0.8078 1 / 0.729);
			--palette-gray-alpha11: color(display-p3 0.7765 0.8118 1 / 0.842);
			--palette-gray-alpha12: color(display-p3 0.9294 0.9412 0.9961 / 0.963);

			--palette-gray-contrast: #fff;
			--palette-gray-surface: color(display-p3 0.1412 0.1255 0.1216 / 0.5);
			--palette-gray-indicator: oklch(73.7% 0.0676 275.1);
			--palette-gray-track: oklch(73.7% 0.0676 275.1);
		}
	}

	/* ============================================================================================
	 * NEGATIVE COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #E11D48
	 *   - gray color #8B8D98
	 *   - background color #0F172A
	 * ========================================================================================= */

	--palette-negative-1: #1e1515;
	--palette-negative-2: #241717;
	--palette-negative-3: #401217;
	--palette-negative-4: #550a18;
	--palette-negative-5: #660f1f;
	--palette-negative-6: #771d2a;
	--palette-negative-7: #912c38;
	--palette-negative-8: #bd3a4a;
	--palette-negative-9: #e11d48;
	--palette-negative-10: #bc394a;
	--palette-negative-11: #ff8e95;
	--palette-negative-12: #ffd0d0;

	--palette-negative-alpha1: #ff000010;
	--palette-negative-alpha2: #f8170017;
	--palette-negative-alpha3: #fb000035;
	--palette-negative-alpha4: #fd00004b;
	--palette-negative-alpha5: #fd020c5d;
	--palette-negative-alpha6: #fe252a6f;
	--palette-negative-alpha7: #fe3e448b;
	--palette-negative-alpha8: #ff4856b9;
	--palette-negative-alpha9: #fe1e4ce0;
	--palette-negative-alpha10: #ff4756b8;
	--palette-negative-alpha11: #ff8e95;
	--palette-negative-alpha12: #ffd0d0;

	--palette-negative-contrast: #fff;
	--palette-negative-surface: #39170580;
	--palette-negative-indicator: #e11d48;
	--palette-negative-track: #e11d48;

	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-negative-1: oklch(20.8% 0.0153 17.58);
			--palette-negative-2: oklch(22% 0.0215 17.58);
			--palette-negative-3: oklch(26% 0.0714 17.58);
			--palette-negative-4: oklch(29.5% 0.1059 17.58);
			--palette-negative-5: oklch(33.4% 0.1183 17.58);
			--palette-negative-6: oklch(38.3% 0.124 17.58);
			--palette-negative-7: oklch(45% 0.1357 17.58);
			--palette-negative-8: oklch(54.4% 0.1668 17.58);
			--palette-negative-9: oklch(58.6% 0.222 17.58);
			--palette-negative-10: oklch(54.2% 0.1668 17.58);
			--palette-negative-11: oklch(78.5% 0.1849 17.58);
			--palette-negative-12: oklch(90.4% 0.0602 17.58);

			--palette-negative-alpha1: color(display-p3 0.9922 0 0 / 0.051);
			--palette-negative-alpha2: color(display-p3 0.9961 0.1451 0 / 0.072);
			--palette-negative-alpha3: color(display-p3 1 0.0471 0 / 0.177);
			--palette-negative-alpha4: color(display-p3 0.9961 0.0157 0 / 0.257);
			--palette-negative-alpha5: color(display-p3 0.9961 0.102 0.0667 / 0.32);
			--palette-negative-alpha6: color(display-p3 1 0.2235 0.1922 / 0.387);
			--palette-negative-alpha7: color(display-p3 1 0.3216 0.298 / 0.492);
			--palette-negative-alpha8: color(display-p3 1 0.3569 0.3725 / 0.664);
			--palette-negative-alpha9: color(display-p3 1 0.2392 0.3333 / 0.799);
			--palette-negative-alpha10: color(display-p3 1 0.3529 0.3765 / 0.66);
			--palette-negative-alpha11: color(display-p3 1 0.6157 0.6196 / 0.937);
			--palette-negative-alpha12: color(display-p3 1 0.8431 0.8392 / 0.971);

			--palette-negative-contrast: #fff;
			--palette-negative-surface: color(display-p3 0.1961 0.0863 0.0196 / 0.5);
			--palette-negative-indicator: oklch(58.6% 0.222 17.58);
			--palette-negative-track: oklch(58.6% 0.222 17.58);
		}
	}

	/* =========================
    ========== Global ==========
    ========================= */

	/* Negative, Positive, Warning, Info */

	--state-negative-light: theme(colors.rose.950);
	--state-negative-light-hover: theme(colors.rose.900);
	--state-negative-light-focus: theme(colors.rose.800);
	--state-negative-light-active: theme(colors.rose.700);
	--state-negative-light-disabled: theme(colors.rose.900 / 60%);

	--state-positive-light: theme(colors.emerald.950);
	--state-positive-light-hover: theme(colors.emerald.900);
	--state-positive-light-focus: theme(colors.emerald.800);
	--state-positive-light-active: theme(colors.emerald.700);
	--state-positive-light-disabled: theme(colors.emerald.900 / 60%);

	--state-warning-light: theme(colors.amber.300 / 40%);
	--state-warning-light-hover: theme(colors.amber.500 / 50%);
	--state-warning-light-focus: theme(colors.amber.800);
	--state-warning-light-active: theme(colors.amber.700);
	--state-warning-light-disabled: theme(colors.amber.900 / 60%);

	--state-info-light: theme(colors.sky.950);
	--state-info-light-hover: theme(colors.sky.900);
	--state-info-light-focus: theme(colors.sky.800);
	--state-info-light-active: theme(colors.sky.700);
	--state-info-light-disabled: theme(colors.sky.800 / 60%);

	/* Negative, Positive, Warning, Info - MID */

	--state-negative-mid: theme(colors.rose.800);
	--state-negative-mid-hover: theme(colors.rose.700);
	--state-negative-mid-focus: theme(colors.rose.600);
	--state-negative-mid-active: theme(colors.rose.500);
	--state-negative-mid-disabled: theme(colors.rose.700 / 60%);

	--state-positive-mid: theme(colors.emerald.800);
	--state-positive-mid-hover: theme(colors.emerald.700);
	--state-positive-mid-focus: theme(colors.emerald.600);
	--state-positive-mid-active: theme(colors.emerald.500);
	--state-positive-mid-disabled: theme(colors.emerald.700 / 60%);

	--state-warning-mid: theme(colors.amber.600 / 40%);
	--state-warning-mid-hover: theme(colors.amber.700);
	--state-warning-mid-focus: theme(colors.amber.600);
	--state-warning-mid-active: theme(colors.amber.500);
	--state-warning-mid-disabled: theme(colors.amber.700 / 60%);

	--state-info-mid: theme(colors.sky.800);
	--state-info-mid-hover: theme(colors.sky.700);
	--state-info-mid-focus: theme(colors.sky.600);
	--state-info-mid-active: theme(colors.sky.500);
	--state-info-mid-disabled: theme(colors.sky.700 / 60%);

	/* Full color spectrum */

	--rainbow-light-0-solid: theme(colors.red.600);
	--rainbow-light-1-solid: theme(colors.orange.600);
	--rainbow-light-2-solid: theme(colors.amber.600);
	--rainbow-light-3-solid: theme(colors.yellow.600);
	--rainbow-light-4-solid: theme(colors.lime.600);
	--rainbow-light-5-solid: theme(colors.green.600);
	--rainbow-light-6-solid: theme(colors.emerald.600);
	--rainbow-light-7-solid: theme(colors.teal.600);
	--rainbow-light-8-solid: theme(colors.cyan.600);
	--rainbow-light-9-solid: theme(colors.sky.600);
	--rainbow-light-10-solid: theme(colors.blue.600);
	--rainbow-light-11-solid: theme(colors.indigo.600);
	--rainbow-light-12-solid: theme(colors.violet.600);
	--rainbow-light-13-solid: theme(colors.purple.600);
	--rainbow-light-14-solid: theme(colors.fuchsia.600);
	--rainbow-light-15-solid: theme(colors.pink.600);
	--rainbow-light-16-solid: theme(colors.rose.600);

	--rainbow-dark-0-solid: theme(colors.red.100);
	--rainbow-dark-1-solid: theme(colors.orange.100);
	--rainbow-dark-2-solid: theme(colors.amber.100);
	--rainbow-dark-3-solid: theme(colors.yellow.100);
	--rainbow-dark-4-solid: theme(colors.lime.100);
	--rainbow-dark-5-solid: theme(colors.green.100);
	--rainbow-dark-6-solid: theme(colors.emerald.100);
	--rainbow-dark-7-solid: theme(colors.teal.100);
	--rainbow-dark-8-solid: theme(colors.cyan.100);
	--rainbow-dark-9-solid: theme(colors.sky.100);
	--rainbow-dark-10-solid: theme(colors.blue.100);
	--rainbow-dark-11-solid: theme(colors.indigo.100);
	--rainbow-dark-12-solid: theme(colors.violet.100);
	--rainbow-dark-13-solid: theme(colors.purple.100);
	--rainbow-dark-14-solid: theme(colors.fuchsia.100);
	--rainbow-dark-15-solid: theme(colors.pink.100);
	--rainbow-dark-16-solid: theme(colors.rose.100);

	--rainbow-lighter-0-solid: theme(colors.red.600);
	--rainbow-lighter-1-solid: theme(colors.orange.600);
	--rainbow-lighter-2-solid: theme(colors.amber.600);
	--rainbow-lighter-3-solid: theme(colors.yellow.600);
	--rainbow-lighter-4-solid: theme(colors.lime.600);
	--rainbow-lighter-5-solid: theme(colors.green.600);
	--rainbow-lighter-6-solid: theme(colors.emerald.600);
	--rainbow-lighter-7-solid: theme(colors.teal.600);
	--rainbow-lighter-8-solid: theme(colors.cyan.600);
	--rainbow-lighter-9-solid: theme(colors.sky.600);
	--rainbow-lighter-10-solid: theme(colors.blue.600);
	--rainbow-lighter-11-solid: theme(colors.indigo.600);
	--rainbow-lighter-12-solid: theme(colors.violet.600);
	--rainbow-lighter-13-solid: theme(colors.purple.600);
	--rainbow-lighter-14-solid: theme(colors.fuchsia.600);
	--rainbow-lighter-15-solid: theme(colors.pink.600);
	--rainbow-lighter-16-solid: theme(colors.rose.600);

	--rainbow-light-0-translucent: theme(colors.red.500 / 20%);
	--rainbow-light-1-translucent: theme(colors.orange.500 / 20%);
	--rainbow-light-2-translucent: theme(colors.amber.500 / 20%);
	--rainbow-light-3-translucent: theme(colors.yellow.500 / 20%);
	--rainbow-light-4-translucent: theme(colors.lime.500 / 20%);
	--rainbow-light-5-translucent: theme(colors.green.500 / 20%);
	--rainbow-light-6-translucent: theme(colors.emerald.500 / 20%);
	--rainbow-light-7-translucent: theme(colors.teal.500 / 20%);
	--rainbow-light-8-translucent: theme(colors.cyan.500 / 20%);
	--rainbow-light-9-translucent: theme(colors.sky.500 / 20%);
	--rainbow-light-10-translucent: theme(colors.blue.500 / 20%);
	--rainbow-light-11-translucent: theme(colors.indigo.500 / 20%);
	--rainbow-light-12-translucent: theme(colors.violet.500 / 20%);
	--rainbow-light-13-translucent: theme(colors.purple.500 / 20%);
	--rainbow-light-14-translucent: theme(colors.fuchsia.500 / 20%);
	--rainbow-light-15-translucent: theme(colors.pink.500 / 20%);
	--rainbow-light-16-translucent: theme(colors.rose.500 / 20%);

	/* Dark and light spectrums */

	--light-spectrum-gray: theme(colors.gray[700]);
	--light-spectrum-gray-hover: theme(colors.gray[600]);
	--light-spectrum-gray-focus: theme(colors.gray[600]);
	--light-spectrum-gray-active: theme(colors.gray[500]);
	--light-spectrum-red: theme(colors.rose[900]);
	--light-spectrum-red-hover: theme(colors.rose[800]);
	--light-spectrum-red-focus: theme(colors.rose[800]);
	--light-spectrum-red-active: theme(colors.rose[700]);
	--light-spectrum-yellow: theme(colors.amber[900]);
	--light-spectrum-yellow-hover: theme(colors.amber[800]);
	--light-spectrum-yellow-focus: theme(colors.amber[800]);
	--light-spectrum-yellow-active: theme(colors.amber[700]);
	--light-spectrum-green: theme(colors.emerald[900]);
	--light-spectrum-green-hover: theme(colors.emerald[800]);
	--light-spectrum-green-focus: theme(colors.emerald[800]);
	--light-spectrum-green-active: theme(colors.emerald[700]);
	--light-spectrum-cyan: theme(colors.cyan[900]);
	--light-spectrum-cyan-hover: theme(colors.cyan[800]);
	--light-spectrum-cyan-focus: theme(colors.cyan[800]);
	--light-spectrum-cyan-active: theme(colors.cyan[700]);
	--light-spectrum-blue: theme(colors.blue[900]);
	--light-spectrum-blue-hover: theme(colors.blue[800]);
	--light-spectrum-blue-focus: theme(colors.blue[800]);
	--light-spectrum-blue-active: theme(colors.blue[700]);
	--light-spectrum-purple: theme(colors.violet[900]);
	--light-spectrum-purple-hover: theme(colors.violet[800]);
	--light-spectrum-purple-focus: theme(colors.violet[800]);
	--light-spectrum-purple-active: theme(colors.violet[700]);
	--light-spectrum-pink: theme(colors.fuchsia[900]);
	--light-spectrum-pink-hover: theme(colors.fuchsia[800]);
	--light-spectrum-pink-focus: theme(colors.fuchsia[800]);
	--light-spectrum-pink-active: theme(colors.fuchsia[700]);

	--dark-spectrum-gray: theme(colors.gray[400]);
	--dark-spectrum-gray-hover: theme(colors.gray[500]);
	--dark-spectrum-gray-focus: theme(colors.gray[500]);
	--dark-spectrum-gray-active: theme(colors.gray[600]);
	--dark-spectrum-red: theme(colors.rose[500]);
	--dark-spectrum-red-hover: theme(colors.rose[600]);
	--dark-spectrum-red-focus: theme(colors.rose[600]);
	--dark-spectrum-red-active: theme(colors.rose[700]);
	--dark-spectrum-yellow: theme(colors.amber[500]);
	--dark-spectrum-yellow-hover: theme(colors.amber[600]);
	--dark-spectrum-yellow-focus: theme(colors.amber[600]);
	--dark-spectrum-yellow-active: theme(colors.amber[700]);
	--dark-spectrum-green: theme(colors.emerald[500]);
	--dark-spectrum-green-hover: theme(colors.emerald[600]);
	--dark-spectrum-green-focus: theme(colors.emerald[600]);
	--dark-spectrum-green-active: theme(colors.emerald[700]);
	--dark-spectrum-cyan: theme(colors.cyan[500]);
	--dark-spectrum-cyan-hover: theme(colors.cyan[600]);
	--dark-spectrum-cyan-focus: theme(colors.cyan[600]);
	--dark-spectrum-cyan-active: theme(colors.cyan[700]);
	--dark-spectrum-blue: theme(colors.blue[500]);
	--dark-spectrum-blue-hover: theme(colors.blue[600]);
	--dark-spectrum-blue-focus: theme(colors.blue[600]);
	--dark-spectrum-blue-active: theme(colors.blue[700]);
	--dark-spectrum-purple: theme(colors.violet[500]);
	--dark-spectrum-purple-hover: theme(colors.violet[600]);
	--dark-spectrum-purple-focus: theme(colors.violet[600]);
	--dark-spectrum-purple-active: theme(colors.violet[700]);
	--dark-spectrum-pink: theme(colors.fuchsia[500]);
	--dark-spectrum-pink-hover: theme(colors.fuchsia[600]);
	--dark-spectrum-pink-focus: theme(colors.fuchsia[600]);
	--dark-spectrum-pink-active: theme(colors.fuchsia[700]);

	/* ==============================
    ========== Focus Rings ==========
    ============================== */

	--focus-ring-default: theme(colors.indigo.600 / 30%);
	--focus-ring-negative: theme(colors.rose.600 / 30%);
	--focus-ring-positive: theme(colors.emerald.600 / 30%);
	--focus-ring-warning: theme(colors.amber.600 / 30%);

	/* ==============================
    ========== Backgrounds ==========
    ============================== */

	/* Main */

	--bg-primary-raised: theme(colors.gray.800);
	--bg-primary: theme(colors.gray.900);
	--bg-secondary: theme(colors.gray.950);
	--bg-tertiary: theme(colors.black);
	--bg-quaternary: theme(colors.black);
	--bg-quinary: theme(colors.black);

	/* --bg-primary: theme(colors.gray.800);
	--bg-secondary: theme(colors.gray.900);
	--bg-tertiary: theme(colors.gray.950);
	--bg-quaternary: theme(colors.black);
	--bg-quinary: theme(colors.black); */

	--bg-inverse-primary: theme(colors.gray.950);
	--bg-inverse-secondary: theme(colors.gray.900);
	--bg-inverse-tertiary: theme(colors.gray.800);
	--bg-inverse-quaternary: theme(colors.gray.700);
	--bg-inverse-quinary: theme(colors.gray.600);

	/* Modal */

	--bg-modal: theme(colors.white / 20%);

	/* Translucent */

	--bg-translucent-primary: theme(colors.white / 0%);
	--bg-translucent-secondary: theme(colors.white / 9%);
	--bg-translucent-tertiary: theme(colors.white / 12%);
	--bg-translucent-quaternary: theme(colors.white / 15%);
	--bg-translucent-quinary: theme(colors.white / 18%);

	--bg-inverse-translucent-primary: theme(colors.white / 60%);
	--bg-inverse-translucent-secondary: theme(colors.white / 70%);
	--bg-inverse-translucent-tertiary: theme(colors.white / 80%);
	--bg-inverse-translucent-quaternary: theme(colors.white / 90%);
	--bg-inverse-translucent-quinary: theme(colors.white / 95%);

	--bg-translucent-brand-default: theme(colors.indigo.400 / 0%);
	--bg-translucent-brand-hover: theme(colors.indigo.400 / 5%);
	--bg-translucent-brand-focus: theme(colors.indigo.400 / 4%);
	--bg-translucent-brand-active: theme(colors.indigo.400 / 10%);
	--bg-translucent-brand-disabled: theme(colors.indigo.400 / 0%);

	--bg-translucent-positive-default: theme(colors.emerald.400 / 0%);
	--bg-translucent-positive-hover: theme(colors.emerald.400 / 5%);
	--bg-translucent-positive-focus: theme(colors.emerald.400 / 10%);
	--bg-translucent-positive-active: theme(colors.emerald.400 / 15%);
	--bg-translucent-positive-disabled: theme(colors.emerald.400 / 0%);

	--bg-translucent-negative-default: theme(colors.rose.400 / 0%);
	--bg-translucent-negative-hover: theme(colors.rose.400 / 5%);
	--bg-translucent-negative-focus: theme(colors.rose.400 / 10%);
	--bg-translucent-negative-active: theme(colors.rose.400 / 15%);
	--bg-translucent-negative-disabled: theme(colors.rose.400 / 0%);

	--bg-translucent-warning-default: theme(colors.amber.400 / 0%);
	--bg-translucent-warning-hover: theme(colors.amber.400 / 5%);
	--bg-translucent-warning-focus: theme(colors.amber.400 / 10%);
	--bg-translucent-warning-active: theme(colors.amber.400 / 15%);
	--bg-translucent-warning-disabled: theme(colors.amber.400 / 0%);

	--bg-translucent-info-default: theme(colors.sky.400 / 0%);
	--bg-translucent-info-hover: theme(colors.sky.400 / 5%);
	--bg-translucent-info-focus: theme(colors.sky.400 / 10%);
	--bg-translucent-info-active: theme(colors.sky.400 / 15%);
	--bg-translucent-info-disabled: theme(colors.sky.400 / 0%);

	/* Miscellaneous */

	--bg-flash: theme(colors.emerald.800);

	--scrollbar-thumb: theme(colors.gray.600);
	--scrollbar-track: theme(colors.gray.800);

	/* ==========================
    ========== Borders ==========
    ========================== */

	--border-primary: theme(colors.gray.100 / 10%);
	--border-primary-hover: theme(colors.gray.100 / 15%);
	--border-primary-focus: theme(colors.gray.100 / 20%);
	--border-primary-active: theme(colors.gray.100 / 25%);
	--border-primary-disabled: theme(colors.gray.100 / 10%);

	--border-primary-solid: theme(colors.gray.800);
	--border-primary-solid-hover: theme(colors.gray.700);
	--border-primary-solid-focus: theme(colors.gray.600);
	--border-primary-solid-active: theme(colors.gray.500);
	--border-primary-solid-disabled: theme(colors.gray.800);

	--border-inverse-primary: theme(colors.gray.900 / 10%);
	--border-inverse-primary-hover: theme(colors.gray.900 / 15%);
	--border-inverse-primary-focus: theme(colors.gray.900 / 20%);
	--border-inverse-primary-active: theme(colors.gray.900 / 25%);
	--border-inverse-primary-disabled: theme(colors.gray.900 / 10%);

	--border-inverse-primary-solid: theme(colors.gray.300);
	--border-inverse-primary-solid-hover: theme(colors.gray.400);
	--border-inverse-primary-solid-focus: theme(colors.gray.500);
	--border-inverse-primary-solid-active: theme(colors.gray.600);
	--border-inverse-primary-solid-disabled: theme(colors.gray.400);

	--border-interactive-normal-default: theme(colors.gray.50 / 25%);
	--border-interactive-normal-hover: theme(colors.gray.50 / 30%);
	--border-interactive-normal-focus: theme(colors.indigo.500);
	--border-interactive-normal-active: theme(colors.indigo.400);
	--border-interactive-normal-disabled: theme(colors.gray.50 / 12%);

	--border-interactive-negative-default: theme(colors.rose.400 / 40%);
	--border-interactive-negative-hover: theme(colors.rose.400 / 45%);
	--border-interactive-negative-focus: var(--state-negative-focus);
	--border-interactive-negative-active: var(--state-negative-active);
	--border-interactive-negative-disabled: theme(colors.rose.400 / 20%);

	--border-interactive-positive-default: theme(colors.emerald.400 / 40%);
	--border-interactive-positive-hover: theme(colors.emerald.400 / 45%);
	--border-interactive-positive-focus: var(--state-positive-focus);
	--border-interactive-positive-active: var(--state-positive-active);
	--border-interactive-positive-disabled: theme(colors.emerald.400 / 20%);

	/* =======================
    ========== Text ==========
    ======================= */

	--text-link-default: theme(colors.indigo.400);
	--text-link-hover: theme(colors.indigo.500);
	--text-link-focus: theme(colors.indigo.400);
	--text-link-active: theme(colors.indigo.300);
	--text-link-disabled: theme(colors.indigo.400);

	/* Primary */

	--text-primary: theme(colors.white / 85%);
	--text-primary-hover: theme(colors.white / 88%);
	--text-primary-focus: theme(colors.white / 88%);
	--text-primary-active: theme(colors.white / 91%);
	--text-primary-disabled: theme(colors.white / 35%);

	--text-primary-solid: theme(colors.gray.200);
	--text-primary-solid-hover: theme(colors.gray.100);
	--text-primary-solid-focus: theme(colors.gray.50);
	--text-primary-solid-active: theme(colors.white);
	--text-primary-solid-disabled: theme(colors.gray.600);

	--text-inverse-primary: theme(colors.black / 89%);
	--text-inverse-primary-hover: theme(colors.black / 92%);
	--text-inverse-primary-focus: theme(colors.black / 92%);
	--text-inverse-primary-active: theme(colors.black / 95%);
	--text-inverse-primary-disabled: theme(colors.black / 79%);

	--text-inverse-primary-solid: theme(colors.gray.800);
	--text-inverse-primary-solid-hover: theme(colors.gray.900);
	--text-inverse-primary-solid-focus: theme(colors.gray.950);
	--text-inverse-primary-solid-active: theme(colors.black);
	--text-inverse-primary-solid-disabled: theme(colors.gray.800);

	/* Secondary */

	--text-secondary: theme(colors.white / 62%);
	--text-secondary-hover: theme(colors.white / 65%);
	--text-secondary-focus: theme(colors.white / 65%);
	--text-secondary-active: theme(colors.white / 68%);
	--text-secondary-disabled: theme(colors.white / 52%);

	--text-secondary-solid: theme(colors.gray.500);
	--text-secondary-solid-hover: theme(colors.gray.400);
	--text-secondary-solid-focus: theme(colors.gray.400);
	--text-secondary-solid-active: theme(colors.gray.300);
	--text-secondary-solid-disabled: theme(colors.gray.600);

	--text-inverse-secondary: theme(colors.black / 79%);
	--text-inverse-secondary-hover: theme(colors.black / 82%);
	--text-inverse-secondary-focus: theme(colors.black / 82%);
	--text-inverse-secondary-active: theme(colors.black / 85%);
	--text-inverse-secondary-disabled: theme(colors.black / 69%);

	--text-inverse-secondary-solid: theme(colors.gray.600);
	--text-inverse-secondary-solid-hover: theme(colors.gray.700);
	--text-inverse-secondary-solid-focus: theme(colors.gray.800);
	--text-inverse-secondary-solid-active: theme(colors.gray.900);
	--text-inverse-secondary-solid-disabled: theme(colors.gray.600);

	/* Tertiary */

	--text-tertiary: theme(colors.white / 42%);
	--text-tertiary-hover: theme(colors.white / 45%);
	--text-tertiary-focus: theme(colors.white / 45%);
	--text-tertiary-active: theme(colors.white / 48%);
	--text-tertiary-disabled: theme(colors.white / 32%);

	--text-tertiary-solid: theme(colors.gray.600);
	--text-tertiary-solid-hover: theme(colors.gray.500);
	--text-tertiary-solid-focus: theme(colors.gray.500);
	--text-tertiary-solid-active: theme(colors.gray.400);
	--text-tertiary-solid-disabled: theme(colors.gray.600);

	--text-inverse-tertiary: theme(colors.black / 69%);
	--text-inverse-tertiary-hover: theme(colors.black / 72%);
	--text-inverse-tertiary-focus: theme(colors.black / 72%);
	--text-inverse-tertiary-active: theme(colors.black / 75%);
	--text-inverse-tertiary-disabled: theme(colors.black / 59%);

	--text-inverse-tertiary-solid: theme(colors.gray.500);
	--text-inverse-tertiary-solid-hover: theme(colors.gray.600);
	--text-inverse-tertiary-solid-focus: theme(colors.gray.700);
	--text-inverse-tertiary-solid-active: theme(colors.gray.800);
	--text-inverse-tertiary-solid-disabled: theme(colors.gray.500);

	/* Negative, Positive, Warning, Info */

	--text-negative: theme(colors.rose.400);
	--text-negative-hover: theme(colors.rose.300);
	--text-negative-focus: theme(colors.rose.200);
	--text-negative-active: theme(colors.rose.100);
	--text-negative-disabled: theme(colors.rose.400);

	--text-positive: theme(colors.emerald.400);
	--text-positive-hover: theme(colors.emerald.300);
	--text-positive-focus: theme(colors.emerald.200);
	--text-positive-active: theme(colors.emerald.100);
	--text-positive-disabled: theme(colors.emerald.400);

	--text-warning: theme(colors.amber.400);
	--text-warning-hover: theme(colors.amber.300);
	--text-warning-focus: theme(colors.amber.200);
	--text-warning-active: theme(colors.amber.100);
	--text-warning-disabled: theme(colors.amber.400);

	--text-info: theme(colors.sky.400);
	--text-info-hover: theme(colors.sky.300);
	--text-info-focus: theme(colors.sky.200);
	--text-info-active: theme(colors.sky.100);
	--text-info-disabled: theme(colors.sky.400);

	/* Syntax Highlight */

	--text-syntax-normal: theme(colors.gray.200);
	--text-syntax-comment: theme(colors.gray.600);
	--text-syntax-number: theme(colors.cyan.400);
	--text-syntax-keyword: theme(colors.blue.300);
	--text-syntax-keyword-hover: theme(colors.blue.400);
	--text-syntax-atom: theme(colors.green.400);
	--text-syntax-string: theme(colors.sky.400);
	--text-syntax-error: theme(colors.red.400);
	--text-syntax-unknownVariable: theme(colors.gray.500);
	--text-syntax-knownVariable: theme(colors.cyan.300);
	--text-syntax-matchbracket: theme(colors.sky.600);
	--text-syntax-key: theme(colors.purple.200);
	--text-syntax-forbidden: theme(colors.pink.600);
	--text-syntax-prototypeKey: theme(colors.gray.600);

	--text-syntax-inverse-normal: theme(colors.gray.800);
	--text-syntax-inverse-comment: theme(colors.gray.400);
	--text-syntax-inverse-number: theme(colors.cyan.600);
	--text-syntax-inverse-keyword: theme(colors.blue.700);
	--text-syntax-inverse-atom: theme(colors.green.600);
	--text-syntax-inverse-string: theme(colors.sky.600);
	--text-syntax-inverse-error: theme(colors.red.600);
	--text-syntax-inverse-unknownVariable: theme(colors.gray.500);
	--text-syntax-inverse-knownVariable: theme(colors.cyan.700);
	--text-syntax-inverse-matchbracket: theme(colors.sky.400);
	--text-syntax-inverse-key: theme(colors.purple.800);
	--text-syntax-inverse-forbidden: theme(colors.pink.400);
	--text-syntax-inverse-prototypeKey: theme(colors.gray.400);

	/* ========================
    ========== Icons ==========
    ======================== */

	--icon-default: theme(colors.white / 50%);
	--icon-hover: theme(colors.white / 60%);
	--icon-focus: theme(colors.white / 70%);
	--icon-active: theme(colors.white / 100%);
	--icon-disabled: theme(colors.white / 40%);

	--icon-inverse-default: theme(colors.black / 75%);
	--icon-inverse-hover: theme(colors.black / 85%);
	--icon-inverse-focus: theme(colors.black / 85%);
	--icon-inverse-active: theme(colors.black / 90%);
	--icon-inverse-disabled: theme(colors.black / 50%);

	--icon-negative-default: theme(colors.rose.400 / 60%);
	--icon-negative-hover: theme(colors.rose.400 / 75%);
	--icon-negative-focus: theme(colors.rose.400 / 90%);
	--icon-negative-active: theme(colors.rose.400 / 100%);
	--icon-negative-disabled: theme(colors.rose.400 / 40%);

	--icon-positive-default: theme(colors.emerald.400 / 60%);
	--icon-positive-hover: theme(colors.emerald.400 / 75%);
	--icon-positive-active: theme(colors.emerald.400 / 90%);
	--icon-positive-focus: theme(colors.emerald.400 / 100%);
	--icon-positive-disabled: theme(colors.emerald.400 / 40%);

	--icon-warning-default: theme(colors.amber.400 / 60%);
	--icon-warning-hover: theme(colors.amber.400 / 75%);
	--icon-warning-active: theme(colors.amber.400 / 90%);
	--icon-warning-focus: theme(colors.amber.400 / 100%);
	--icon-warning-disabled: theme(colors.amber.400 / 40%);

	/* ==========================
    ========== Buttons ==========
    ========================== */

	/**
	 * DON'T EDIT THESE UNLESS YOU KNOW WHAT YOU'RE DOING.
	 * They reference the tokens above, and shouldn't need to be changed.
	**/

	/* ======== PRIMARY ======== */

	/* Background */

	--button-normal-primary-default-bg: var(--brand-primary);
	--button-normal-primary-hover-bg: var(--brand-primary-hover);
	--button-normal-primary-focus-bg: var(--brand-primary-focus);
	--button-normal-primary-active-bg: var(--brand-primary-active);
	--button-normal-primary-disabled-bg: var(--brand-primary-disabled);

	--button-negative-primary-default-bg: var(--state-negative);
	--button-negative-primary-hover-bg: var(--state-negative-hover);
	--button-negative-primary-focus-bg: var(--state-negative-focus);
	--button-negative-primary-active-bg: var(--state-negative-active);
	--button-negative-primary-disabled-bg: var(--state-negative-disabled);

	--button-positive-primary-default-bg: var(--state-positive);
	--button-positive-primary-hover-bg: var(--state-positive-hover);
	--button-positive-primary-focus-bg: var(--state-positive-focus);
	--button-positive-primary-active-bg: var(--state-positive-active);
	--button-positive-primary-disabled-bg: var(--state-positive-disabled);

	--button-warning-primary-default-bg: var(--state-warning);
	--button-warning-primary-hover-bg: var(--state-warning-hover);
	--button-warning-primary-focus-bg: var(--state-warning-focus);
	--button-warning-primary-active-bg: var(--state-warning-active);
	--button-warning-primary-disabled-bg: var(--state-warning-disabled);

	/* Text */

	--button-normal-primary-default-text: var(--text-fixed-light-default);
	--button-normal-primary-hover-text: var(--text-fixed-light-hover);
	--button-normal-primary-focus-text: var(--text-fixed-light-focus);
	--button-normal-primary-active-text: var(--text-fixed-light-active);
	--button-normal-primary-disabled-text: var(--text-fixed-light-disabled);

	--button-negative-primary-default-text: var(--text-fixed-light-default);
	--button-negative-primary-hover-text: var(--text-fixed-light-hover);
	--button-negative-primary-focus-text: var(--text-fixed-light-focus);
	--button-negative-primary-active-text: var(--text-fixed-light-active);
	--button-negative-primary-disabled-text: var(--text-fixed-light-disabled);

	--button-positive-primary-default-text: var(--text-fixed-light-default);
	--button-positive-primary-hover-text: var(--text-fixed-light-hover);
	--button-positive-primary-focus-text: var(--text-fixed-light-focus);
	--button-positive-primary-active-text: var(--text-fixed-light-active);
	--button-positive-primary-disabled-text: var(--text-fixed-light-disabled);

	--button-warning-primary-default-text: var(--text-fixed-light-default);
	--button-warning-primary-hover-text: var(--text-fixed-light-hover);
	--button-warning-primary-focus-text: var(--text-fixed-light-focus);
	--button-warning-primary-active-text: var(--text-fixed-light-active);
	--button-warning-primary-disabled-text: var(--text-fixed-light-disabled);

	/* Icon */

	--button-normal-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-normal-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-normal-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-normal-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-normal-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	--button-negative-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-negative-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-negative-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-negative-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-negative-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	--button-positive-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-positive-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-positive-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-positive-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-positive-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	--button-warning-primary-default-text: var(--text-fixed-light-default);
	--button-warning-primary-hover-text: var(--text-fixed-light-hover);
	--button-warning-primary-focus-text: var(--text-fixed-light-focus);
	--button-warning-primary-active-text: var(--text-fixed-light-active);
	--button-warning-primary-disabled-text: var(--text-fixed-light-disabled);

	/* ======== SECONDARY ======== */

	/* Background */

	--button-normal-secondary-default-bg: var(--bg-translucent-primary);
	--button-normal-secondary-hover-bg: var(--bg-translucent-secondary);
	--button-normal-secondary-focus-bg: var(--bg-translucent-tertiary);
	--button-normal-secondary-active-bg: var(--bg-translucent-quaternary);
	--button-normal-secondary-disabled-bg: var(--bg-translucent-secondary);

	--button-negative-secondary-default-bg: var(--bg-translucent-negative-default);
	--button-negative-secondary-hover-bg: var(--bg-translucent-negative-hover);
	--button-negative-secondary-focus-bg: var(--bg-translucent-negative-focus);
	--button-negative-secondary-active-bg: var(--bg-translucent-negative-active);
	--button-negative-secondary-disabled-bg: var(--bg-translucent-negative-disabled);

	--button-positive-secondary-default-bg: var(--bg-translucent-positive-default);
	--button-positive-secondary-hover-bg: var(--bg-translucent-positive-hover);
	--button-positive-secondary-focus-bg: var(--bg-translucent-positive-focus);
	--button-positive-secondary-active-bg: var(--bg-translucent-positive-active);
	--button-positive-secondary-disabled-bg: var(--bg-translucent-positive-disabled);

	--button-warning-secondary-default-bg: var(--bg-translucent-warning-default);
	--button-warning-secondary-hover-bg: var(--bg-translucent-warning-hover);
	--button-warning-secondary-focus-bg: var(--bg-translucent-warning-focus);
	--button-warning-secondary-active-bg: var(--bg-translucent-warning-active);
	--button-warning-secondary-disabled-bg: var(--bg-translucent-warning-disabled);

	/* Text */

	--button-normal-secondary-default-text: var(--text-primary);
	--button-normal-secondary-hover-text: var(--text-primary-hover);
	--button-normal-secondary-focus-text: var(--text-primary-focus);
	--button-normal-secondary-active-text: var(--text-primary-active);
	--button-normal-secondary-disabled-text: var(--text-primary-disabled);

	--button-negative-secondary-default-text: var(--text-negative);
	--button-negative-secondary-hover-text: var(--text-negative-hover);
	--button-negative-secondary-focus-text: var(--text-negative-focus);
	--button-negative-secondary-active-text: var(--text-negative-active);
	--button-negative-secondary-disabled-text: var(--text-negative-disabled);

	--button-positive-secondary-default-text: var(--text-positive);
	--button-positive-secondary-hover-text: var(--text-positive-hover);
	--button-positive-secondary-focus-text: var(--text-positive-focus);
	--button-positive-secondary-active-text: var(--text-positive-active);
	--button-positive-secondary-disabled-text: var(--text-positive-disabled);

	--button-warning-secondary-default-text: var(--text-warning);
	--button-warning-secondary-hover-text: var(--text-warning-hover);
	--button-warning-secondary-focus-text: var(--text-warning-focus);
	--button-warning-secondary-active-text: var(--text-warning-active);
	--button-warning-secondary-disabled-text: var(--text-warning-disabled);

	/* Border */

	--button-normal-secondary-default-border: var(--border-interactive-normal-default);
	--button-normal-secondary-hover-border: var(--border-interactive-normal-hover);
	--button-normal-secondary-focus-border: var(--border-interactive-normal-focus);
	--button-normal-secondary-active-border: var(--border-interactive-normal-active);
	--button-normal-secondary-disabled-border: var(--border-interactive-normal-disabled);

	--button-negative-secondary-default-border: var(--border-interactive-negative-default);
	--button-negative-secondary-hover-border: var(--border-interactive-negative-hover);
	--button-negative-secondary-focus-border: var(--border-interactive-negative-focus);
	--button-negative-secondary-active-border: var(--border-interactive-negative-active);
	--button-negative-secondary-disabled-border: var(--border-interactive-negative-disabled);

	--button-positive-secondary-default-border: var(--border-interactive-positive-default);
	--button-positive-secondary-hover-border: var(--border-interactive-positive-hover);
	--button-positive-secondary-focus-border: var(--border-interactive-positive-focus);
	--button-positive-secondary-active-border: var(--border-interactive-positive-active);
	--button-positive-secondary-disabled-border: var(--border-interactive-positive-disabled);

	--button-warning-secondary-default-border: var(--border-interactive-warning-default);
	--button-warning-secondary-hover-border: var(--border-interactive-warning-hover);
	--button-warning-secondary-focus-border: var(--border-interactive-warning-focus);
	--button-warning-secondary-active-border: var(--border-interactive-warning-active);
	--button-warning-secondary-disabled-border: var(--border-interactive-warning-disabled);

	/* Icon */

	--button-normal-secondary-default-enhancer: var(--icon-default);
	--button-normal-secondary-hover-enhancer: var(--icon-hover);
	--button-normal-secondary-focus-enhancer: var(--icon-focus);
	--button-normal-secondary-active-enhancer: var(--icon-active);
	--button-normal-secondary-disabled-enhancer: var(--icon-disabled);

	--button-negative-secondary-default-enhancer: var(--icon-negative-default);
	--button-negative-secondary-hover-enhancer: var(--icon-negative-hover);
	--button-negative-secondary-focus-enhancer: var(--icon-negative-focus);
	--button-negative-secondary-active-enhancer: var(--icon-negative-active);
	--button-negative-secondary-disabled-enhancer: var(--icon-negative-disabled);

	--button-positive-secondary-default-enhancer: var(--icon-positive-default);
	--button-positive-secondary-hover-enhancer: var(--icon-positive-hover);
	--button-positive-secondary-focus-enhancer: var(--icon-positive-focus);
	--button-positive-secondary-active-enhancer: var(--icon-positive-active);
	--button-positive-secondary-disabled-enhancer: var(--icon-positive-disabled);

	--button-warning-secondary-default-enhancer: var(--icon-warning-default);
	--button-warning-secondary-hover-enhancer: var(--icon-warning-hover);
	--button-warning-secondary-focus-enhancer: var(--icon-warning-focus);
	--button-warning-secondary-active-enhancer: var(--icon-warning-active);
	--button-warning-secondary-disabled-enhancer: var(--icon-warning-disabled);

	/* ======== TERTIARY ======== */

	/* Background */

	--button-normal-tertiary-default-bg: var(--bg-translucent-primary);
	--button-normal-tertiary-hover-bg: var(--bg-translucent-secondary);
	--button-normal-tertiary-focus-bg: var(--bg-translucent-tertiary);
	--button-normal-tertiary-active-bg: var(--bg-translucent-quaternary);
	--button-normal-tertiary-disabled-bg: var(--bg-translucent-primary);

	--button-negative-tertiary-default-bg: var(--bg-translucent-negative-default);
	--button-negative-tertiary-hover-bg: var(--bg-translucent-negative-hover);
	--button-negative-tertiary-focus-bg: var(--bg-translucent-negative-focus);
	--button-negative-tertiary-active-bg: var(--bg-translucent-negative-active);
	--button-negative-tertiary-disabled-bg: var(--bg-translucent-negative-disabled);

	--button-positive-tertiary-default-bg: var(--bg-translucent-positive-default);
	--button-positive-tertiary-hover-bg: var(--bg-translucent-positive-hover);
	--button-positive-tertiary-focus-bg: var(--bg-translucent-positive-focus);
	--button-positive-tertiary-active-bg: var(--bg-translucent-positive-active);
	--button-positive-tertiary-disabled-bg: var(--bg-translucent-positive-disabled);

	--button-warning-tertiary-default-bg: var(--bg-translucent-warning-default);
	--button-warning-tertiary-hover-bg: var(--bg-translucent-warning-hover);
	--button-warning-tertiary-focus-bg: var(--bg-translucent-warning-focus);
	--button-warning-tertiary-active-bg: var(--bg-translucent-warning-active);
	--button-warning-tertiary-disabled-bg: var(--bg-translucent-warning-disabled);

	/* Text */

	--button-normal-tertiary-default-text: var(--text-primary);
	--button-normal-tertiary-hover-text: var(--text-primary-hover);
	--button-normal-tertiary-focus-text: var(--text-primary-focus);
	--button-normal-tertiary-active-text: var(--text-primary-active);
	--button-normal-tertiary-disabled-text: var(--text-primary-disabled);

	--button-negative-tertiary-default-text: var(--text-negative);
	--button-negative-tertiary-hover-text: var(--text-negative-hover);
	--button-negative-tertiary-focus-text: var(--text-negative-focus);
	--button-negative-tertiary-active-text: var(--text-negative-active);
	--button-negative-tertiary-disabled-text: var(--text-negative-disabled);

	--button-positive-tertiary-default-text: var(--text-positive);
	--button-positive-tertiary-hover-text: var(--text-positive-hover);
	--button-positive-tertiary-focus-text: var(--text-positive-focus);
	--button-positive-tertiary-active-text: var(--text-positive-active);
	--button-positive-tertiary-disabled-text: var(--text-positive-disabled);

	--button-warning-tertiary-default-text: var(--text-warning);
	--button-warning-tertiary-hover-text: var(--text-warning-hover);
	--button-warning-tertiary-focus-text: var(--text-warning-focus);
	--button-warning-tertiary-active-text: var(--text-warning-active);
	--button-warning-tertiary-disabled-text: var(--text-warning-disabled);

	/* Border */

	--button-normal-tertiary-default-border: none;
	--button-normal-tertiary-hover-border: none;
	--button-normal-tertiary-focus-border: var(--border-interactive-normal-focus);
	--button-normal-tertiary-active-border: var(--border-interactive-normal-active);
	--button-normal-tertiary-disabled-border: none;

	--button-negative-tertiary-default-border: none;
	--button-negative-tertiary-hover-border: none;
	--button-negative-tertiary-focus-border: var(--border-interactive-negative-focus);
	--button-negative-tertiary-active-border: var(--border-interactive-negative-active);
	--button-negative-tertiary-disabled-border: none;

	--button-positive-tertiary-default-border: none;
	--button-positive-tertiary-hover-border: none;
	--button-positive-tertiary-focus-border: var(--border-interactive-positive-focus);
	--button-positive-tertiary-active-border: var(--border-interactive-positive-active);
	--button-positive-tertiary-disabled-border: none;

	--button-warning-tertiary-default-border: none;
	--button-warning-tertiary-hover-border: none;
	--button-warning-tertiary-focus-border: var(--border-interactive-warning-focus);
	--button-warning-tertiary-active-border: var(--border-interactive-warning-active);
	--button-warning-tertiary-disabled-border: none;

	/* Icon */

	--button-normal-tertiary-default-enhancer: var(--icon-default);
	--button-normal-tertiary-hover-enhancer: var(--icon-hover);
	--button-normal-tertiary-focus-enhancer: var(--icon-focus);
	--button-normal-tertiary-active-enhancer: var(--icon-active);
	--button-normal-tertiary-disabled-enhancer: var(--icon-disabled);

	--button-negative-tertiary-default-enhancer: var(--icon-negative-default);
	--button-negative-tertiary-hover-enhancer: var(--icon-negative-hover);
	--button-negative-tertiary-focus-enhancer: var(--icon-negative-focus);
	--button-negative-tertiary-active-enhancer: var(--icon-negative-active);
	--button-negative-tertiary-disabled-enhancer: var(--icon-negative-disabled);

	--button-positive-tertiary-default-enhancer: var(--icon-positive-default);
	--button-positive-tertiary-hover-enhancer: var(--icon-positive-hover);
	--button-positive-tertiary-focus-enhancer: var(--icon-positive-focus);
	--button-positive-tertiary-active-enhancer: var(--icon-positive-active);
	--button-positive-tertiary-disabled-enhancer: var(--icon-positive-disabled);

	--button-warning-tertiary-default-enhancer: var(--icon-warning-default);
	--button-warning-tertiary-hover-enhancer: var(--icon-warning-hover);
	--button-warning-tertiary-focus-enhancer: var(--icon-warning-focus);
	--button-warning-tertiary-active-enhancer: var(--icon-warning-active);
	--button-warning-tertiary-disabled-enhancer: var(--icon-warning-disabled);

	/* ======== QUATERNARY ======== */

	/* Background */

	--button-normal-quaternary-default-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-hover-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-focus-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-active-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-disabled-bg: var(--bg-translucent-secondary);

	--button-negative-quaternary-default-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-hover-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-focus-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-active-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-disabled-bg: var(--bg-translucent-negative-disabled);

	--button-positive-quaternary-default-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-hover-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-focus-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-active-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-disabled-bg: var(--bg-translucent-positive-disabled);

	--button-warning-quaternary-default-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-hover-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-focus-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-active-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-disabled-bg: var(--bg-translucent-warning-disabled);

	/* Text */

	--button-normal-quaternary-default-text: var(--text-primary);
	--button-normal-quaternary-hover-text: var(--text-primary-hover);
	--button-normal-quaternary-focus-text: var(--text-primary-focus);
	--button-normal-quaternary-active-text: var(--text-primary-active);
	--button-normal-quaternary-disabled-text: var(--text-primary-disabled);

	--button-negative-quaternary-default-text: var(--text-negative);
	--button-negative-quaternary-hover-text: var(--text-negative-hover);
	--button-negative-quaternary-focus-text: var(--text-negative-focus);
	--button-negative-quaternary-active-text: var(--text-negative-active);
	--button-negative-quaternary-disabled-text: var(--text-negative-disabled);

	--button-positive-quaternary-default-text: var(--text-positive);
	--button-positive-quaternary-hover-text: var(--text-positive-hover);
	--button-positive-quaternary-focus-text: var(--text-positive-focus);
	--button-positive-quaternary-active-text: var(--text-positive-active);
	--button-positive-quaternary-disabled-text: var(--text-positive-disabled);

	--button-warning-quaternary-default-text: var(--text-warning);
	--button-warning-quaternary-hover-text: var(--text-warning-hover);
	--button-warning-quaternary-focus-text: var(--text-warning-focus);
	--button-warning-quaternary-active-text: var(--text-warning-active);
	--button-warning-quaternary-disabled-text: var(--text-warning-disabled);

	/* Border */

	--button-normal-quaternary-default-border: none;
	--button-normal-quaternary-hover-border: none;
	--button-normal-quaternary-focus-border: none;
	--button-normal-quaternary-active-border: none;
	--button-normal-quaternary-disabled-border: none;

	--button-negative-quaternary-default-border: none;
	--button-negative-quaternary-hover-border: none;
	--button-negative-quaternary-focus-border: none;
	--button-negative-quaternary-active-border: none;
	--button-negative-quaternary-disabled-border: none;

	--button-positive-quaternary-default-border: none;
	--button-positive-quaternary-hover-border: none;
	--button-positive-quaternary-focus-border: none;
	--button-positive-quaternary-active-border: none;
	--button-positive-quaternary-disabled-border: none;

	--button-warning-quaternary-default-border: none;
	--button-warning-quaternary-hover-border: none;
	--button-warning-quaternary-focus-border: none;
	--button-warning-quaternary-active-border: none;
	--button-warning-quaternary-disabled-border: none;

	/* Icon */

	--button-normal-quaternary-default-enhancer: var(--icon-default);
	--button-normal-quaternary-hover-enhancer: var(--icon-hover);
	--button-normal-quaternary-focus-enhancer: var(--icon-focus);
	--button-normal-quaternary-active-enhancer: var(--icon-active);
	--button-normal-quaternary-disabled-enhancer: var(--icon-disabled);

	--button-negative-quaternary-default-enhancer: var(--icon-negative-default);
	--button-negative-quaternary-hover-enhancer: var(--icon-negative-hover);
	--button-negative-quaternary-focus-enhancer: var(--icon-negative-focus);
	--button-negative-quaternary-active-enhancer: var(--icon-negative-active);
	--button-negative-quaternary-disabled-enhancer: var(--icon-negative-disabled);

	--button-positive-quaternary-default-enhancer: var(--icon-positive-default);
	--button-positive-quaternary-hover-enhancer: var(--icon-positive-hover);
	--button-positive-quaternary-focus-enhancer: var(--icon-positive-focus);
	--button-positive-quaternary-active-enhancer: var(--icon-positive-active);
	--button-positive-quaternary-disabled-enhancer: var(--icon-positive-disabled);

	--button-warning-quaternary-default-enhancer: var(--icon-warning-default);
	--button-warning-quaternary-hover-enhancer: var(--icon-warning-hover);
	--button-warning-quaternary-focus-enhancer: var(--icon-warning-focus);
	--button-warning-quaternary-active-enhancer: var(--icon-warning-active);
	--button-warning-quaternary-disabled-enhancer: var(--icon-warning-disabled);
}
