html,
body {
	@apply h-full
        min-h-full;
}

body {
	position: relative;
	height: 100%;

	@apply font-light
        text-primary;
}

.contents {
	width: 700px;
	@apply ml-auto
        mr-auto;
}

.main-content {
	@apply mx-auto
        min-h-full
        max-w-7xl
        p-8
        2xl:max-w-9xl
        3xl:max-w-12xl;
}
