* {
	scroll-behavior: smooth;
}

.w-fit-content {
	width: fit-content;
}

.tsqd-main-panel {
	pointer-events: auto; /* allow clicks to pass through to the dialog */
	bottom: 32px !important;
	z-index: 61 !important;
}

.tsqd-open-btn-container {
	pointer-events: auto; /* allow clicks to pass through to the dialog */
	z-index: 62 !important;
}
