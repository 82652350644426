.InputSelectTrigger {
	@apply shadow-border-dropshadow-default
	transition-all
	hover:shadow-border-dropshadow-hover;

	&[data-state="open"] {
		@apply shadow-border-brand-default ring-4;

		svg {
			@apply fill-icon-active;
		}
	}

	&[data-disabled] {
		@apply pointer-events-none cursor-default bg-tertiary text-tertiary;
	}

	&[data-placeholder] {
		@apply text-tertiary;
	}

	&:focus-within {
		@apply shadow-border-dropshadow-focus
            outline-none
            ring
            ring-default;
	}
}

.InputSelectIcon {
	svg {
		@apply fill-icon-default;
	}
}

.InputSelectItem {
	svg {
		@apply fill-icon-default;
	}

	&.InputSelectItem--negative {
		@apply text-negative;

		svg {
			@apply fill-icon-negative-default;
		}
	}

	&[data-disabled] {
		@apply pointer-events-none 
		cursor-default 
		text-tertiary 
		group-hover:shadow-border-disabled;
	}

	&[data-highlighted] {
		@apply bg-brand 
		text-fixed-light-default 
		outline-none;

		svg {
			@apply pointer-events-none 
			fill-icon-fixed-light-default;
		}

		&.InputSelectItem--negative {
			@apply bg-negative;
		}
	}
}

.InputSelect--sm {
	@apply text-sm;

	.InputSelectTrigger {
		@apply rounded px-3;
	}
}

.InputSelect--md {
	@apply text-md;

	.InputSelectTrigger {
		@apply rounded-md px-3;
	}
}

.InputSelect--lg {
	@apply text-lg;

	.InputSelectTrigger {
		@apply rounded-lg px-4;
	}
}
