:root {
	font-family: "Inter Variable", sans-serif;
}
@supports (font-variation-settings: normal) {
	:root {
		font-family: "Inter Variable", sans-serif;
	}
}

p {
	@apply text-md;

	&:last-child {
		@apply mb-0;
	}
}

label {
	@apply cursor-pointer
	text-md;
}

blockquote {
	@apply mb-5;
}

textarea {
	@apply outline-none;
}

a {
	font-weight: inherit;

	@apply cursor-pointer
		rounded-sm
		text-link-default
		underline
		transition-all
		hover:text-link-hover
		hover:no-underline
		focus:text-link-focus
		active:text-link-active
		active:ring
		disabled:text-link-disabled;

	&.link--buttonish {
		@apply flex-1
		cursor-pointer
		text-primary
		no-underline
		hover:text-primary-hover
		hover:underline
		focus:ring-0;

		&.link--no-underline {
			@apply no-underline
			hover:no-underline;
		}

		&.link--inverse {
			@apply text-inverse-primary
			hover:text-inverse-primary;
		}

		&.link--brand {
			@apply bg-brand
			text-fixed-light-default;
		}
	}
}

/* This is required since the `text-syntax-*` classes get automatically applied */
a.text-syntax-keyword {
	@apply hover:text-syntax-keyword-hover;
}

b {
	@apply font-bold;
}

strong {
	@apply font-bold;
}

h1 {
	@apply mb-0
		text-2xl
		font-semibold;

	code {
		@apply !text-2xl;
	}
}

h2 {
	@apply mb-0
		text-xl
		font-semibold;

	code {
		@apply !text-xl;
	}
}

h3 {
	@apply mb-0
		text-lg
		font-semibold;

	code {
		@apply !text-md;
	}
}

h4 {
	@apply mb-0
		text-sm
		font-semibold
		uppercase
		tracking-wide;
}

h5,
h6 {
	@apply font-semibold;
}

h4,
h5,
h6 {
	@apply text-secondary;
}

.inspector-results {
	@apply leading-6;
}

.page-title {
	@apply mb-4
		text-lg
		font-bold;
}
