.popovermenuitem {
	a,
	button {
		@apply flex
            w-full
			items-center
            px-4
            py-2
            text-sm
            font-normal
            text-primary
			no-underline
            transition-none;

		svg,
		svg path {
			@apply fill-icon-default;
		}

		&:hover {
			@apply bg-brand
                text-inverse-primary
                no-underline;

			svg,
			svg path {
				@apply fill-icon-inverse-hover;
			}
		}
	}
}
