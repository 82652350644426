.stat {
	@apply inline-flex
        items-center
        text-sm 
        text-secondary;

	svg {
		@apply mr-1 
            fill-icon-default;
	}
}
