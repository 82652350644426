.skeleton-loader {
	position: relative;
	overflow: hidden;
}

.skeleton-loader::before {
	content: "";
	display: block;
	width: 10rem;
	left: -10rem;
	height: 100%;
	position: absolute;
	animation: slideGradient 6s ease-out infinite;
	background: linear-gradient(
		-60deg,
		transparent 0%,
		transparent 20%,
		rgba(255, 255, 255, 0.5) 50%,
		transparent 80%,
		transparent 100%
	);
}

@keyframes slideGradient {
	0% {
		transform: translate3d(-10rem, 0, 0);
	}

	100% {
		transform: translate3d(105vw, 0, 0);
	}
}
