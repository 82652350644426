/**
* This file contains style overrides for xterm's terminal
* that is only used in the Moment desktop app.
**/

/**
* All elements in xterm are nested under a div
* with the class names `terminal` and `xterm`.
*/
.terminal.xterm {
	@apply px-3 py-2;
}
