.MuiSvgIcon-root {
	/*
	 * !text-base:
	 *
	 *     MUI icons run a little large in our concise UI design; override to make them
	 *     1rem rather than the default 1.5rem.
	 *
	 * !transition-none:
	 *
	 *     Prevent MUI icon color from lagging ~50ms behind stuff like `<DropdownMenu.Item />` hover
	 *     effects. In you don't do this, you'll see stuff like the menu item instantly to
	 *     `bg-accent`, while the icon lingers at `fill-icon-default` for ~50ms before
	 *     transitioning to `fill-icon-inverse-default`.
	 */
	@apply !text-base
	 !transition-none;
}
