.DropdownMenuSubTrigger {
	svg {
		@apply fill-icon-default;
	}

	&[data-disabled] {
		@apply pointer-events-none
			cursor-default
			text-tertiary;
	}

	&[data-highlighted] {
		@apply bg-brand
			text-fixed-light-default
			outline-none;

		svg {
			@apply pointer-events-none
				fill-icon-fixed-light-default;
		}
	}
}

.DropdownMenuItem {
	svg {
		@apply fill-icon-default;
	}

	&.DropdownMenuItem--negative {
		@apply text-negative;

		svg {
			@apply fill-icon-negative-default;
		}
	}

	&[data-disabled] {
		@apply pointer-events-none cursor-default text-tertiary;
	}

	&[data-highlighted] {
		@apply bg-brand
			text-fixed-light-default
			outline-none;

		svg {
			@apply pointer-events-none
				fill-icon-fixed-light-default;
		}

		&.DropdownMenuItem--negative {
			@apply bg-negative;
		}
	}
}

.DropdownMenuCheckboxItem {
	svg {
		@apply fill-icon-default;
	}

	&[data-disabled] {
		@apply pointer-events-none cursor-default text-tertiary;
	}

	&[data-highlighted] {
		@apply bg-brand
			text-fixed-light-default
			outline-none;

		svg {
			@apply fill-icon-fixed-light-default;
		}
	}
}

.DropdownMenuRadioItem {
	svg {
		@apply fill-icon-default;
	}

	&[data-disabled] {
		@apply pointer-events-none cursor-default text-tertiary;
	}

	&[data-highlighted] {
		@apply bg-brand
			text-fixed-light-default
			outline-none;

		svg {
			@apply fill-icon-fixed-light-default;
		}

		&[data-state="checked"] {
			.RadioItemMark {
				/* @apply border border-bg-primary bg-primary; */

				svg {
					@apply pointer-events-none fill-icon-inverse-default;
				}
			}
		}

		&[data-state="unchecked"] {
			.RadioItemMark {
				/* @apply border border-bg-primary; */

				svg {
					@apply pointer-events-none opacity-0;
				}
			}
		}
	}

	&[data-state="checked"] {
		.RadioItemMark {
			/* @apply border border-brand bg-brand; */

			svg {
				@apply pointer-events-none fill-icon-default;
			}
		}
	}

	&[data-state="unchecked"] {
		.RadioItemMark {
			/* @apply border; */

			svg {
				@apply pointer-events-none opacity-0;
			}
		}
	}
}
