/* A pseudo element which extends the hit area of buttons */
.hit-maximizer::after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	box-sizing: content-box;
}

.hit-maximizer--bar {
	@apply after:-mt-1
		after:py-1;
}

.hit-maximizer--square {
	@apply after:-ml-0.5
		after:-mt-1
		after:px-0.5
		after:py-1;
}
