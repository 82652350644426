.togglebuttongroup {
	button:not(:first-child):not(:last-child) {
		@apply rounded-none;
	}
	button:not(:last-child) {
		/* this is hacky */
		margin: 0 -1px 0 0;
	}
	button:first-child {
		@apply rounded-r-none;
	}
	button:last-child {
		@apply rounded-l-none;
	}

	button {
		z-index: 98;
	}

	button:hover,
	button:focus {
		z-index: 99;
	}
}
