.popovermenu {
	@apply my-1 
        rounded-lg 
        bg-primary 
        py-2 
        shadow-border-default;
}

.popovermenu-fixed {
	@apply w-56;
}
