/* .observablehq--import a {
    @apply text-brand;
    text-decoration: none;
}

.observablehq--import a:hover {
    @apply text-brand underline;
} */

.observablehq {
	@apply overflow-x-scroll;
}

.observablehq::-webkit-scrollbar {
	display: none;
}

.observablehq p {
	@apply mb-5
        last:mb-0;
}

.observablehq > span {
	@apply font-mono
        text-sm;
}

.observablehq code {
	@apply font-mono
        font-normal;
}

.observablehq h1 {
	@apply text-3xl;
}

.observablehq h2 {
	@apply text-2xl;
}

.observablehq h3 {
	@apply text-xl;
}

.observablehq h4 {
	@apply text-lg;
}

.observablehq h5 {
	@apply text-base;
}

.observablehq pre {
	@apply text-xs;
}

.observablehq tt {
	@apply mx-2px text-lg;
}

.observablehq hr {
	@apply border-t
        border-primary;
}

.observablehq--field {
	@apply ml-4;
}
