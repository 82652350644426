.inputcheckbox[type="button"] {
	@apply inline-block;

	@apply bg-primary
		shadow-border-dropshadow-default
		hover:shadow-border-dropshadow-hover
		focus:shadow-border-dropshadow-focus
		active:shadow-border-dropshadow-active
		disabled:shadow-border-dropshadow-disabled;

	&.inputcheckbox:disabled,
	&.inputcheckbox.disabled,
	&.inputcheckbox[disabled] {
		@apply pointer-events-none bg-button-normal-secondary-disabled;
	}

	position: relative;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	flex: 0 0 auto;
}

.inputcheckbox--checked[type="button"] {
	@apply border-brand bg-brand hover:bg-button-normal-primary-hover;

	&.inputcheckbox:disabled,
	&.inputcheckbox.disabled,
	&.inputcheckbox[disabled] {
		@apply pointer-events-none
		bg-button-normal-primary-disabled
		text-button-normal-primary-disabled;
	}
}

.inputcheckbox--sm {
	@apply h-4 w-4;
}

.inputcheckbox--md {
	@apply h-6 w-6;
}

.inputcheckbox--lg {
	@apply h-8 w-8;
}
