.canvas-row {
	@apply grid
        grid-cols-8
        2xl:grid-cols-6
        3xl:grid-cols-5;

	&__margin {
		@apply col-span-1;
	}

	&__main {
		@apply col-span-6
            2xl:col-span-4
            3xl:col-span-3;
	}
}
