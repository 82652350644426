.value-secret {
	@apply relative
        inline-flex 
        items-center 
        rounded 
        border 
        bg-tertiary
        py-1.5 
        pl-1.5 
        pr-2 
        align-middle
        font-mono 
        text-sm
        text-primary;
}

.error {
	@apply border-negative 
        bg-light-spectrum-red
        text-negative;
}
