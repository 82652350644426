.input-switch {
	--width: 4.5em;
	--height: 2.5em;
	--knob: 0.7;
	--transition-speed: 0.2s;
	--transition-func: cubic-bezier(0.41, 0.01, 1, 0.38);
	/* --transition-func: cubic-bezier(0.27, 0.43, 1, 0.38); */

	/* variant scales */
	&.md {
		/* default */
		--width: 4.5em;
		--height: 2.5em;
	}
	&.sm {
		--width: 3.5em;
		--height: 2em;
	}
	&.xs {
		--width: 2.5em;
		--height: 1.5em;
	}
	&.xxs {
		--width: 2em;
		--height: 1em;
	}

	display: inline-block;
	position: relative;
	width: var(--width);
	height: var(--height);

	@apply cursor-pointer
        rounded-full
		bg-quaternary;

	transition: background var(--transition-func) var(--transition-speed);

	&.checked {
		background: var(--brand-primary);
	}

	&.focused.checked {
		@apply border
		border-brand
		ring
		ring-normal;
	}

	input[type="checkbox"] {
		position: absolute;
		inset: 0 0 0 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	div:after {
		@apply bg-primary;

		position: absolute;
		top: 50%;
		left: 0;
		content: "";
		display: block;
		width: var(--height);
		height: var(--height);
		border-radius: 100%;

		@apply pointer-events-none
            drop-shadow-sm;

		transform: translate(0, -50%) scale(var(--knob));
		transition: transform var(--transition-func) var(--transition-speed);
	}

	&.checked div:after {
		transform: translate(calc(var(--width) - var(--height)), -50%) scale(var(--knob));
	}
}
