/*
 * IMPORTANT: `postcss-import` requires that imports come before anything else.
 *
 * CSS Architecture
 * ================
 *
 * - This folder structure follows [ITCC](https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/)
 * - For layout and simple CSS, just use Tailwind utility classes
 * - If you have complex or unmaintainable strings of utility classes, then
 *   [extract](https://tailwindcss.com/docs/extracting-components) into a class
 * - Class names should use [BEM](http://getbem.com/introduction) naming conventions:
 *   - For example: button__icon--xl (block__element--modifier)
 */
@import "tailwindcss/base";
@import "tailwindcss/utilities";
@import "tailwindcss/components";

@import "./tailwind-init.css";

/* Settings & Themes */
@import "./settings/index.css";

/* Tools */
@import "./tools/index.css";

/* Generic & Resets */
@import "./generic/index.css";

/* Elements */
@import "./elements/index.css";

/* Objects & Wrappers */
@import "./objects/index.css";

/* Components */
@import "./components/index.css";

/* Utilities */
@import "./utilities/index.css";

/* temporary */
@import "../lib/Button/_.module.css";
@import "../lib/InputCheckbox/_.module.css";
@import "../lib/InputColor/_.module.css";
@import "../lib/InputDatalist/_.module.css";
@import "../lib/InputSelect/_.module.css";
@import "../lib/InputSwitch/_.module.css";
@import "../lib/InputText/_.module.css";
@import "../lib/LoadingSkeleton/_.module.css";
@import "../lib/PopOverMenu/_.module.css";
@import "../lib/PopOverMenuItem/_.module.css";
@import "../lib/Stat/_.module.css";
@import "../lib/ToggleButtonGroup/_.module.css";
@import "../lib/ValueError/_.module.css";
@import "../lib/ValueSecret/_.module.css";

@import "./find-in-page.css";
