.canvases {
	@apply space-y-4;

	&__card {
		@apply rounded-lg 
            bg-primary 
            p-6 
            shadow-md;

		&__title {
			@apply rounded
                text-lg
                font-bold
                text-primary
                hover:text-primary-hover
                focus:ring
                focus:ring-default
                active:text-primary-active;

			&--untitled {
				@apply font-normal
                    italic
                    text-secondary;
			}
		}
	}
}
