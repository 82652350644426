/*
* These styles are only used by the desktop app as the web
* version will simply use the browser's default "Find in page"
* functionality.
*
* For the desktop app, we use CSS Custom Highlights API.
* These CSS rules and specifically the values inside the parentheses
* correspond to key used in the FindInPage component when it
* interacts with the CSS Custom Highlights API.
* https://developer.mozilla.org/en-US/docs/Web/API/CSS_Custom_Highlight_API
* https://developer.mozilla.org/en-US/docs/Web/CSS/::highlight
*/
::highlight(search-results) {
	background-color: yellow;
	color: black;
}

::highlight(current-match) {
	background-color: orange;
	color: black;
}
