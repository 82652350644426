.EmojiPickerReact {
	&.epr-main {
		@apply border-0;
		@apply bg-primary-raised;

		input {
			@apply border-primary 
			bg-primary-raised
			shadow 
			transition-colors 
			hover:shadow-md 
			focus:shadow-none
			focus:ring
			focus:ring-default;
		}

		.epr-header > div {
			@apply p-1;
		}

		.epr-emoji-category-label {
			@apply border-b bg-primary-raised p-0;
		}

		.epr-emoji-list {
			@apply p-0;
		}

		.epr-emoji-category {
			@apply list-none;
		}

		.epr-emoji-category-content {
			@apply mx-0 mt-2;
		}

		& > div:last-child {
			@apply h-12 border-primary;
		}
	}
}
