.tooltip {
	@apply pointer-events-none
        absolute
        z-tooltip
        space-x-2
        rounded 
		bg-inverse-primary
        px-3
        py-2
        text-sm
        text-inverse-primary
        transition-all delay-100 duration-150
        ease-in-out;

	&__command {
		@apply text-sm
            tracking-wider
            text-tertiary;
	}

	/* UP */
	&.tooltip--up {
		bottom: calc(100% - 0.5rem);

		@apply -translate-y-3.5 scale-95 transform;

		&.animated {
			@apply -translate-y-4 scale-100 transform;
		}
	}

	/* RIGHT */
	&.tooltip--right {
		left: calc(100% - 0.5rem);

		@apply translate-x-3 scale-95 transform;

		&.animated {
			@apply translate-x-4 scale-100 transform;
		}
	}

	/* DOWN */
	&.tooltip--down {
		top: calc(100% - 0.5rem);

		@apply translate-y-3.5 scale-95 transform;

		&.animated {
			@apply translate-y-4 scale-100 transform;
		}
	}

	/* LEFT */
	&.tooltip--left {
		right: calc(100% - 0.5rem);

		@apply -translate-x-3 scale-95 transform;

		&.animated {
			@apply -translate-x-4 scale-100 transform;
		}
	}

	/* Align: LEFT */
	&.tooltip--align-left:not(.tooltip--left):not(.tooltip--right) {
		@apply left-0;
	}

	/* Align: RIGHT */
	&.tooltip--align-right:not(.tooltip--left):not(.tooltip--right) {
		@apply right-0;
	}
}
