.theme-light {
	/* ====================================================
    ========== FIXED > DO NOT EDIT THIS SECTION! ==========
    ==================================================== */

	--brand-primary-light: theme(colors.indigo.400);
	--brand-primary-dark: theme(colors.indigo.950);

	--brand-primary: theme(colors.indigo.600);
	--brand-primary-hover: theme(colors.indigo.700);
	--brand-primary-focus: theme(colors.indigo.800);
	--brand-primary-active: theme(colors.indigo.900);
	--brand-primary-disabled: theme(colors.indigo.600 / 60%);

	--brand-primary-translucent: theme(colors.indigo.500 / 8%);
	--brand-primary-translucent-hover: theme(colors.indigo.500 / 10%);
	--brand-primary-translucent-focus: theme(colors.indigo.500 / 12%);
	--brand-primary-translucent-active: theme(colors.indigo.500 / 16%);
	--brand-primary-translucent-disabled: theme(colors.indigo.500 / 5%);

	--brand-primary-translucent-dark: theme(colors.indigo.800 / 80%);
	--brand-primary-translucent-dark-hover: theme(colors.indigo.800 / 84%);
	--brand-primary-translucent-dark-focus: theme(colors.indigo.800 / 88%);
	--brand-primary-translucent-dark-active: theme(colors.indigo.800 / 92%);
	--brand-primary-translucent-dark-disabled: theme(colors.indigo.800 / 70%);

	--text-fixed-light-default: theme(colors.white / 89%);
	--text-fixed-light-hover: theme(colors.white / 92%);
	--text-fixed-light-focus: theme(colors.white / 92%);
	--text-fixed-light-active: theme(colors.white / 95%);
	--text-fixed-light-disabled: theme(colors.white / 50%);

	--text-fixed-dark-default: theme(colors.black / 85%);
	--text-fixed-dark-hover: theme(colors.black / 88%);
	--text-fixed-dark-focus: theme(colors.black / 88%);
	--text-fixed-dark-active: theme(colors.black / 91%);
	--text-fixed-dark-disabled: theme(colors.black / 75%);

	--icon-fixed-light-default: theme(colors.white / 75%);
	--icon-fixed-light-hover: theme(colors.white / 85%);
	--icon-fixed-light-focus: theme(colors.white / 85%);
	--icon-fixed-light-active: theme(colors.white / 90%);
	--icon-fixed-light-disabled: theme(colors.white / 50%);

	--icon-fixed-dark-default: theme(colors.black / 40%);
	--icon-fixed-dark-hover: theme(colors.black / 50%);
	--icon-fixed-dark-focus: theme(colors.black / 60%);
	--icon-fixed-dark-active: theme(colors.black / 90%);
	--icon-fixed-dark-disabled: theme(colors.black / 20%);

	--bg-fixed-dark-translucent-primary: theme(colors.black / 60%);
	--bg-fixed-dark-translucent-secondary: theme(colors.black / 70%);
	--bg-fixed-dark-translucent-tertiary: theme(colors.black / 80%);
	--bg-fixed-dark-translucent-quaternary: theme(colors.black / 90%);
	--bg-fixed-dark-translucent-quinary: theme(colors.black / 95%);

	--border-interactive-fixed-light: theme(colors.white / 8%);
	--border-interactive-fixed-dark: theme(colors.black / 12%);

	--state-negative: theme(colors.rose.600);
	--state-negative-hover: theme(colors.rose.700);
	--state-negative-focus: theme(colors.rose.800);
	--state-negative-active: theme(colors.rose.900);
	--state-negative-disabled: theme(colors.rose.600 / 60%);

	--state-positive: theme(colors.emerald.600);
	--state-positive-hover: theme(colors.emerald.700);
	--state-positive-focus: theme(colors.emerald.800);
	--state-positive-active: theme(colors.emerald.900);
	--state-positive-disabled: theme(colors.emerald.600 / 60%);

	--state-warning: theme(colors.amber.500);
	--state-warning-hover: theme(colors.amber.600);
	--state-warning-focus: theme(colors.amber.700);
	--state-warning-active: theme(colors.amber.800);
	--state-warning-disabled: theme(colors.amber.500 / 50%);

	--state-info: theme(colors.sky.600);
	--state-info-hover: theme(colors.sky.700);
	--state-info-focus: theme(colors.sky.800);
	--state-info-active: theme(colors.sky.900);
	--state-info-disabled: theme(colors.sky.600);

	/* ======== BEGIN EDITABLE SECTION BELOW ======== */

	/* ============================================================================================
	 * BRAND COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #4F46E5
	 *   - gray color #8B8D98
	 *   - background color #FFFFFF
	 * ========================================================================================= */

	/* Normal sRBG palette. */
	--palette-brand-1: #fdfdff;
	--palette-brand-2: #f7f8ff;
	--palette-brand-3: #eef1ff;
	--palette-brand-4: #e2e8ff;
	--palette-brand-5: #d4dcff;
	--palette-brand-6: #c4ceff;
	--palette-brand-7: #aebaff;
	--palette-brand-8: #919dff;
	--palette-brand-9: #4f46e5;
	--palette-brand-10: #443acb;
	--palette-brand-11: #4f4bdc;
	--palette-brand-12: #25256c;

	/* Alpha palette. */
	--palette-brand-alpha1: #0000ff02;
	--palette-brand-alpha2: #0020ff08;
	--palette-brand-alpha3: #002dff11;
	--palette-brand-alpha4: #0035ff1d;
	--palette-brand-alpha5: #0130ff2b;
	--palette-brand-alpha6: #012cff3b;
	--palette-brand-alpha7: #0026ff51;
	--palette-brand-alpha8: #001cff6e;
	--palette-brand-alpha9: #0d00dbb9;
	--palette-brand-alpha10: #0d00bcc5;
	--palette-brand-alpha11: #0600ceb4;
	--palette-brand-alpha12: #000053da;

	--palette-brand-contrast: #fff;
	--palette-brand-surface: #f5f6ffcc;
	--palette-brand-indicator: #4f46e5;
	--palette-brand-track: #4f46e5;

	/* Wide-gamut palette for, e.g., Apple P3 displays. Alpha blending and saturation work
       differently on these devices. */
	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-brand-1: oklch(99.4% 0.003 277);
			--palette-brand-2: oklch(98.1% 0.0112 277);
			--palette-brand-3: oklch(96.1% 0.0214 277);
			--palette-brand-4: oklch(93.5% 0.043 277);
			--palette-brand-5: oklch(90.4% 0.0643 277);
			--palette-brand-6: oklch(86.4% 0.0858 277);
			--palette-brand-7: oklch(80.8% 0.1084 277);
			--palette-brand-8: oklch(72.9% 0.1445 277);
			--palette-brand-9: oklch(51.1% 0.2301 277);
			--palette-brand-10: oklch(46.1% 0.2127 277);
			--palette-brand-11: oklch(51% 0.2127 277);
			--palette-brand-12: oklch(31.4% 0.1194 277);

			--palette-brand-alpha1: color(display-p3 0.0196 0.0196 1 / 0.008);
			--palette-brand-alpha2: color(display-p3 0.0196 0.1451 0.8784 / 0.032);
			--palette-brand-alpha3: color(display-p3 0.0078 0.1294 0.9412 / 0.063);
			--palette-brand-alpha4: color(display-p3 0.0039 0.1804 0.9294 / 0.11);
			--palette-brand-alpha5: color(display-p3 0.0039 0.1686 0.9294 / 0.165);
			--palette-brand-alpha6: color(display-p3 0.0039 0.1451 0.9333 / 0.224);
			--palette-brand-alpha7: color(display-p3 0.0039 0.1294 0.9373 / 0.31);
			--palette-brand-alpha8: color(display-p3 0.0039 0.0941 0.9373 / 0.424);
			--palette-brand-alpha9: color(display-p3 0.0392 0 0.8118 / 0.726);
			--palette-brand-alpha10: color(display-p3 0.0392 0 0.6941 / 0.773);
			--palette-brand-alpha11: color(display-p3 0.0157 0 0.7608 / 0.706);
			--palette-brand-alpha12: color(display-p3 0 0 0.3059 / 0.855);

			--palette-brand-contrast: #fff;
			--palette-brand-surface: color(display-p3 0.9608 0.9686 1 / 0.8);
			--palette-brand-indicator: oklch(51.1% 0.2301 277);
			--palette-brand-track: oklch(51.1% 0.2301 277);
		}
	}

	/* ============================================================================================
	 * CONTRAST COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #0f172a
	 *   - gray color #8B8D98
	 *   - background color #FFFFFF
	 * ========================================================================================= */

	--palette-brand-contrast-1: #fafcff;
	--palette-brand-contrast-2: #f5f9ff;
	--palette-brand-contrast-3: #ebf0fd;
	--palette-brand-contrast-4: #e1e8f9;
	--palette-brand-contrast-5: #d8e1f7;
	--palette-brand-contrast-6: #cedaf3;
	--palette-brand-contrast-7: #c0cfef;
	--palette-brand-contrast-8: #aabce3;
	--palette-brand-contrast-9: #0f172a;
	--palette-brand-contrast-10: #262f43;
	--palette-brand-contrast-11: #556485;
	--palette-brand-contrast-12: #161f33;

	--palette-brand-contrast-alpha1: #0066ff05;
	--palette-brand-contrast-alpha2: #0066ff0a;
	--palette-brand-contrast-alpha3: #0040e614;
	--palette-brand-contrast-alpha4: #003ccc1e;
	--palette-brand-contrast-alpha5: #003bcb27;
	--palette-brand-contrast-alpha6: #003fc131;
	--palette-brand-contrast-alpha7: #003dbf3f;
	--palette-brand-contrast-alpha8: #0036ab55;
	--palette-brand-contrast-alpha9: #00091df0;
	--palette-brand-contrast-alpha10: #000b22d9;
	--palette-brand-contrast-alpha11: #001748aa;
	--palette-brand-contrast-alpha12: #000a20e9;

	--palette-brand-contrast-contrast: #fff;
	--palette-brand-contrast-surface: #f3f8ffcc;
	--palette-brand-contrast-indicator: #0f172a;
	--palette-brand-contrast-track: #0f172a;

	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-brand-contrast-1: oklch(99.1% 0.006 265.8);
			--palette-brand-contrast-2: oklch(98.2% 0.0121 265.8);
			--palette-brand-contrast-3: oklch(95.6% 0.0182 265.8);
			--palette-brand-contrast-4: oklch(93.1% 0.0245 265.8);
			--palette-brand-contrast-5: oklch(91% 0.0307 265.8);
			--palette-brand-contrast-6: oklch(88.8% 0.0372 265.8);
			--palette-brand-contrast-7: oklch(85.3% 0.047 265.8);
			--palette-brand-contrast-8: oklch(79.4% 0.0597 265.8);
			--palette-brand-contrast-9: oklch(20.8% 0.0398 265.8);
			--palette-brand-contrast-10: oklch(30.5% 0.0398 265.8);
			--palette-brand-contrast-11: oklch(50.3% 0.056 265.8);
			--palette-brand-contrast-12: oklch(24.1% 0.0398 265.8);

			--palette-brand-contrast-alpha1: color(display-p3 0.0196 0.4118 1 / 0.02);
			--palette-brand-contrast-alpha2: color(display-p3 0.0235 0.349 0.8941 / 0.036);
			--palette-brand-contrast-alpha3: color(display-p3 0.0078 0.2157 0.8431 / 0.075);
			--palette-brand-contrast-alpha4: color(display-p3 0.0039 0.2118 0.7255 / 0.114);
			--palette-brand-contrast-alpha5: color(display-p3 0.0078 0.1961 0.7333 / 0.146);
			--palette-brand-contrast-alpha6: color(display-p3 0.0039 0.2196 0.7059 / 0.185);
			--palette-brand-contrast-alpha7: color(display-p3 0.0039 0.2039 0.6863 / 0.236);
			--palette-brand-contrast-alpha8: color(display-p3 0.0039 0.1725 0.6235 / 0.322);
			--palette-brand-contrast-alpha9: color(display-p3 0 0.0235 0.102 / 0.934);
			--palette-brand-contrast-alpha10: color(display-p3 0 0.0314 0.1176 / 0.844);
			--palette-brand-contrast-alpha11: color(display-p3 0 0.0745 0.251 / 0.655);
			--palette-brand-contrast-alpha12: color(display-p3 0 0.0314 0.1098 / 0.906);

			--palette-brand-contrast-contrast: #fff;
			--palette-brand-contrast-surface: color(display-p3 0.9569 0.9725 1 / 0.8);
			--palette-brand-contrast-indicator: oklch(20.8% 0.0398 265.8);
			--palette-brand-contrast-track: oklch(20.8% 0.0398 265.8);
		}
	}

	/* ============================================================================================
	 * GRAY COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #8183A0
	 *   - gray color #8B8D98
	 *   - background color #FFFFFF
	 * ========================================================================================= */

	--palette-gray-1: #fcfcff;
	--palette-gray-2: #f9f9fe;
	--palette-gray-3: #eff0f8;
	--palette-gray-4: #e6e7f2;
	--palette-gray-5: #dedfee;
	--palette-gray-6: #d6d7e8;
	--palette-gray-7: #cacce2;
	--palette-gray-8: #b5b8d6;
	--palette-gray-9: #8183a0;
	--palette-gray-10: #757793;
	--palette-gray-11: #61637c;
	--palette-gray-12: #1f202f;

	--palette-gray-alpha1: #0000ff03;
	--palette-gray-alpha2: #0000d506;
	--palette-gray-alpha3: #00109010;
	--palette-gray-alpha4: #000b7b19;
	--palette-gray-alpha5: #00087c21;
	--palette-gray-alpha6: #00077029;
	--palette-gray-alpha7: #000a7435;
	--palette-gray-alpha8: #010b724a;
	--palette-gray-alpha9: #00053f7e;
	--palette-gray-alpha10: #0004388a;
	--palette-gray-alpha11: #00042c9e;
	--palette-gray-alpha12: #000112e0;

	--palette-gray-contrast: #fff;
	--palette-gray-surface: #f8f8fecc;
	--palette-gray-indicator: #8183a0;
	--palette-gray-track: #8183a0;

	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-gray-1: oklch(99.2% 0.0035 281.9);
			--palette-gray-2: oklch(98.3% 0.0061 281.9);
			--palette-gray-3: oklch(95.6% 0.0108 281.9);
			--palette-gray-4: oklch(93.2% 0.0148 281.9);
			--palette-gray-5: oklch(90.8% 0.0198 281.9);
			--palette-gray-6: oklch(88.4% 0.0234 281.9);
			--palette-gray-7: oklch(85% 0.0299 281.9);
			--palette-gray-8: oklch(78.9% 0.0426 281.9);
			--palette-gray-9: oklch(61.9% 0.0437 281.9);
			--palette-gray-10: oklch(57.7% 0.0437 281.9);
			--palette-gray-11: oklch(50.7% 0.0401 281.9);
			--palette-gray-12: oklch(25% 0.0284 281.9);

			--palette-gray-alpha1: color(display-p3 0.0235 0.0235 1 / 0.012);
			--palette-gray-alpha2: color(display-p3 0.0235 0.0235 0.8392 / 0.024);
			--palette-gray-alpha3: color(display-p3 0.0078 0.0667 0.5059 / 0.063);
			--palette-gray-alpha4: color(display-p3 0.0118 0.051 0.4471 / 0.099);
			--palette-gray-alpha5: color(display-p3 0.0078 0.0353 0.4588 / 0.13);
			--palette-gray-alpha6: color(display-p3 0.0039 0.0275 0.4157 / 0.161);
			--palette-gray-alpha7: color(display-p3 0.0039 0.0392 0.4157 / 0.208);
			--palette-gray-alpha8: color(display-p3 0.0039 0.0314 0.4 / 0.287);
			--palette-gray-alpha9: color(display-p3 0.0039 0.0196 0.2314 / 0.495);
			--palette-gray-alpha10: color(display-p3 0 0.0157 0.2039 / 0.542);
			--palette-gray-alpha11: color(display-p3 0 0.0157 0.1608 / 0.62);
			--palette-gray-alpha12: color(display-p3 0 0.0039 0.0667 / 0.879);

			--palette-gray-contrast: #fff;
			--palette-gray-surface: color(display-p3 0.9725 0.9725 0.9922 / 0.8);
			--palette-gray-indicator: oklch(61.9% 0.0437 281.9);
			--palette-gray-track: oklch(61.9% 0.0437 281.9);
		}
	}

	/* ============================================================================================
	 * NEGATIVE COLOR PALETTE. Generated by https://www.radix-ui.com/colors/custom with:
	 *
	 *   - accent color #E11D48
	 *   - gray color #8B8D98
	 *   - background color #FFFFFF
	 * ========================================================================================= */

	--palette-negative-1: #fffcfc;
	--palette-negative-2: #fff7f7;
	--palette-negative-3: #ffeaea;
	--palette-negative-4: #ffdbdb;
	--palette-negative-5: #ffcccd;
	--palette-negative-6: #ffbcbd;
	--palette-negative-7: #f7a8aa;
	--palette-negative-8: #ef8d91;
	--palette-negative-9: #e11d48;
	--palette-negative-10: #d2003b;
	--palette-negative-11: #d4003e;
	--palette-negative-12: #6a0d1f;

	--palette-negative-alpha1: #ff000003;
	--palette-negative-alpha2: #ff000008;
	--palette-negative-alpha3: #ff000015;
	--palette-negative-alpha4: #ff000024;
	--palette-negative-alpha5: #ff000533;
	--palette-negative-alpha6: #ff000443;
	--palette-negative-alpha7: #e8000657;
	--palette-negative-alpha8: #dc000972;
	--palette-negative-alpha9: #dd0031e2;
	--palette-negative-alpha10: #d2003b;
	--palette-negative-alpha11: #d4003e;
	--palette-negative-alpha12: #620013f2;

	--palette-negative-contrast: #fff;
	--palette-negative-surface: #fff5f5cc;
	--palette-negative-indicator: #e11d48;
	--palette-negative-track: #e11d48;

	@supports (color: color(display-p3 1 1 1)) {
		@media (color-gamut: p3) {
			--palette-negative-1: oklch(99.4% 0.0036 17.58);
			--palette-negative-2: oklch(98.3% 0.0095 17.58);
			--palette-negative-3: oklch(95.4% 0.0248 17.58);
			--palette-negative-4: oklch(92.8% 0.0497 17.58);
			--palette-negative-5: oklch(89.6% 0.0647 17.58);
			--palette-negative-6: oklch(85.8% 0.0778 17.58);
			--palette-negative-7: oklch(80.9% 0.0937 17.58);
			--palette-negative-8: oklch(74.8% 0.1189 17.58);
			--palette-negative-9: oklch(58.6% 0.222 17.58);
			--palette-negative-10: oklch(54.2% 0.2247 17.58);
			--palette-negative-11: oklch(55.1% 0.222 17.58);
			--palette-negative-12: oklch(34.1% 0.1243 17.58);

			--palette-negative-alpha1: color(display-p3 0.6745 0.0235 0.0235 / 0.012);
			--palette-negative-alpha2: color(display-p3 0.8784 0.0196 0.0196 / 0.032);
			--palette-negative-alpha3: color(display-p3 0.8118 0.0588 0.0078 / 0.083);
			--palette-negative-alpha4: color(display-p3 0.8314 0.0078 0.0078 / 0.138);
			--palette-negative-alpha5: color(display-p3 0.8392 0.0078 0.0078 / 0.193);
			--palette-negative-alpha6: color(display-p3 0.8471 0.0196 0.0039 / 0.255);
			--palette-negative-alpha7: color(display-p3 0.7647 0.0039 0.0157 / 0.33);
			--palette-negative-alpha8: color(display-p3 0.7373 0.0039 0.0118 / 0.428);
			--palette-negative-alpha9: color(display-p3 0.7608 0 0.1098 / 0.789);
			--palette-negative-alpha10: color(display-p3 0.7059 0 0.1137 / 0.844);
			--palette-negative-alpha11: color(display-p3 0.7137 0 0.1176 / 0.84);
			--palette-negative-alpha12: color(display-p3 0.3176 0 0.0431 / 0.91);

			--palette-negative-contrast: #fff;
			--palette-negative-surface: color(display-p3 0.9961 0.9608 0.9608 / 0.8);
			--palette-negative-indicator: oklch(58.6% 0.222 17.58);
			--palette-negative-track: oklch(58.6% 0.222 17.58);
		}
	}

	/* =========================
    ========== Global ==========
    ========================= */

	/* Negative, Positive, Warning, Info - LIGHT */

	--state-negative-light: theme(colors.rose.50);
	--state-negative-light-hover: theme(colors.rose.100);
	--state-negative-light-focus: theme(colors.rose.200);
	--state-negative-light-active: theme(colors.rose.300);
	--state-negative-light-disabled: theme(colors.rose.100 / 60%);

	--state-positive-light: theme(colors.emerald.50);
	--state-positive-light-hover: theme(colors.emerald.100);
	--state-positive-light-focus: theme(colors.emerald.200);
	--state-positive-light-active: theme(colors.emerald.300);
	--state-positive-light-disabled: theme(colors.emerald.100 / 60%);

	--state-warning-light: theme(colors.amber.50);
	--state-warning-light-hover: theme(colors.amber.100);
	--state-warning-light-focus: theme(colors.amber.200);
	--state-warning-light-active: theme(colors.amber.300);
	--state-warning-light-disabled: theme(colors.amber.100 / 60%);

	--state-info-light: theme(colors.sky.50);
	--state-info-light-hover: theme(colors.sky.100);
	--state-info-light-focus: theme(colors.sky.200);
	--state-info-light-active: theme(colors.sky.300);
	--state-info-light-disabled: theme(colors.sky.200 / 60%);

	/* Negative, Positive, Warning, Info - MID */

	--state-negative-mid: theme(colors.rose.200);
	--state-negative-mid-hover: theme(colors.rose.300);
	--state-negative-mid-focus: theme(colors.rose.400);
	--state-negative-mid-active: theme(colors.rose.500);
	--state-negative-mid-disabled: theme(colors.rose.300 / 60%);

	--state-positive-mid: theme(colors.emerald.200);
	--state-positive-mid-hover: theme(colors.emerald.300);
	--state-positive-mid-focus: theme(colors.emerald.400);
	--state-positive-mid-active: theme(colors.emerald.500);
	--state-positive-mid-disabled: theme(colors.emerald.300 / 60%);

	--state-warning-mid: theme(colors.amber.200);
	--state-warning-mid-hover: theme(colors.amber.300);
	--state-warning-mid-focus: theme(colors.amber.400);
	--state-warning-mid-active: theme(colors.amber.500);
	--state-warning-mid-disabled: theme(colors.amber.300 / 60%);

	--state-info-mid: theme(colors.sky.200);
	--state-info-mid-hover: theme(colors.sky.300);
	--state-info-mid-focus: theme(colors.sky.400);
	--state-info-mid-active: theme(colors.sky.500);
	--state-info-mid-disabled: theme(colors.sky.300 / 60%);

	/* Full color spectrum */

	--rainbow-light-0-solid: theme(colors.red.300);
	--rainbow-light-1-solid: theme(colors.orange.300);
	--rainbow-light-2-solid: theme(colors.amber.300);
	--rainbow-light-3-solid: theme(colors.yellow.300);
	--rainbow-light-4-solid: theme(colors.lime.300);
	--rainbow-light-5-solid: theme(colors.green.300);
	--rainbow-light-6-solid: theme(colors.emerald.300);
	--rainbow-light-7-solid: theme(colors.teal.300);
	--rainbow-light-8-solid: theme(colors.cyan.300);
	--rainbow-light-9-solid: theme(colors.sky.300);
	--rainbow-light-10-solid: theme(colors.blue.300);
	--rainbow-light-11-solid: theme(colors.indigo.300);
	--rainbow-light-12-solid: theme(colors.violet.300);
	--rainbow-light-13-solid: theme(colors.purple.300);
	--rainbow-light-14-solid: theme(colors.fuchsia.300);
	--rainbow-light-15-solid: theme(colors.pink.300);
	--rainbow-light-16-solid: theme(colors.rose.300);

	--rainbow-dark-0-solid: theme(colors.red.900);
	--rainbow-dark-1-solid: theme(colors.orange.900);
	--rainbow-dark-2-solid: theme(colors.amber.900);
	--rainbow-dark-3-solid: theme(colors.yellow.900);
	--rainbow-dark-4-solid: theme(colors.lime.900);
	--rainbow-dark-5-solid: theme(colors.green.900);
	--rainbow-dark-6-solid: theme(colors.emerald.900);
	--rainbow-dark-7-solid: theme(colors.teal.900);
	--rainbow-dark-8-solid: theme(colors.cyan.900);
	--rainbow-dark-9-solid: theme(colors.sky.900);
	--rainbow-dark-10-solid: theme(colors.blue.900);
	--rainbow-dark-11-solid: theme(colors.indigo.900);
	--rainbow-dark-12-solid: theme(colors.violet.900);
	--rainbow-dark-13-solid: theme(colors.purple.900);
	--rainbow-dark-14-solid: theme(colors.fuchsia.900);
	--rainbow-dark-15-solid: theme(colors.pink.900);
	--rainbow-dark-16-solid: theme(colors.rose.900);

	--rainbow-lighter-0-solid: theme(colors.red.200);
	--rainbow-lighter-1-solid: theme(colors.orange.200);
	--rainbow-lighter-2-solid: theme(colors.amber.200);
	--rainbow-lighter-3-solid: theme(colors.yellow.200);
	--rainbow-lighter-4-solid: theme(colors.lime.200);
	--rainbow-lighter-5-solid: theme(colors.green.200);
	--rainbow-lighter-6-solid: theme(colors.emerald.200);
	--rainbow-lighter-7-solid: theme(colors.teal.200);
	--rainbow-lighter-8-solid: theme(colors.cyan.200);
	--rainbow-lighter-9-solid: theme(colors.sky.200);
	--rainbow-lighter-10-solid: theme(colors.blue.200);
	--rainbow-lighter-11-solid: theme(colors.indigo.200);
	--rainbow-lighter-12-solid: theme(colors.violet.200);
	--rainbow-lighter-13-solid: theme(colors.purple.200);
	--rainbow-lighter-14-solid: theme(colors.fuchsia.200);
	--rainbow-lighter-15-solid: theme(colors.pink.200);
	--rainbow-lighter-16-solid: theme(colors.rose.200);

	--rainbow-light-0-translucent: theme(colors.red.300 / 20%);
	--rainbow-light-1-translucent: theme(colors.orange.300 / 20%);
	--rainbow-light-2-translucent: theme(colors.amber.300 / 20%);
	--rainbow-light-3-translucent: theme(colors.yellow.300 / 20%);
	--rainbow-light-4-translucent: theme(colors.lime.300 / 20%);
	--rainbow-light-5-translucent: theme(colors.green.300 / 20%);
	--rainbow-light-6-translucent: theme(colors.emerald.300 / 20%);
	--rainbow-light-7-translucent: theme(colors.teal.300 / 20%);
	--rainbow-light-8-translucent: theme(colors.cyan.300 / 20%);
	--rainbow-light-9-translucent: theme(colors.sky.300 / 20%);
	--rainbow-light-10-translucent: theme(colors.blue.300 / 20%);
	--rainbow-light-11-translucent: theme(colors.indigo.300 / 20%);
	--rainbow-light-12-translucent: theme(colors.violet.300 / 20%);
	--rainbow-light-13-translucent: theme(colors.purple.300 / 20%);
	--rainbow-light-14-translucent: theme(colors.fuchsia.300 / 20%);
	--rainbow-light-15-translucent: theme(colors.pink.300 / 20%);
	--rainbow-light-16-translucent: theme(colors.rose.300 / 20%);

	/* Dark and light spectrums */

	--light-spectrum-gray: theme(colors.gray[100]);
	--light-spectrum-gray-hover: theme(colors.gray[200]);
	--light-spectrum-gray-focus: theme(colors.gray[200]);
	--light-spectrum-gray-active: theme(colors.gray[300]);
	--light-spectrum-red: theme(colors.rose[100]);
	--light-spectrum-red-hover: theme(colors.rose[200]);
	--light-spectrum-red-focus: theme(colors.rose[200]);
	--light-spectrum-red-active: theme(colors.rose[300]);
	--light-spectrum-yellow: theme(colors.amber[100]);
	--light-spectrum-yellow-hover: theme(colors.amber[200]);
	--light-spectrum-yellow-focus: theme(colors.amber[200]);
	--light-spectrum-yellow-active: theme(colors.amber[300]);
	--light-spectrum-green: theme(colors.emerald[100]);
	--light-spectrum-green-hover: theme(colors.emerald[200]);
	--light-spectrum-green-focus: theme(colors.emerald[200]);
	--light-spectrum-green-active: theme(colors.emerald[300]);
	--light-spectrum-cyan: theme(colors.cyan[100]);
	--light-spectrum-cyan-hover: theme(colors.cyan[200]);
	--light-spectrum-cyan-focus: theme(colors.cyan[200]);
	--light-spectrum-cyan-active: theme(colors.cyan[300]);
	--light-spectrum-blue: theme(colors.blue[100]);
	--light-spectrum-blue-hover: theme(colors.blue[200]);
	--light-spectrum-blue-focus: theme(colors.blue[200]);
	--light-spectrum-blue-active: theme(colors.blue[300]);
	--light-spectrum-purple: theme(colors.violet[100]);
	--light-spectrum-purple-hover: theme(colors.violet[200]);
	--light-spectrum-purple-focus: theme(colors.violet[200]);
	--light-spectrum-purple-active: theme(colors.violet[300]);
	--light-spectrum-pink: theme(colors.fuchsia[100]);
	--light-spectrum-pink-hover: theme(colors.fuchsia[200]);
	--light-spectrum-pink-focus: theme(colors.fuchsia[200]);
	--light-spectrum-pink-active: theme(colors.fuchsia[300]);

	--dark-spectrum-gray: theme(colors.gray[500]);
	--dark-spectrum-gray-hover: theme(colors.gray[600]);
	--dark-spectrum-gray-focus: theme(colors.gray[600]);
	--dark-spectrum-gray-active: theme(colors.gray[700]);
	--dark-spectrum-red: theme(colors.rose[500]);
	--dark-spectrum-red-hover: theme(colors.rose[600]);
	--dark-spectrum-red-focus: theme(colors.rose[600]);
	--dark-spectrum-red-active: theme(colors.rose[700]);
	--dark-spectrum-yellow: theme(colors.amber[500]);
	--dark-spectrum-yellow-hover: theme(colors.amber[600]);
	--dark-spectrum-yellow-focus: theme(colors.amber[600]);
	--dark-spectrum-yellow-active: theme(colors.amber[700]);
	--dark-spectrum-green: theme(colors.emerald[500]);
	--dark-spectrum-green-hover: theme(colors.emerald[600]);
	--dark-spectrum-green-focus: theme(colors.emerald[600]);
	--dark-spectrum-green-active: theme(colors.emerald[700]);
	--dark-spectrum-cyan: theme(colors.cyan[500]);
	--dark-spectrum-cyan-hover: theme(colors.cyan[600]);
	--dark-spectrum-cyan-focus: theme(colors.cyan[600]);
	--dark-spectrum-cyan-active: theme(colors.cyan[700]);
	--dark-spectrum-blue: theme(colors.blue[500]);
	--dark-spectrum-blue-hover: theme(colors.blue[600]);
	--dark-spectrum-blue-focus: theme(colors.blue[600]);
	--dark-spectrum-blue-active: theme(colors.blue[700]);
	--dark-spectrum-purple: theme(colors.violet[500]);
	--dark-spectrum-purple-hover: theme(colors.violet[600]);
	--dark-spectrum-purple-focus: theme(colors.violet[600]);
	--dark-spectrum-purple-active: theme(colors.violet[700]);
	--dark-spectrum-pink: theme(colors.fuchsia[500]);
	--dark-spectrum-pink-hover: theme(colors.fuchsia[600]);
	--dark-spectrum-pink-focus: theme(colors.fuchsia[600]);
	--dark-spectrum-pink-active: theme(colors.fuchsia[700]);

	/* ==============================
    ========== Focus Rings ==========
    ============================== */

	--focus-ring-default: theme(colors.indigo.600 / 30%);
	--focus-ring-negative: theme(colors.rose.600 / 30%);
	--focus-ring-positive: theme(colors.emerald.600 / 30%);
	--focus-ring-warning: theme(colors.amber.600 / 30%);

	/* ==============================
    ========== Backgrounds ==========
    ============================== */

	/* Main */

	--bg-primary-raised: theme(colors.white);
	--bg-primary: theme(colors.white);
	--bg-secondary: theme(colors.gray.50);
	--bg-tertiary: theme(colors.gray.100);
	--bg-quaternary: theme(colors.gray.200);
	--bg-quinary: theme(colors.gray.300);

	--bg-inverse-primary: theme(colors.black);
	--bg-inverse-secondary: theme(colors.gray.950);
	--bg-inverse-tertiary: theme(colors.gray.900);
	--bg-inverse-quaternary: theme(colors.gray.800);
	--bg-inverse-quinary: theme(colors.gray.700);

	/* Modal */

	--bg-modal: theme(colors.black / 60%);

	/* Translucent */

	--bg-translucent-primary: theme(colors.black / 0%);
	--bg-translucent-secondary: theme(colors.black / 3%);
	--bg-translucent-tertiary: theme(colors.black / 6%);
	--bg-translucent-quaternary: theme(colors.black / 9%);
	--bg-translucent-quinary: theme(colors.black / 12%);

	--bg-inverse-translucent-primary: theme(colors.black / 60%);
	--bg-inverse-translucent-secondary: theme(colors.black / 70%);
	--bg-inverse-translucent-tertiary: theme(colors.black / 80%);
	--bg-inverse-translucent-quaternary: theme(colors.black / 90%);
	--bg-inverse-translucent-quinary: theme(colors.black / 95%);

	--bg-translucent-brand-default: theme(colors.indigo.600 / 0%);
	--bg-translucent-brand-hover: theme(colors.indigo.600 / 5%);
	--bg-translucent-brand-focus: theme(colors.indigo.600 / 4%);
	--bg-translucent-brand-active: theme(colors.indigo.600 / 10%);
	--bg-translucent-brand-disabled: theme(colors.indigo.600 / 0%);

	--bg-translucent-positive-default: theme(colors.emerald.600 / 0%);
	--bg-translucent-positive-hover: theme(colors.emerald.600 / 5%);
	--bg-translucent-positive-focus: theme(colors.emerald.600 / 10%);
	--bg-translucent-positive-active: theme(colors.emerald.600 / 15%);
	--bg-translucent-positive-disabled: theme(colors.emerald.600 / 0%);

	--bg-translucent-negative-default: theme(colors.rose.600 / 0%);
	--bg-translucent-negative-hover: theme(colors.rose.600 / 5%);
	--bg-translucent-negative-focus: theme(colors.rose.600 / 10%);
	--bg-translucent-negative-active: theme(colors.rose.600 / 15%);
	--bg-translucent-negative-disabled: theme(colors.rose.600 / 0%);

	--bg-translucent-warning-default: theme(colors.amber.600 / 0%);
	--bg-translucent-warning-hover: theme(colors.amber.600 / 5%);
	--bg-translucent-warning-focus: theme(colors.amber.600 / 10%);
	--bg-translucent-warning-active: theme(colors.amber.600 / 15%);
	--bg-translucent-warning-disabled: theme(colors.amber.600 / 0%);

	--bg-translucent-info-default: theme(colors.sky.600 / 0%);
	--bg-translucent-info-hover: theme(colors.sky.600 / 5%);
	--bg-translucent-info-focus: theme(colors.sky.600 / 10%);
	--bg-translucent-info-active: theme(colors.sky.600 / 15%);
	--bg-translucent-info-disabled: theme(colors.sky.600 / 0%);

	/* Miscellaneous */

	--bg-flash: theme(colors.emerald.200);

	--scrollbar-thumb: theme(colors.gray.300);
	--scrollbar-track: theme(colors.gray.100);

	/* ==========================
    ========== Borders ==========
    ========================== */

	--border-primary: theme(colors.gray.900 / 10%);
	--border-primary-hover: theme(colors.gray.900 / 15%);
	--border-primary-focus: theme(colors.gray.900 / 20%);
	--border-primary-active: theme(colors.gray.900 / 25%);
	--border-primary-disabled: theme(colors.gray.900 / 10%);

	--border-primary-solid: theme(colors.gray.200);
	--border-primary-solid-hover: theme(colors.gray.300);
	--border-primary-solid-focus: theme(colors.gray.400);
	--border-primary-solid-active: theme(colors.gray.500);
	--border-primary-solid-disabled: theme(colors.gray.200);

	--border-inverse-primary: theme(colors.white / 20%);
	--border-inverse-primary-hover: theme(colors.white / 25%);
	--border-inverse-primary-focus: theme(colors.white / 30%);
	--border-inverse-primary-active: theme(colors.white / 35%);
	--border-inverse-primary-disabled: theme(colors.white / 20%);

	--border-inverse-primary-solid: theme(colors.gray.700);
	--border-inverse-primary-solid-hover: theme(colors.gray.600);
	--border-inverse-primary-solid-focus: theme(colors.gray.500);
	--border-inverse-primary-solid-active: theme(colors.gray.400);
	--border-inverse-primary-solid-disabled: theme(colors.gray.600);

	--border-interactive-normal-default: theme(colors.gray.900 / 25%);
	--border-interactive-normal-hover: theme(colors.gray.900 / 30%);
	--border-interactive-normal-focus: var(--brand-primary-focus);
	--border-interactive-normal-active: var(--brand-primary-active);
	--border-interactive-normal-disabled: theme(colors.gray.900 / 10%);

	--border-interactive-negative-default: theme(colors.rose.600 / 40%);
	--border-interactive-negative-hover: theme(colors.rose.600 / 45%);
	--border-interactive-negative-focus: var(--state-negative-focus);
	--border-interactive-negative-active: var(--state-negative-active);
	--border-interactive-negative-disabled: theme(colors.rose.600 / 20%);

	--border-interactive-positive-default: theme(colors.emerald.600 / 40%);
	--border-interactive-positive-hover: theme(colors.emerald.600 / 45%);
	--border-interactive-positive-focus: var(--state-positive-focus);
	--border-interactive-positive-active: var(--state-positive-active);
	--border-interactive-positive-disabled: theme(colors.emerald.600 / 20%);

	/* =======================
    ========== Text ==========
    ======================= */

	--text-link-default: var(--brand-primary);
	--text-link-hover: var(--brand-primary-hover);
	--text-link-focus: var(--brand-primary-focus);
	--text-link-active: var(--brand-primary-active);
	--text-link-disabled: var(--brand-primary-disabled);

	/* Primary */

	--text-primary: theme(colors.black / 85%);
	--text-primary-hover: theme(colors.black / 88%);
	--text-primary-focus: theme(colors.black / 88%);
	--text-primary-active: theme(colors.black / 91%);
	--text-primary-disabled: theme(colors.black / 35%);

	--text-primary-solid: theme(colors.gray.800);
	--text-primary-solid-hover: theme(colors.gray.900);
	--text-primary-solid-focus: theme(colors.gray.950);
	--text-primary-solid-active: theme(colors.black);
	--text-primary-solid-disabled: theme(colors.gray.400);

	--text-inverse-primary: theme(colors.white / 89%);
	--text-inverse-primary-hover: theme(colors.white / 92%);
	--text-inverse-primary-focus: theme(colors.white / 92%);
	--text-inverse-primary-active: theme(colors.white / 95%);
	--text-inverse-primary-disabled: theme(colors.white / 50%);

	--text-inverse-primary-solid: theme(colors.gray.200);
	--text-inverse-primary-solid-hover: theme(colors.gray.100);
	--text-inverse-primary-solid-focus: theme(colors.gray.50);
	--text-inverse-primary-solid-active: theme(colors.white);
	--text-inverse-primary-solid-disabled: theme(colors.gray.400);

	/* Secondary */

	--text-secondary: theme(colors.black / 62%);
	--text-secondary-hover: theme(colors.black / 65%);
	--text-secondary-focus: theme(colors.black / 65%);
	--text-secondary-active: theme(colors.black / 68%);
	--text-secondary-disabled: theme(colors.black / 40%);

	--text-secondary-solid: theme(colors.gray.500);
	--text-secondary-solid-hover: theme(colors.gray.600);
	--text-secondary-solid-focus: theme(colors.gray.600);
	--text-secondary-solid-active: theme(colors.gray.700);
	--text-secondary-solid-disabled: theme(colors.gray.400);

	--text-inverse-secondary: theme(colors.white / 79%);
	--text-inverse-secondary-hover: theme(colors.white / 82%);
	--text-inverse-secondary-focus: theme(colors.white / 82%);
	--text-inverse-secondary-active: theme(colors.white / 85%);
	--text-inverse-secondary-disabled: theme(colors.white / 50%);

	--text-inverse-secondary-solid: theme(colors.gray.400);
	--text-inverse-secondary-solid-hover: theme(colors.gray.300);
	--text-inverse-secondary-solid-focus: theme(colors.gray.200);
	--text-inverse-secondary-solid-active: theme(colors.gray.100);
	--text-inverse-secondary-solid-disabled: theme(colors.gray.500);

	/* Tertiary */

	--text-tertiary: theme(colors.black / 42%);
	--text-tertiary-hover: theme(colors.black / 45%);
	--text-tertiary-focus: theme(colors.black / 45%);
	--text-tertiary-active: theme(colors.black / 48%);
	--text-tertiary-disabled: theme(colors.black / 32%);

	--text-tertiary-solid: theme(colors.gray.400);
	--text-tertiary-solid-hover: theme(colors.gray.500);
	--text-tertiary-solid-focus: theme(colors.gray.500);
	--text-tertiary-solid-active: theme(colors.gray.600);
	--text-tertiary-solid-disabled: theme(colors.gray.400);

	--text-inverse-tertiary: theme(colors.white / 69%);
	--text-inverse-tertiary-hover: theme(colors.white / 72%);
	--text-inverse-tertiary-focus: theme(colors.white / 72%);
	--text-inverse-tertiary-active: theme(colors.white / 75%);
	--text-inverse-tertiary-disabled: theme(colors.white / 59%);

	--text-inverse-tertiary-solid: theme(colors.gray.500);
	--text-inverse-tertiary-solid-hover: theme(colors.gray.400);
	--text-inverse-tertiary-solid-focus: theme(colors.gray.300);
	--text-inverse-tertiary-solid-active: theme(colors.gray.200);
	--text-inverse-tertiary-solid-disabled: theme(colors.gray.500);

	/* Negative, Positive, Warning, Info */

	--text-negative: theme(colors.rose.600);
	--text-negative-hover: theme(colors.rose.700);
	--text-negative-focus: theme(colors.rose.800);
	--text-negative-active: theme(colors.rose.900);
	--text-negative-disabled: theme(colors.rose.600);

	--text-positive: theme(colors.emerald.600);
	--text-positive-hover: theme(colors.emerald.700);
	--text-positive-focus: theme(colors.emerald.800);
	--text-positive-active: theme(colors.emerald.900);
	--text-positive-disabled: theme(colors.emerald.600);

	--text-warning: theme(colors.amber.600);
	--text-warning-hover: theme(colors.amber.700);
	--text-warning-focus: theme(colors.amber.800);
	--text-warning-active: theme(colors.amber.900);
	--text-warning-disabled: theme(colors.amber.600);

	--text-info: theme(colors.sky.600);
	--text-info-hover: theme(colors.sky.700);
	--text-info-focus: theme(colors.sky.800);
	--text-info-active: theme(colors.sky.900);
	--text-info-disabled: theme(colors.sky.600);

	/* Syntax Highlight */

	--text-syntax-normal: theme(colors.gray.800);
	--text-syntax-comment: theme(colors.gray.400);
	--text-syntax-number: theme(colors.cyan.600);
	--text-syntax-keyword: theme(colors.blue.700);
	--text-syntax-keyword-hover: theme(colors.blue.800);
	--text-syntax-atom: theme(colors.green.600);
	--text-syntax-string: theme(colors.sky.600);
	--text-syntax-error: theme(colors.red.600);
	--text-syntax-unknownVariable: theme(colors.gray.500);
	--text-syntax-knownVariable: theme(colors.cyan.700);
	--text-syntax-matchbracket: theme(colors.sky.400);
	--text-syntax-key: theme(colors.purple.800);
	--text-syntax-forbidden: theme(colors.pink.400);
	--text-syntax-prototypeKey: theme(colors.gray.400);

	--text-syntax-inverse-normal: theme(colors.gray.200);
	--text-syntax-inverse-comment: theme(colors.gray.600);
	--text-syntax-inverse-number: theme(colors.cyan.400);
	--text-syntax-inverse-keyword: theme(colors.blue.300);
	--text-syntax-inverse-atom: theme(colors.green.400);
	--text-syntax-inverse-string: theme(colors.sky.400);
	--text-syntax-inverse-error: theme(colors.red.400);
	--text-syntax-inverse-unknownVariable: theme(colors.gray.500);
	--text-syntax-inverse-knownVariable: theme(colors.cyan.300);
	--text-syntax-inverse-matchbracket: theme(colors.sky.600);
	--text-syntax-inverse-key: theme(colors.purple.200);
	--text-syntax-inverse-forbidden: theme(colors.pink.600);
	--text-syntax-inverse-prototypeKey: theme(colors.gray.600);

	/* ========================
    ========== Icons ==========
    ======================== */

	--icon-default: theme(colors.black / 50%);
	--icon-hover: theme(colors.black / 60%);
	--icon-focus: theme(colors.black / 70%);
	--icon-active: theme(colors.black / 100%);
	--icon-disabled: theme(colors.black / 40%);

	--icon-inverse-default: theme(colors.white / 75%);
	--icon-inverse-hover: theme(colors.white / 85%);
	--icon-inverse-focus: theme(colors.white / 85%);
	--icon-inverse-active: theme(colors.white / 90%);
	--icon-inverse-disabled: theme(colors.white / 50%);

	--icon-negative-default: theme(colors.rose.600 / 60%);
	--icon-negative-hover: theme(colors.rose.600 / 75%);
	--icon-negative-focus: theme(colors.rose.600 / 90%);
	--icon-negative-active: theme(colors.rose.600 / 100%);
	--icon-negative-disabled: theme(colors.rose.600 / 40%);

	--icon-positive-default: theme(colors.emerald.600 / 60%);
	--icon-positive-hover: theme(colors.emerald.600 / 75%);
	--icon-positive-active: theme(colors.emerald.600 / 90%);
	--icon-positive-focus: theme(colors.emerald.600 / 100%);
	--icon-positive-disabled: theme(colors.emerald.600 / 40%);

	--icon-warning-default: theme(colors.amber.600 / 60%);
	--icon-warning-hover: theme(colors.amber.600 / 75%);
	--icon-warning-active: theme(colors.amber.600 / 90%);
	--icon-warning-focus: theme(colors.amber.600 / 100%);
	--icon-warning-disabled: theme(colors.amber.600 / 40%);

	/* ==========================
    ========== Buttons ==========
    ========================== */

	/**
	 * DON'T EDIT THESE UNLESS YOU KNOW WHAT YOU'RE DOING.
	 * They reference the tokens above, and shouldn't need to be changed.
	**/

	/* ======== PRIMARY ======== */

	/* Background */

	--button-normal-primary-default-bg: var(--brand-primary);
	--button-normal-primary-hover-bg: var(--brand-primary-hover);
	--button-normal-primary-focus-bg: var(--brand-primary-focus);
	--button-normal-primary-active-bg: var(--brand-primary-active);
	--button-normal-primary-disabled-bg: var(--brand-primary-disabled);

	--button-negative-primary-default-bg: var(--state-negative);
	--button-negative-primary-hover-bg: var(--state-negative-hover);
	--button-negative-primary-focus-bg: var(--state-negative-focus);
	--button-negative-primary-active-bg: var(--state-negative-active);
	--button-negative-primary-disabled-bg: var(--state-negative-disabled);

	--button-positive-primary-default-bg: var(--state-positive);
	--button-positive-primary-hover-bg: var(--state-positive-hover);
	--button-positive-primary-focus-bg: var(--state-positive-focus);
	--button-positive-primary-active-bg: var(--state-positive-active);
	--button-positive-primary-disabled-bg: var(--state-positive-disabled);

	--button-warning-primary-default-bg: var(--state-warning);
	--button-warning-primary-hover-bg: var(--state-warning-hover);
	--button-warning-primary-focus-bg: var(--state-warning-focus);
	--button-warning-primary-active-bg: var(--state-warning-active);
	--button-warning-primary-disabled-bg: var(--state-warning-disabled);

	/* Text */

	--button-normal-primary-default-text: var(--text-fixed-light-default);
	--button-normal-primary-hover-text: var(--text-fixed-light-hover);
	--button-normal-primary-focus-text: var(--text-fixed-light-focus);
	--button-normal-primary-active-text: var(--text-fixed-light-active);
	--button-normal-primary-disabled-text: var(--text-fixed-light-disabled);

	--button-negative-primary-default-text: var(--text-fixed-light-default);
	--button-negative-primary-hover-text: var(--text-fixed-light-hover);
	--button-negative-primary-focus-text: var(--text-fixed-light-focus);
	--button-negative-primary-active-text: var(--text-fixed-light-active);
	--button-negative-primary-disabled-text: var(--text-fixed-light-disabled);

	--button-positive-primary-default-text: var(--text-fixed-light-default);
	--button-positive-primary-hover-text: var(--text-fixed-light-hover);
	--button-positive-primary-focus-text: var(--text-fixed-light-focus);
	--button-positive-primary-active-text: var(--text-fixed-light-active);
	--button-positive-primary-disabled-text: var(--text-fixed-light-disabled);

	--button-warning-primary-default-text: var(--text-fixed-light-default);
	--button-warning-primary-hover-text: var(--text-fixed-light-hover);
	--button-warning-primary-focus-text: var(--text-fixed-light-focus);
	--button-warning-primary-active-text: var(--text-fixed-light-active);
	--button-warning-primary-disabled-text: var(--text-fixed-light-disabled);

	/* Icon */

	--button-normal-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-normal-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-normal-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-normal-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-normal-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	--button-negative-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-negative-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-negative-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-negative-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-negative-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	--button-positive-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-positive-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-positive-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-positive-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-positive-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	--button-warning-primary-default-enhancer: var(--icon-fixed-light-default);
	--button-warning-primary-hover-enhancer: var(--icon-fixed-light-hover);
	--button-warning-primary-focus-enhancer: var(--icon-fixed-light-focus);
	--button-warning-primary-active-enhancer: var(--icon-fixed-light-active);
	--button-warning-primary-disabled-enhancer: var(--icon-fixed-light-disabled);

	/* ======== SECONDARY ======== */

	/* Background */

	--button-normal-secondary-default-bg: var(--bg-translucent-primary);
	--button-normal-secondary-hover-bg: var(--bg-translucent-secondary);
	--button-normal-secondary-focus-bg: var(--bg-translucent-tertiary);
	--button-normal-secondary-active-bg: var(--bg-translucent-quaternary);
	--button-normal-secondary-disabled-bg: var(--bg-translucent-secondary);

	--button-negative-secondary-default-bg: var(--bg-translucent-negative-default);
	--button-negative-secondary-hover-bg: var(--bg-translucent-negative-hover);
	--button-negative-secondary-focus-bg: var(--bg-translucent-negative-focus);
	--button-negative-secondary-active-bg: var(--bg-translucent-negative-active);
	--button-negative-secondary-disabled-bg: var(--bg-translucent-negative-disabled);

	--button-positive-secondary-default-bg: var(--bg-translucent-positive-default);
	--button-positive-secondary-hover-bg: var(--bg-translucent-positive-hover);
	--button-positive-secondary-focus-bg: var(--bg-translucent-positive-focus);
	--button-positive-secondary-active-bg: var(--bg-translucent-positive-active);
	--button-positive-secondary-disabled-bg: var(--bg-translucent-positive-disabled);

	--button-warning-secondary-default-bg: var(--bg-translucent-warning-default);
	--button-warning-secondary-hover-bg: var(--bg-translucent-warning-hover);
	--button-warning-secondary-focus-bg: var(--bg-translucent-warning-focus);
	--button-warning-secondary-active-bg: var(--bg-translucent-warning-active);
	--button-warning-secondary-disabled-bg: var(--bg-translucent-warning-disabled);

	/* Text */

	--button-normal-secondary-default-text: var(--text-primary);
	--button-normal-secondary-hover-text: var(--text-primary-hover);
	--button-normal-secondary-focus-text: var(--text-primary-focus);
	--button-normal-secondary-active-text: var(--text-primary-active);
	--button-normal-secondary-disabled-text: var(--text-primary-disabled);

	--button-negative-secondary-default-text: var(--text-negative);
	--button-negative-secondary-hover-text: var(--text-negative-hover);
	--button-negative-secondary-focus-text: var(--text-negative-focus);
	--button-negative-secondary-active-text: var(--text-negative-active);
	--button-negative-secondary-disabled-text: var(--text-negative-disabled);

	--button-positive-secondary-default-text: var(--text-positive);
	--button-positive-secondary-hover-text: var(--text-positive-hover);
	--button-positive-secondary-focus-text: var(--text-positive-focus);
	--button-positive-secondary-active-text: var(--text-positive-active);
	--button-positive-secondary-disabled-text: var(--text-positive-disabled);

	--button-warning-secondary-default-text: var(--text-warning);
	--button-warning-secondary-hover-text: var(--text-warning-hover);
	--button-warning-secondary-focus-text: var(--text-warning-focus);
	--button-warning-secondary-active-text: var(--text-warning-active);
	--button-warning-secondary-disabled-text: var(--text-warning-disabled);

	/* Border */

	--button-normal-secondary-default-border: var(--border-interactive-normal-default);
	--button-normal-secondary-hover-border: var(--border-interactive-normal-hover);
	--button-normal-secondary-focus-border: var(--border-interactive-normal-focus);
	--button-normal-secondary-active-border: var(--border-interactive-normal-active);
	--button-normal-secondary-disabled-border: var(--border-interactive-normal-disabled);

	--button-negative-secondary-default-border: var(--border-interactive-negative-default);
	--button-negative-secondary-hover-border: var(--border-interactive-negative-hover);
	--button-negative-secondary-focus-border: var(--border-interactive-negative-focus);
	--button-negative-secondary-active-border: var(--border-interactive-negative-active);
	--button-negative-secondary-disabled-border: var(--border-interactive-negative-disabled);

	--button-positive-secondary-default-border: var(--border-interactive-positive-default);
	--button-positive-secondary-hover-border: var(--border-interactive-positive-hover);
	--button-positive-secondary-focus-border: var(--border-interactive-positive-focus);
	--button-positive-secondary-active-border: var(--border-interactive-positive-active);
	--button-positive-secondary-disabled-border: var(--border-interactive-positive-disabled);

	--button-warning-secondary-default-border: var(--border-interactive-warning-default);
	--button-warning-secondary-hover-border: var(--border-interactive-warning-hover);
	--button-warning-secondary-focus-border: var(--border-interactive-warning-focus);
	--button-warning-secondary-active-border: var(--border-interactive-warning-active);
	--button-warning-secondary-disabled-border: var(--border-interactive-warning-disabled);

	/* Icon */

	--button-normal-secondary-default-enhancer: var(--icon-default);
	--button-normal-secondary-hover-enhancer: var(--icon-hover);
	--button-normal-secondary-focus-enhancer: var(--icon-focus);
	--button-normal-secondary-active-enhancer: var(--icon-active);
	--button-normal-secondary-disabled-enhancer: var(--icon-disabled);

	--button-negative-secondary-default-enhancer: var(--icon-negative-default);
	--button-negative-secondary-hover-enhancer: var(--icon-negative-hover);
	--button-negative-secondary-focus-enhancer: var(--icon-negative-focus);
	--button-negative-secondary-active-enhancer: var(--icon-negative-active);
	--button-negative-secondary-disabled-enhancer: var(--icon-negative-disabled);

	--button-positive-secondary-default-enhancer: var(--icon-positive-default);
	--button-positive-secondary-hover-enhancer: var(--icon-positive-hover);
	--button-positive-secondary-focus-enhancer: var(--icon-positive-focus);
	--button-positive-secondary-active-enhancer: var(--icon-positive-active);
	--button-positive-secondary-disabled-enhancer: var(--icon-positive-disabled);

	--button-warning-secondary-default-enhancer: var(--icon-warning-default);
	--button-warning-secondary-hover-enhancer: var(--icon-warning-hover);
	--button-warning-secondary-focus-enhancer: var(--icon-warning-focus);
	--button-warning-secondary-active-enhancer: var(--icon-warning-active);
	--button-warning-secondary-disabled-enhancer: var(--icon-warning-disabled);

	/* ======== TERTIARY ======== */

	/* Background */

	--button-normal-tertiary-default-bg: var(--bg-translucent-primary);
	--button-normal-tertiary-hover-bg: var(--bg-translucent-secondary);
	--button-normal-tertiary-focus-bg: var(--bg-translucent-tertiary);
	--button-normal-tertiary-active-bg: var(--bg-translucent-quaternary);
	--button-normal-tertiary-disabled-bg: var(--bg-translucent-primary);

	--button-negative-tertiary-default-bg: var(--bg-translucent-negative-default);
	--button-negative-tertiary-hover-bg: var(--bg-translucent-negative-hover);
	--button-negative-tertiary-focus-bg: var(--bg-translucent-negative-focus);
	--button-negative-tertiary-active-bg: var(--bg-translucent-negative-active);
	--button-negative-tertiary-disabled-bg: var(--bg-translucent-negative-disabled);

	--button-positive-tertiary-default-bg: var(--bg-translucent-positive-default);
	--button-positive-tertiary-hover-bg: var(--bg-translucent-positive-hover);
	--button-positive-tertiary-focus-bg: var(--bg-translucent-positive-focus);
	--button-positive-tertiary-active-bg: var(--bg-translucent-positive-active);
	--button-positive-tertiary-disabled-bg: var(--bg-translucent-positive-disabled);

	--button-warning-tertiary-default-bg: var(--bg-translucent-warning-default);
	--button-warning-tertiary-hover-bg: var(--bg-translucent-warning-hover);
	--button-warning-tertiary-focus-bg: var(--bg-translucent-warning-focus);
	--button-warning-tertiary-active-bg: var(--bg-translucent-warning-active);
	--button-warning-tertiary-disabled-bg: var(--bg-translucent-warning-disabled);

	/* Text */

	--button-normal-tertiary-default-text: var(--text-primary);
	--button-normal-tertiary-hover-text: var(--text-primary-hover);
	--button-normal-tertiary-focus-text: var(--text-primary-focus);
	--button-normal-tertiary-active-text: var(--text-primary-active);
	--button-normal-tertiary-disabled-text: var(--text-primary-disabled);

	--button-negative-tertiary-default-text: var(--text-negative);
	--button-negative-tertiary-hover-text: var(--text-negative-hover);
	--button-negative-tertiary-focus-text: var(--text-negative-focus);
	--button-negative-tertiary-active-text: var(--text-negative-active);
	--button-negative-tertiary-disabled-text: var(--text-negative-disabled);

	--button-positive-tertiary-default-text: var(--text-positive);
	--button-positive-tertiary-hover-text: var(--text-positive-hover);
	--button-positive-tertiary-focus-text: var(--text-positive-focus);
	--button-positive-tertiary-active-text: var(--text-positive-active);
	--button-positive-tertiary-disabled-text: var(--text-positive-disabled);

	--button-warning-tertiary-default-text: var(--text-warning);
	--button-warning-tertiary-hover-text: var(--text-warning-hover);
	--button-warning-tertiary-focus-text: var(--text-warning-focus);
	--button-warning-tertiary-active-text: var(--text-warning-active);
	--button-warning-tertiary-disabled-text: var(--text-warning-disabled);

	/* Border */

	--button-normal-tertiary-default-border: none;
	--button-normal-tertiary-hover-border: none;
	--button-normal-tertiary-focus-border: var(--border-interactive-normal-focus);
	--button-normal-tertiary-active-border: var(--border-interactive-normal-active);
	--button-normal-tertiary-disabled-border: none;

	--button-negative-tertiary-default-border: none;
	--button-negative-tertiary-hover-border: none;
	--button-negative-tertiary-focus-border: var(--border-interactive-negative-focus);
	--button-negative-tertiary-active-border: var(--border-interactive-negative-active);
	--button-negative-tertiary-disabled-border: none;

	--button-positive-tertiary-default-border: none;
	--button-positive-tertiary-hover-border: none;
	--button-positive-tertiary-focus-border: var(--border-interactive-positive-focus);
	--button-positive-tertiary-active-border: var(--border-interactive-positive-active);
	--button-positive-tertiary-disabled-border: none;

	--button-warning-tertiary-default-border: none;
	--button-warning-tertiary-hover-border: none;
	--button-warning-tertiary-focus-border: var(--border-interactive-warning-focus);
	--button-warning-tertiary-active-border: var(--border-interactive-warning-active);
	--button-warning-tertiary-disabled-border: none;

	/* Icon */

	--button-normal-tertiary-default-enhancer: var(--icon-default);
	--button-normal-tertiary-hover-enhancer: var(--icon-hover);
	--button-normal-tertiary-focus-enhancer: var(--icon-focus);
	--button-normal-tertiary-active-enhancer: var(--icon-active);
	--button-normal-tertiary-disabled-enhancer: var(--icon-disabled);

	--button-negative-tertiary-default-enhancer: var(--icon-negative-default);
	--button-negative-tertiary-hover-enhancer: var(--icon-negative-hover);
	--button-negative-tertiary-focus-enhancer: var(--icon-negative-focus);
	--button-negative-tertiary-active-enhancer: var(--icon-negative-active);
	--button-negative-tertiary-disabled-enhancer: var(--icon-negative-disabled);

	--button-positive-tertiary-default-enhancer: var(--icon-positive-default);
	--button-positive-tertiary-hover-enhancer: var(--icon-positive-hover);
	--button-positive-tertiary-focus-enhancer: var(--icon-positive-focus);
	--button-positive-tertiary-active-enhancer: var(--icon-positive-active);
	--button-positive-tertiary-disabled-enhancer: var(--icon-positive-disabled);

	--button-warning-tertiary-default-enhancer: var(--icon-warning-default);
	--button-warning-tertiary-hover-enhancer: var(--icon-warning-hover);
	--button-warning-tertiary-focus-enhancer: var(--icon-warning-focus);
	--button-warning-tertiary-active-enhancer: var(--icon-warning-active);
	--button-warning-tertiary-disabled-enhancer: var(--icon-warning-disabled);

	/* ======== QUATERNARY ======== */

	/* Background */

	--button-normal-quaternary-default-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-hover-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-focus-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-active-bg: var(--bg-translucent-primary);
	--button-normal-quaternary-disabled-bg: var(--bg-translucent-secondary);

	--button-negative-quaternary-default-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-hover-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-focus-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-active-bg: var(--bg-translucent-negative-default);
	--button-negative-quaternary-disabled-bg: var(--bg-translucent-negative-disabled);

	--button-positive-quaternary-default-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-hover-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-focus-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-active-bg: var(--bg-translucent-positive-default);
	--button-positive-quaternary-disabled-bg: var(--bg-translucent-positive-disabled);

	--button-warning-quaternary-default-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-hover-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-focus-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-active-bg: var(--bg-translucent-warning-default);
	--button-warning-quaternary-disabled-bg: var(--bg-translucent-warning-disabled);

	/* Text */

	--button-normal-quaternary-default-text: var(--text-secondary);
	--button-normal-quaternary-hover-text: var(--text-secondary-hover);
	--button-normal-quaternary-focus-text: var(--text-secondary-focus);
	--button-normal-quaternary-active-text: var(--text-secondary-active);
	--button-normal-quaternary-disabled-text: var(--text-secondary-disabled);

	--button-negative-quaternary-default-text: var(--text-negative);
	--button-negative-quaternary-hover-text: var(--text-negative-hover);
	--button-negative-quaternary-focus-text: var(--text-negative-focus);
	--button-negative-quaternary-active-text: var(--text-negative-active);
	--button-negative-quaternary-disabled-text: var(--text-negative-disabled);

	--button-positive-quaternary-default-text: var(--text-positive);
	--button-positive-quaternary-hover-text: var(--text-positive-hover);
	--button-positive-quaternary-focus-text: var(--text-positive-focus);
	--button-positive-quaternary-active-text: var(--text-positive-active);
	--button-positive-quaternary-disabled-text: var(--text-positive-disabled);

	--button-warning-quaternary-default-text: var(--text-warning);
	--button-warning-quaternary-hover-text: var(--text-warning-hover);
	--button-warning-quaternary-focus-text: var(--text-warning-focus);
	--button-warning-quaternary-active-text: var(--text-warning-active);
	--button-warning-quaternary-disabled-text: var(--text-warning-disabled);

	/* Border */

	--button-normal-quaternary-default-border: none;
	--button-normal-quaternary-hover-border: none;
	--button-normal-quaternary-focus-border: none;
	--button-normal-quaternary-active-border: none;
	--button-normal-quaternary-disabled-border: none;

	--button-negative-quaternary-default-border: none;
	--button-negative-quaternary-hover-border: none;
	--button-negative-quaternary-focus-border: none;
	--button-negative-quaternary-active-border: none;
	--button-negative-quaternary-disabled-border: none;

	--button-positive-quaternary-default-border: none;
	--button-positive-quaternary-hover-border: none;
	--button-positive-quaternary-focus-border: none;
	--button-positive-quaternary-active-border: none;
	--button-positive-quaternary-disabled-border: none;

	--button-warning-quaternary-default-border: none;
	--button-warning-quaternary-hover-border: none;
	--button-warning-quaternary-focus-border: none;
	--button-warning-quaternary-active-border: none;
	--button-warning-quaternary-disabled-border: none;

	/* Icon */

	--button-normal-quaternary-default-enhancer: var(--icon-default);
	--button-normal-quaternary-hover-enhancer: var(--icon-hover);
	--button-normal-quaternary-focus-enhancer: var(--icon-focus);
	--button-normal-quaternary-active-enhancer: var(--icon-active);
	--button-normal-quaternary-disabled-enhancer: var(--icon-disabled);

	--button-negative-quaternary-default-enhancer: var(--icon-negative-default);
	--button-negative-quaternary-hover-enhancer: var(--icon-negative-hover);
	--button-negative-quaternary-focus-enhancer: var(--icon-negative-focus);
	--button-negative-quaternary-active-enhancer: var(--icon-negative-active);
	--button-negative-quaternary-disabled-enhancer: var(--icon-negative-disabled);

	--button-positive-quaternary-default-enhancer: var(--icon-positive-default);
	--button-positive-quaternary-hover-enhancer: var(--icon-positive-hover);
	--button-positive-quaternary-focus-enhancer: var(--icon-positive-focus);
	--button-positive-quaternary-active-enhancer: var(--icon-positive-active);
	--button-positive-quaternary-disabled-enhancer: var(--icon-positive-disabled);

	--button-warning-quaternary-default-enhancer: var(--icon-warning-default);
	--button-warning-quaternary-hover-enhancer: var(--icon-warning-hover);
	--button-warning-quaternary-focus-enhancer: var(--icon-warning-focus);
	--button-warning-quaternary-active-enhancer: var(--icon-warning-active);
	--button-warning-quaternary-disabled-enhancer: var(--icon-warning-disabled);
}
