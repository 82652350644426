.inputdatalist {
	@apply relative 
            block
            w-full
			cursor-text
			rounded
			shadow
			transition-all
            hover:shadow-md
            focus:shadow-none;

	input:focus {
		outline: 0;
	}
}

.inputdatalist--focused {
	.inputdatalist__display {
		@apply border-brand 
                outline-none 
                ring 
                ring-default;
	}
}

.inputdatalist__display {
	@apply flex 
            w-full 
            flex-wrap
            items-center
            rounded
            border
            bg-primary;

	input {
		line-height: 1;
	}

	& > * {
		margin: 0.1em 0.5em;
	}
}

.inputdatalist__dropdown {
	@apply absolute 
            mt-1
            overflow-auto
            rounded-md
            border 
            bg-primary
            shadow-md
            focus:outline-none;

	top: 100%;
	width: 100%;

	max-height: 20em;

	& > ul {
		display: block;
		width: 100%;

		@apply flex
                flex-col
                py-1;

		li {
			@apply flex
                    w-full;

			&.isSelected {
				button {
					@apply bg-button-normal-secondary-active 
                            text-primary-active;
				}
			}

			& > button {
				@apply flex 
                        w-full 
                        items-center
                        bg-primary
                        text-left;

				&:disabled {
					@apply opacity-60;
				}

				&:hover:not(:disabled) {
					@apply bg-button-normal-primary-hover 
                            text-inverse-primary;
				}
				&:focus {
					outline: 0;
					@apply bg-button-normal-primary-focus 
                            text-inverse-primary-hover;
				}
			}
		}
	}
}

.inputdatalist.inputdatalist--lg {
	@apply text-lg;
	.inputdatalist__display {
		/* x 16 y 12 */
		@apply px-2 py-2;
		min-height: 48px;
	}
	.inputdatalist__dropdown {
		& > ul {
			li {
				& > button {
					@apply px-4 py-3;
				}
			}
		}
	}
}

.inputdatalist.inputdatalist--md {
	@apply text-md;
	.inputdatalist__display {
		/* x 12 y 8 */
		@apply px-1.5 py-1.5;
		min-height: 40px;
	}
	.inputdatalist__dropdown {
		& > ul {
			li {
				& > button {
					@apply px-3 py-2;
				}
			}
		}
	}
}

.inputdatalist.inputdatalist--sm {
	@apply text-sm;
	.inputdatalist__display {
		/* x 12 y 8 */
		@apply px-1 py-1;
	}
	.inputdatalist__dropdown {
		& > ul {
			li {
				& > button {
					@apply px-3 py-2;
				}
			}
		}
	}
}
