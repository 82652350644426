.input-text {
	@apply relative
        inline-flex
        border
		border-primary
        bg-primary
		shadow
        transition-colors
		hover:shadow-md
		focus:shadow-none;

	&[data-disableElevation="true"] {
		@apply shadow-none;
	}

	&.input-text--segment-right:hover,
	&.input-text--segment-center:hover,
	&.input-text--segment-left:hover {
		@apply z-component-flat;
	}

	&.input-text--segment-left:not(:first-child),
	&.input-text--segment-center:not(:first-child),
	&.input-text--segment-right:not(:first-child) {
		@apply -ml-[1px];
	}

	&.input-text--lg {
		@apply text-lg;

		&.input-text--segment-none {
			@apply rounded-lg;
		}

		&.input-text--segment-left {
			@apply rounded-l-lg;
		}

		&.input-text--segment-center {
			@apply rounded-none;
		}

		&.input-text--segment-right {
			@apply rounded-r-lg;
		}

		& > input,
		& > textarea {
			@apply px-3 py-[11px];
		}
	}

	&.input-text--md {
		@apply text-md;

		&.input-text--segment-none {
			@apply rounded-md;
		}

		&.input-text--segment-left {
			@apply rounded-l-md;
		}

		&.input-text--segment-center {
			@apply rounded-none;
		}

		&.input-text--segment-right {
			@apply rounded-r-md;
		}

		& > input,
		& > textarea {
			@apply px-3 py-[7px];
		}
	}

	&.input-text--sm {
		@apply text-sm;

		&.input-text--segment-none {
			@apply rounded;
		}

		&.input-text--segment-left {
			@apply rounded-l;
		}

		&.input-text--segment-center {
			@apply rounded-none;
		}

		&.input-text--segment-right {
			@apply rounded-r;
		}

		& > input,
		& > textarea {
			/* Makes input height exactly 32px */
			@apply px-2 py-[5px];
		}
	}

	.input-text__modifiers {
		@apply flex items-center space-x-1 pr-2;
	}

	input,
	textarea {
		@apply min-w-0
            flex-grow
            rounded-none
            border-0
            bg-transparent;
		--transition-speed: 0.2s;
		--transition-func: cubic-bezier(0.41, 0.01, 1, 0.38);
		transition: height var(--transition-speed) var(--transition-func);

		&:focus {
			@apply outline-none;
		}
	}

	svg {
		@apply fill-icon-default;
	}

	&:hover,
	.input-text--hover,
	&:not(.input-text--disabled) {
		@apply hover:border-primary-hover;

		svg {
			@apply fill-icon-hover;
		}
	}

	&.input-text:focus-within {
		@apply border-primary-focus
            outline-none
            ring
            ring-default;

		&.input-text--negative {
			@apply border-negative
                ring-negative;
		}
	}

	&.input-text--negative {
		@apply border-negative
		ring
		ring-negative
		hover:border-negative-hover
		focus:border-negative-focus
		active:border-negative-active
		disabled:border-negative-disabled;
	}

	&.input-text--disabled {
		@apply cursor-not-allowed
            border-primary
			bg-tertiary
			shadow-none;

		& * {
			@apply cursor-not-allowed;
		}
	}
}

.input-text__main-icon {
	@apply pl-2 pr-0;
}

.input-text__secondary-icon {
	@apply absolute right-3 py-2 pl-0;
}

/* .input-text__icon {
    @apply fill-icon-default;
} */

.input-text__modifiers > button > * {
	pointer-events: none;
}

.input-text--mono {
	input {
		@apply font-mono;
	}
}

.input-text--success {
	@apply border-positive bg-positive-light;

	&.input-text:focus-within {
		@apply border-positive
            outline-none
            ring
            ring-positive;
	}
}

.theme-dark {
	.input-text--success {
		@apply border-positive-light;

		/*TODO*/
		background-color: rgba(6, 78, 59, 0.5);
	}
}
