/***
* This CSS is designed to cover layout and styling for
* typography that exists inside the canvas only
* (basically, anything inside a `.rich-text` wrapper).
*
* An explicit design goal is to avoid duplicating CSS properties,
* which has historically led to inconsistencies. The selectors
* are a little nuts, because we need to cover things like the
* markdown parser, which outputs markup we don't have control over.
**/

::selection:not(.ProseMirror-hideselection *) {
	@apply bg-brand text-white;
}

/***************************************
----> BASE
/**************************************/

/* This is the default left + right padding of narrow cells, which makes
space for the add and drag icons in the cell margins.
The screen sizes in the media queries refer to the tailwind
screen sizes, but there's not an easy way to use the tailwind vars here.*/
:root {
	--default-padding: 1rem;
}

.ProseMirror-hideselection *::selection {
	background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
	background: transparent;
}
.ProseMirror-hideselection {
	caret-color: transparent;
}

/* TODO: The canvas margin button positions have to also be updated when the
padding changes. This needs to happen in the same PR to prevent text from
overlapping the cell margin buttons. */

/* Tailwind screen size: "sm" */
@media (min-width: 640px) {
	:root {
		--default-padding: 1.5rem;
	}
}

/* Tailwind screen size: "md" */
@media (min-width: 768px) {
	:root {
		--default-padding: 4rem;
	}
}

/* Tailwind screen size: "lg" */
@media (min-width: 1024px) {
	:root {
		--default-padding: 5rem;
	}
}

.rich-text {
	&.no-margin {
		--default-padding: 0rem;
	}

	/** ProseMirror defaults */
	word-wrap: break-word;
	white-space: break-spaces;
	-webkit-font-variant-ligatures: none;
	font-variant-ligatures: none;
	font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */

	@apply cursor-text text-sm;

	/***************************************
	----> QUIRKS (Be very careful changing these)
	/**************************************/

	[data-text-cell] {
		@apply scroll-m-8;
	}

	/* Prevents an outline around the ProseMirror document */
	&:focus {
		outline: none;
	}

	[data-node-type="compute_cell"] .prosemirror-compute-cell--result {
		@apply border-l-4 border-transparent;
	}

	[data-node-type="compute_cell"]:not(.no-focus-state)
		.prosemirror-compute-cell--selected.prosemirror-compute-cell--result {
		@apply border-palette-brand-5;
	}

	/* This applies the selection border to compute cells on focus */
	&.ProseMirror-focused
		[data-node-type="compute_cell"]:not(.no-focus-state)
		.prosemirror-compute-cell--selected.prosemirror-compute-cell--result {
		@apply border-brand;
	}

	/* This applies the ring to compute cells on focus */
	.prosemirror-compute-cell--focus:not(.no-focus-state) .prosemirror-compute-cell--result {
		@apply ring
		ring-normal;
	}

	[data-node-type="compute_cell"]:not(.use-x-scroll-container)
		.prosemirror-compute-cell--computed-result-container {
		@apply min-w-0;
	}

	/* (Firefox only) fixes a bug where spaces at the end of bullets get
	a <br> instead of an &nbsp; character, resulting in extra space */
	.ProseMirror-trailingBreak {
		@apply m-0;
	}

	/* Fix for presencePlugin */
	.ProseMirror-separator {
		@apply inline;
	}

	/* This is a fallback for any indent level greater than 7 */
	[data-indent] {
		@apply ml-[12rem];
	}
	[data-indent="0"] {
		@apply ml-[0rem];
	}
	[data-indent="1"] {
		@apply ml-[1.5rem];
	}
	[data-indent="2"] {
		@apply ml-[3rem];
	}
	[data-indent="3"] {
		@apply ml-[4.5rem];
	}
	[data-indent="4"] {
		@apply ml-[6rem];
	}
	[data-indent="5"] {
		@apply ml-[7.5rem];
	}
	[data-indent="6"] {
		@apply ml-[9rem];
	}
	[data-indent="7"] {
		@apply ml-[10.5rem];
	}

	[data-text-cell] {
		@apply break-words;
	}

	/***************************************
	----> PLACEHOLDER TEXT
	/**************************************/
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		&.empty-node {
			&::before {
				@apply pointer-events-none absolute cursor-text text-tertiary;
			}
		}
	}

	h1.empty-node::before {
		content: "Heading 1";
	}

	h2.empty-node::before {
		content: "Heading 2";
	}

	h3.empty-node::before {
		content: "Heading 3";
	}

	h4.empty-node::before {
		content: "Heading 4";
	}

	h5.empty-node::before {
		content: "Heading 5";
	}

	h6.empty-node::before {
		content: "Heading 6";
	}

	> p.empty-node::before {
		content: attr(data-placeholder-text);
	}

	/***************************************
	----> MARKS & CUSTOM ELEMENTS
	/**************************************/

	div > div.cm-code-block > div > div.cm-editor > div.cm-scroller,
	div > div.cm-code-block > div > div.cm-editor > div.cm-scroller:first-of-type {
		@apply overflow-x-auto
		rounded-md
		border
		bg-secondary
		p-4;
	}

	/* Code (block level) */
	pre,
	pre:first-of-type {
		@apply overflow-x-auto
		rounded-md
		bg-secondary
		px-3
		py-2
		text-sm
		font-normal
		text-primary;

		code {
			@apply p-0;
		}
	}

	/* Code (inline) */
	code {
		@apply rounded
		bg-tertiary
		px-1
		py-0.5
		text-sm
		font-medium;
	}

	/* Horizontal rules */
	p.hr {
		@apply border-primary
		py-3;

		hr {
			@apply -mt-[1px];
		}
	}

	.markdown hr {
		@apply my-7;
	}

	/* Blockquotes */
	blockquote p {
		@apply mt-0
		border-l-4
		border-dark-spectrum-gray
		py-3
		pl-3;

		& + p {
			@apply -mt-4 pt-4;
		}

		quotes: "“" "”" "‘" "’";
	}

	/* Callouts */
	callout > div {
		@apply mt-0
		block
		rounded
		border
		border-light-spectrum-gray-active
		bg-light-spectrum-gray
		px-4
		py-3
		text-sm;

		& p:last-child {
			@apply mb-0;
		}

		& p:not(:first-child) {
			@apply mt-4;
		}

		& + p {
			@apply pt-4;
		}
	}

	/***************************************
	----> LISTS
	/**************************************/

	div[data-node-type="list_item"] {
		position: relative;

		&[data-bullet="-"] > p {
			@apply -ml-2 pl-2;
		}

		&[data-bullet="+"] > p {
			margin-left: -7px;
			padding-left: 7px;
		}

		&[data-bullet="*"] > p {
			margin-left: -4px;
			padding-left: 4px;
		}
	}

	div[data-node-type="list_item"],
	div[data-node-type="to_do_item"] {
		@apply list-none;

		& ::marker {
			@apply text-secondary;
		}

		/* Indent level 0 */
		&[data-indent="0"] {
			@apply pl-[calc(var(--default-padding)+1.5rem)];
			counter-increment: indent-0-count;

			~ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"]) {
				counter-reset: indent-0-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-0-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="1"] {
				counter-reset: indent-1-count;
			}

			+ div[data-indent="2"] {
				counter-reset: indent-2-count;
			}

			+ div[data-indent="3"] {
				counter-reset: indent-3-count;
			}

			+ div[data-indent="4"] {
				counter-reset: indent-4-count;
			}

			+ div[data-indent="5"] {
				counter-reset: indent-5-count;
			}

			+ div[data-indent="6"] {
				counter-reset: indent-6-count;
			}

			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="1"] {
			@apply pl-[calc(var(--default-padding)+3rem)];
			counter-increment: indent-1-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-1-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-1-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="2"] {
				counter-reset: indent-2-count;
			}

			+ div[data-indent="3"] {
				counter-reset: indent-3-count;
			}

			+ div[data-indent="4"] {
				counter-reset: indent-4-count;
			}

			+ div[data-indent="5"] {
				counter-reset: indent-5-count;
			}

			+ div[data-indent="6"] {
				counter-reset: indent-6-count;
			}

			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="2"] {
			@apply pl-[calc(var(--default-padding)+4.5rem)];
			counter-increment: indent-2-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-2-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-2-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="3"] {
				counter-reset: indent-3-count;
			}

			+ div[data-indent="4"] {
				counter-reset: indent-4-count;
			}

			+ div[data-indent="5"] {
				counter-reset: indent-5-count;
			}

			+ div[data-indent="6"] {
				counter-reset: indent-6-count;
			}

			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="3"] {
			@apply pl-[calc(var(--default-padding)+6rem)];
			counter-increment: indent-3-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-3-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-3-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="4"] {
				counter-reset: indent-4-count;
			}

			+ div[data-indent="5"] {
				counter-reset: indent-5-count;
			}

			+ div[data-indent="6"] {
				counter-reset: indent-6-count;
			}

			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="4"] {
			@apply pl-[calc(var(--default-padding)+7.5rem)];
			counter-increment: indent-4-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-4-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-4-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="5"] {
				counter-reset: indent-5-count;
			}

			+ div[data-indent="6"] {
				counter-reset: indent-6-count;
			}

			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="5"] {
			@apply pl-[calc(var(--default-padding)+9rem)];
			counter-increment: indent-5-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-5-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-5-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="6"] {
				counter-reset: indent-6-count;
			}

			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="6"] {
			@apply pl-[calc(var(--default-padding)+10.5rem)];
			counter-increment: indent-6-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-6-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-6-count) ". ";
			}

			/* Reset the counter for following indent levels */
			+ div[data-indent="7"] {
				counter-reset: indent-7-count;
			}
		}

		&[data-indent="7"] {
			@apply pl-[calc(var(--default-padding)+10.5rem)];
			counter-increment: indent-7-count;

			&:has(+ :not(div[data-node-type="list_item"]):not(div[data-node-type="to_do_item"])) {
				counter-reset: indent-7-count;
			}

			&[data-bullet="#"]::before {
				content: counter(indent-7-count) ". ";
			}
		}

		&[data-bullet="#"]::before {
			/* ml and w need to match for alignment to look correct */
			@apply absolute -ml-12 w-12 pr-1 text-right text-md text-secondary;
		}

		&[data-bullet="-"] {
			/*
			 * IMPORTANT: Unicode here prevents Next.js hydration errors and misencodings visible in
			 * the frontend.
			 *
			 * `\2013\0020` is Unicode for `- `. This is occasionally getting mangled and delivered
			 * as `â€“`, for some reason. Using the precise Unicode codepoints seems to fix the
			 * issue(?). I have no idea why this happens. See the related issue[1] for more details.
			 *
			 * [1]: https://github.com/moment-eng/moment/issues/9240
			 */
			list-style-type: "\2013\0020";
		}

		&[data-bullet="+"] {
			/*
			 * IMPORTANT: Unicode here prevents Next.js hydration errors and misencodings visible in
			 * the frontend.
			 *
			 * `\002B\0020` is Unicode for `+ `. This DOES NOT get mangled, as far as I can tell,
			 * but for some reason `-` list items do (see comment above), so I'm just using the
			 * precise Unicode codepoints for both to be safe. See also related issue[1] for more
			 * details.
			 *
			 * [1]: https://github.com/moment-eng/moment/issues/9240
			 */
			list-style-type: "\002B\0020";
		}

		&[data-bullet="*"] {
			@apply list-disc;
		}

		> p {
			@apply list-item;
		}
	}

	/* Sink list items 1.5rem to align with checkboxes */
	div[data-node-type="to_do_item"] > * {
		@apply -ml-6;
	}

	/* Required because markdown cells don't use <p> inside <li> blocks */
	.markdown,
	.compute-cell-output {
		@apply text-md;

		ul,
		ol {
			li {
				@apply mx-[18px] p-1;

				&::marker {
					@apply text-secondary;
				}
			}
		}

		ul li {
			@apply list-disc;
		}

		ol li {
			@apply list-decimal;

			&::marker {
				@apply text-md text-secondary;
			}
		}
	}

	/***************************************
	----> VERTICAL MARGINS & SPACING
	/**************************************/

	& > *:last-child,
	.markdown .compute-cell-output > div > *:last-child {
		@apply mb-0;
	}

	& > *:first-child,
	.markdown .compute-cell-output > div > *:first-child {
		@apply mt-0;
	}

	/* Half */

	div[data-node-type="list_item"] > p,
	.markdown li {
		@apply mb-1;
	}

	/* Single */

	div[data-text-cell][data-node-type="to_do_item"] {
		@apply mb-1;
	}

	/*
	 * IMPORTANT: WORK AROUND SAFARI REFLOW BUG. See details here[1]. Essentially typing list items
	 * has wacky margins until some reflow event happens, and causes them to "snap" into the correct
	 * margins. This ONLY happens on Safari, and I am somewhat confident the CSS is correct.
	 *
	 * This link[2] shows the specific lines in the current revision of `main` that are problematic.
	 * They look like this:
	 *
	 *     div[data-text-cell]:not([data-node-type="list_item"]):not([data-node-type="to_do_item"]),
	 *     [... other rules ...] {
	 *         @apply mb-4;
	 *     }
	 *
	 * We are replacing these lines with the lines below, because these DO work on Safari. I am not
	 * sure why. I think these are both correct.
	 *
	 * [1]: https://github.com/moment-eng/moment/issues/9227 [2]:
	 * https://github.com/moment-eng/moment/blob/77a3f740e5775af60fed820c393a5f82138ff9f0/design-system/styles/components/_prose-mirror-overrides.css#L634-L637
	 */
	div[data-node-type="list_item"]
		+ *:not(div[data-node-type="list_item"]):not([data-node-type="to_do_item"]):not(h1):not(
			h2
		):not(h3):not(h4):not(h5):not(h6),
	div[data-node-type="to_do_item"]
		+ *:not(div[data-node-type="list_item"]):not([data-node-type="to_do_item"]):not(h1):not(
			h2
		):not(h3):not(h4):not(h5):not(h6) {
		@apply mt-4;
	}

	p,
	h1,
	.markdown h1,
	h2,
	.markdown h2,
	h3,
	.markdown h3,
	blockquote,
	callout,
	pre {
		@apply mb-4;
	}

	[data-node-type="compute_cell"] {
		& + p,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + ul,
		& + ol,
		& + blockquote,
		& + callout,
		& + pre {
			@apply mt-4;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		pre {
			@apply m-0;
		}
	}

	/* Double */

	h2,
	.markdown h2,
	h3,
	.markdown h3,
	h4,
	.markdown h4 {
		@apply mt-8;
	}

	/* Triple */

	h1,
	.markdown h1 {
		@apply mt-8;
	}

	/***************************************
	----> HORIZONTAL MARGINS & SPACING
	/**************************************/

	& .data-grid-header {
		@apply mx-auto
		w-[calc(768px-var(--default-padding)*2)]
		max-w-full
		pr-[calc(var(--default-padding)*2)]
		@lg:w-[calc(1024px-var(--default-padding))]
		@xl:w-[calc(1280px-var(--default-padding))];
	}

	/* This is outside the main .rich-text block because .canvas-content-row is not always a part of ProseMirror.
	The .rich-text.markdown > .compute-cell-output div > * insanity is necessary to target rich text rendered inside markdown cells. */
	&:not(.no-margin) > div[data-text-cell],
	&:not(.no-margin) .canvas-content-row,
	&:not(.no-margin) > p,
	&:not(.no-margin) > p.hr,
	&:not(.no-margin) > p.pre,
	&:not(.no-margin) > h1,
	&:not(.no-margin) > h2,
	&:not(.no-margin) > h3,
	&:not(.no-margin) > h4,
	&:not(.no-margin) > h5,
	&:not(.no-margin) > h6,
	&:not(.no-margin) > div[data-node-type="list_item"],
	&:not(.no-margin) > div[data-node-type="to_do_item"],
	&:not(.no-margin) > blockquote,
	&:not(.no-margin) > callout,
	&:not(.no-margin) > pre,
	.markdown .compute-cell-output > div > * {
		@apply mx-auto
		w-[768px]
		max-w-full
		pr-[var(--default-padding)]
		@lg:w-[1024px]
		@xl:w-[1280px];

		&.wide {
			@apply w-full;
		}
	}

	&:not(.no-margin)
		> div[data-text-cell]:not(div[data-node-type="list_item"]):not(
			div[data-node-type="to_do_item"]
		),
	&:not(.no-margin) .canvas-content-row,
	&:not(.no-margin) > p,
	&:not(.no-margin) > callout > p,
	&:not(.no-margin) > p.hr,
	&:not(.no-margin) > p.pre,
	&:not(.no-margin) > h1,
	&:not(.no-margin) > h2,
	&:not(.no-margin) > h3,
	&:not(.no-margin) > h4,
	&:not(.no-margin) > h5,
	&:not(.no-margin) > h6,
	&:not(.no-margin) > blockquote,
	&:not(.no-margin) > callout,
	&:not(.no-margin) > pre,
	.markdown ul,
	.markdown ol {
		@apply pl-[var(--default-padding)];
	}

	/* Narrower text columns */
	&:not(.no-margin) > div[data-text-cell],
	&:not(.no-margin) > p,
	&:not(.no-margin) > blockquote,
	&:not(.no-margin) > callout,
	&:not(.no-margin) > h1,
	&:not(.no-margin) > h2,
	&:not(.no-margin) > h3,
	&:not(.no-margin) > h4,
	&:not(.no-margin) > h5,
	&:not(.no-margin) > h6 {
		@apply lg:pr-[calc(var(--default-padding))]
		xl:pr-[calc(var(--default-padding)+5rem)]
		2xl:pr-[calc(var(--default-padding)+10rem)];
	}

	.markdown .compute-cell-output > div {
		& > p,
		& > ol li,
		& > ul li,
		& > h1,
		& > h2,
		& > h3,
		& > h4,
		& > h5,
		& > h6 {
			@apply lg:pr-[0rem]
			xl:pr-[5rem]
			2xl:pr-[10rem];
		}
	}

	/***************************************
	----> TABLES
	/**************************************/

	.markdown {
		table {
			@apply text-sm shadow-md;
		}
		th {
			@apply border-b
			border-l
			bg-tertiary
			px-2 py-2 text-left font-medium text-secondary;

			&:first-child {
				@apply rounded-tl border-l-0 shadow-border-top-left;
			}
			&:last-child {
				@apply rounded-tr border-r-0 shadow-border-top-right;
			}
		}

		tr:nth-child(even) {
			@apply bg-secondary;
		}

		td {
			@apply p-2;
		}

		th:first-child,
		td:first-child {
			@apply pl-3;
		}

		td:first-child {
			@apply shadow-border-left;
		}
		td:last-child {
			@apply shadow-border-right;
		}

		tr:last-child {
			td:first-child {
				@apply rounded-bl shadow-border-bottom-left;
			}
			td:last-child {
				@apply rounded-br shadow-border-bottom-right;
			}
		}
	}

	/***************************************
	----> OBSERVABLE OVERRIDES
	The selectors are weird because
	we don't have access to the markup
	/**************************************/

	.compute-cell-output [class*="-table"] {
		@apply m-0
		rounded-md
		border
		text-sm
		shadow-md;

		thead {
			tr {
				th {
					@apply border-b
					border-primary
					px-1
					py-1.5
					text-left
					text-secondary;

					&:last-child {
						@apply border-r;
					}
				}
			}
		}

		tbody {
			tr {
				&:nth-child(even) {
					@apply bg-tertiary;
				}

				td {
					@apply border-b-0
					border-primary
					px-1;

					&:last-child {
						@apply border-r;
					}
				}
			}
		}
	}

	/* Rules that make WebKit behave better. */
	> div[data-node-type="list_item"] > p.empty-node,
	> p.empty-node {
		/* If the document has no content at all, this is required for WebKit to show a blinking cursor. */
		&::after {
			content: " ";
			float: left; /* I can not tell you why this works but it does */
			height: 1px; /* without the height, empty paragraphs are slightly taller when selected and causes content below to shift */
			clear: both; /* For safety because floats can be gross if not cleared */
		}
	}

	/* If the only content of a document is an empty list item, clicking out of the doc hides it entirely. This works around that. Don't ask why because I do not have an answer. */
	> div[data-node-type="list_item"] > p {
		&:has(:only-child.ProseMirror-trailingBreak) {
			&::before {
				content: " ";
			}
		}
	}
}
