.value-error {
	@apply flex 
        items-start
        space-x-2
        bg-tertiary
        p-2
        font-mono
        text-sm
        font-normal
        text-negative;

	svg {
		@apply fill-icon-negative-default;
	}
}
