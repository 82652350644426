/* TODO: Convert this to a reusable component that doesn't use css modules */

.overflow-menu--button {
	@apply inline-flex
        w-full
        items-center
        rounded-none
        px-4
        py-2
        text-left
        text-sm
        focus:outline-none;

	&.overflow-menu--button__negative {
		@apply text-negative;
	}
}

.overflow-menu--button-sm {
	@apply py-1;
}

.overflow-menu--button:hover {
	@apply bg-brand
        text-inverse-primary;
}

.overflow-menu--button__negative:hover {
	@apply bg-negative;
}

.overflow-menu--button--icon {
	@apply mr-2
        fill-icon-default;
}

.overflow-menu--button--icon__negative {
	@apply text-negative;
}

.overflow-menu--button:hover .overflow-menu--button--icon {
	@apply fill-icon-inverse-default;
}
