.theme-oscar-mayer {
	/* ===========
    === Global ===
    =========== */
	--brand-primary-light: randomColor("brand");
	--brand-primary: randomColor("brand");
	--brand-primary-hover: randomColor("brand");
	--brand-primary-focus: randomColor("brand");
	--brand-primary-active: randomColor("brand");
	--brand-primary-disabled: randomColor("brand");

	--brand-primary-translucent: randomColor("brand");
	--brand-primary-translucent-hover: randomColor("brand");
	--brand-primary-translucent-focus: randomColor("brand");
	--brand-primary-translucent-active: randomColor("brand");
	--brand-primary-translucent-disabled: randomColor("brand");

	--brand-primary-translucent-dark: randomColor("brand");
	--brand-primary-translucent-dark-hover: randomColor("brand");
	--brand-primary-translucent-dark-focus: randomColor("brand");
	--brand-primary-translucent-dark-active: randomColor("brand");
	--brand-primary-translucent-dark-disabled: randomColor("brand");

	--state-negative: randomColor("negative");
	--state-negative-hover: randomColor("negative");
	--state-negative-focus: randomColor("negative");
	--state-negative-active: randomColor("negative");
	--state-negative-disabled: randomColor("negative");

	--state-negative-light: randomColor("negative");
	--state-negative-light-hover: randomColor("negative");
	--state-negative-light-focus: randomColor("negative");
	--state-negative-light-active: randomColor("negative");
	--state-negative-light-disabled: randomColor("negative");

	--state-positive: randomColor("positive");
	--state-positive-hover: randomColor("positive");
	--state-positive-focus: randomColor("positive");
	--state-positive-active: randomColor("positive");
	--state-positive-disabled: randomColor("positive");

	--state-positive-light: randomColor("positive");
	--state-positive-light-hover: randomColor("positive");
	--state-positive-light-focus: randomColor("positive");
	--state-positive-light-active: randomColor("positive");
	--state-positive-light-disabled: randomColor("positive");

	--state-warning: randomColor("warning");
	--state-warning-hover: randomColor("warning");
	--state-warning-focus: randomColor("warning");
	--state-warning-active: randomColor("warning");
	--state-warning-disabled: randomColor("warning");

	--state-warning-light: randomColor("warning");
	--state-warning-light-hover: randomColor("warning");
	--state-warning-light-focus: randomColor("warning");
	--state-warning-light-active: randomColor("warning");
	--state-warning-light-disabled: randomColor("warning");

	--state-info: randomColor("info");
	--state-info-hover: randomColor("info");
	--state-info-focus: randomColor("info");
	--state-info-active: randomColor("info");
	--state-info-disabled: randomColor("info");

	--state-info-light: randomColor("info");
	--state-info-light-hover: randomColor("info");
	--state-info-light-focus: randomColor("info");
	--state-info-light-active: randomColor("info");
	--state-info-light-disabled: randomColor("info");

	--bg-flash: randomColor("bg");

	--neutral-0: randomColor("neutral");
	--neutral-50: randomColor("neutral");
	--neutral-100: randomColor("neutral");
	--neutral-200: randomColor("neutral");
	--neutral-300: randomColor("neutral");
	--neutral-400: randomColor("neutral");
	--neutral-500: randomColor("neutral");
	--neutral-600: randomColor("neutral");
	--neutral-700: randomColor("neutral");
	--neutral-800: randomColor("neutral");
	--neutral-900: randomColor("neutral");
	--neutral-950: randomColor("neutral");
	--neutral-1000: randomColor("neutral");

	/* Focus rings */
	--focus-ring-default: randomColor();
	--focus-ring-negative: randomColor();
	--focus-ring-positive: randomColor();

	/* Backgrounds (used for things like navbars, main backgrounds, etc */
	--bg-primary: randomColor("bg");
	--bg-secondary: randomColor("bg");
	--bg-tertiary: randomColor("bg");
	--bg-quaternary: randomColor("bg");
	--bg-quinary: randomColor("bg");

	--bg-inverse-primary: randomColor("bg");
	--bg-inverse-secondary: randomColor("bg");
	--bg-inverse-tertiary: randomColor("bg");
	--bg-inverse-quaternary: randomColor("bg");
	--bg-inverse-quinary: randomColor("bg");

	--bg-translucent-default: randomColor("bg");
	--bg-translucent-hover: randomColor("bg");
	--bg-translucent-focus: randomColor("bg");
	--bg-translucent-active: randomColor("bg");
	--bg-translucent-disabled: randomColor("bg");

	--bg-translucent-primary-default: randomColor("bg");
	--bg-translucent-primary-hover: randomColor("bg");
	--bg-translucent-primary-focus: randomColor("bg");
	--bg-translucent-primary-active: randomColor("bg");
	--bg-translucent-primary-disabled: randomColor("bg");

	--bg-translucent-positive-default: randomColor("bg");
	--bg-translucent-positive-hover: randomColor("bg");
	--bg-translucent-positive-focus: randomColor("bg");
	--bg-translucent-positive-active: randomColor("bg");
	--bg-translucent-positive-disabled: randomColor("bg");

	--bg-translucent-negative-default: randomColor("bg");
	--bg-translucent-negative-hover: randomColor("bg");
	--bg-translucent-negative-focus: randomColor("bg");
	--bg-translucent-negative-active: randomColor("bg");
	--bg-translucent-negative-disabled: randomColor("bg");

	/* TODO: make inverse */
	--bg-translucent-dark: randomColor("bg");
	--bg-translucent-darker: randomColor("bg");

	/* Border tokens */
	--border-primary: randomColor("neutral");
	--border-primary-hover: randomColor("neutral");
	--border-primary-focus: randomColor("neutral");
	--border-primary-active: randomColor("neutral");
	--border-primary-disabled: randomColor("neutral");

	--border-inverse-primary: randomColor("neutral");
	--border-inverse-primary-hover: randomColor("neutral");
	--border-inverse-primary-focus: randomColor("neutral");
	--border-inverse-primary-active: randomColor("neutral");
	--border-inverse-primary-disabled: randomColor("neutral");

	/*
	"inverse-primary": `rgba(${chroma(colors.white).rgb()}, 0.25)`,
	"inverse-primary-hover": `rgba(${chroma(colors.white).rgb()}, 0.3)`,
	"inverse-primary-focus": `rgba(${chroma(colors.white).rgb()}, 0.35)`,
	"inverse-primary-active": `rgba(${chroma(colors.white).rgb()}, 0.4)`,
	"inverse-primary-disabled": `rgba(${chroma(colors.white).rgb()}, 0.25)`,
	*/

	/* TODO: Are these used? */
	--border-inverse-primary-solid: randomColor("neutral");
	--border-inverse-primary-solid-hover: randomColor("neutral");
	--border-inverse-primary-solid-focus: randomColor("neutral");
	--border-inverse-primary-solid-active: randomColor("neutral");
	--border-inverse-primary-solid-disabled: randomColor("neutral");

	--border-interactive-default: randomColor("neutral");
	--border-interactive-hover: randomColor("neutral");
	--border-interactive-focus: randomColor("neutral");
	--border-interactive-active: randomColor("neutral");
	--border-interactive-disabled: randomColor("neutral");

	/* Text color tokens */

	/*
	const translucencyDefault = 0.0;
	const translucencyHover = 0.06;
	const translucencyFocus = 0.06;
	const translucencyActive = 0.15;
	const translucencyDisabled = -0.3;

	"primary": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.91 + translucencyDefault})`,
	"primary-hover": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.91 + translucencyHover})`,
	"primary-focus": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.91 + translucencyFocus})`,
	"primary-active": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.91 + translucencyActive
	})`,
	"primary-disabled": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.7 + translucencyDisabled
	})`,
	"primary-solid": colors.gray[800],
	"primary-solid-hover": colors.gray[900],
	"primary-solid-focus": colors.gray[900],
	"primary-solid-active": colors.gray[950],
	"primary-solid-disabled": colors.gray[400],
	"secondary": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.62 + translucencyDefault})`,
	"secondary-hover": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.66 + translucencyHover
	})`,
	"secondary-focus": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.66 + translucencyFocus
	})`,
	"secondary-active": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.69 + translucencyActive
	})`,
	"secondary-disabled": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.7 + translucencyDisabled
	})`,
	"secondary-solid": colors.gray[500],
	"secondary-solid-hover": colors.gray[600],
	"secondary-solid-focus": colors.gray[600],
	"secondary-solid-active": colors.gray[700],
	"secondary-solid-disabled": colors.gray[400],
	"tertiary": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.5 + translucencyDefault})`,
	"tertiary-hover": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.53 + translucencyHover})`,
	"tertiary-focus": `rgb(${chroma(colors.gray[900]).rgb()}, ${0.53 + translucencyFocus})`,
	"tertiary-active": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.58 + translucencyActive
	})`,
	"tertiary-disabled": `rgb(${chroma(colors.gray[900]).rgb()}, ${
		0.7 + translucencyDisabled
	})`,
	"tertiary-solid": colors.gray[400],
	"tertiary-solid-hover": colors.gray[500],
	"tertiary-solid-focus": colors.gray[500],
	"tertiary-solid-active": colors.gray[600],
	"tertiary-solid-disabled": colors.gray[400],
	"inverse-primary": `rgb(${chroma(colors.white).rgb()}, ${0.95 + translucencyDefault})`,
	"inverse-primary-hover": `rgb(${chroma(colors.white).rgb()}, ${
		0.95 + translucencyHover
	})`,
	"inverse-primary-focus": `rgb(${chroma(colors.white).rgb()}, ${
		0.95 + translucencyFocus
	})`,
	"inverse-primary-active": `rgb(${chroma(colors.white).rgb()}, ${
		0.95 + translucencyActive
	})`,
	"inverse-primary-disabled": `rgb(${chroma(colors.white).rgb()}, ${
		0.95 + translucencyDisabled
	})`,
	"inverse-primary-solid": colors.gray[50],
	"inverse-primary-solid-hover": colors.white,
	"inverse-primary-solid-focus": colors.white,
	"inverse-primary-solid-active": colors.white,
	"inverse-primary-solid-disabled": colors.gray[300],
	"inverse-secondary": `rgb(${chroma(colors.white).rgb()}, ${
		0.87 + translucencyDefault
	})`,
	"inverse-secondary-hover": `rgb(${chroma(colors.white).rgb()}, ${
		0.87 + translucencyHover
	})`,
	"inverse-secondary-focus": `rgb(${chroma(colors.white).rgb()}, ${
		0.87 + translucencyFocus
	})`,
	"inverse-secondary-active": `rgb(${chroma(colors.white).rgb()}, ${
		0.87 + translucencyActive
	})`,
	"inverse-secondary-disabled": `rgb(${chroma(colors.white).rgb()}, ${
		1.0 + translucencyDisabled
	})`,
	"inverse-secondary-solid": colors.gray[200],
	"inverse-secondary-solid-hover": colors.gray[100],
	"inverse-secondary-solid-focus": colors.gray[100],
	"inverse-secondary-solid-active": colors.gray[50],
	"inverse-secondary-solid-disabled": colors.gray[300],
	"inverse-tertiary": `rgb(${chroma(colors.white).rgb()}, ${0.75 + translucencyDefault})`,
	"inverse-tertiary-hover": `rgb(${chroma(colors.white).rgb()}, ${
		0.75 + translucencyHover
	})`,
	"inverse-tertiary-focus": `rgb(${chroma(colors.white).rgb()}, ${
		0.75 + translucencyFocus
	})`,
	"inverse-tertiary-active": `rgb(${chroma(colors.white).rgb()}, ${
		0.75 + translucencyActive
	})`,
	"inverse-tertiary-disabled": `rgb(${chroma(colors.white).rgb()}, ${
		0.75 + translucencyDisabled
	})`,
	"inverse-tertiary-solid": colors.gray[300],
	"inverse-tertiary-solid-hover": colors.gray[200],
	"inverse-tertiary-solid-focus": colors.gray[200],
	"inverse-tertiary-solid-active": colors.gray[100],
	"inverse-tertiary-solid-disabled": colors.gray[300],
	*/
	--text-primary: randomColor("text");
	--text-primary-hover: randomColor("text");
	--text-primary-focus: randomColor("text");
	--text-primary-active: randomColor("text");
	--text-primary-disabled: randomColor("text");

	--text-primary-translucent: randomColor("text");
	--text-primary-translucent-hover: randomColor("text");
	--text-primary-translucent-focus: randomColor("text");
	--text-primary-translucent-active: randomColor("text");
	--text-primary-translucent-disabled: randomColor("text");

	--text-secondary: randomColor("text");
	--text-secondary-hover: randomColor("text");
	--text-secondary-focus: randomColor("text");
	--text-secondary-active: randomColor("text");
	--text-secondary-disabled: randomColor("text");

	--text-secondary-translucent: randomColor("text");
	--text-secondary-translucent-hover: randomColor("text");
	--text-secondary-translucent-focus: randomColor("text");
	--text-secondary-translucent-active: randomColor("text");
	--text-secondary-translucent-disabled: randomColor("text");

	--text-tertiary: randomColor("text");
	--text-tertiary-hover: randomColor("text");
	--text-tertiary-focus: randomColor("text");
	--text-tertiary-active: randomColor("text");
	--text-tertiary-disabled: randomColor("text");

	--text-tertiary-translucent: randomColor("text");
	--text-tertiary-translucent-hover: randomColor("text");
	--text-tertiary-translucent-focus: randomColor("text");
	--text-tertiary-translucent-active: randomColor("text");
	--text-tertiary-translucent-disabled: randomColor("text");

	--text-inverse-primary: randomColor("text");
	--text-inverse-primary-hover: randomColor("text");
	--text-inverse-primary-focus: randomColor("text");
	--text-inverse-primary-active: randomColor("text");
	--text-inverse-primary-disabled: randomColor("text");

	--text-inverse-primary-translucent: randomColor("text");
	--text-inverse-primary-translucent-hover: randomColor("text");
	--text-inverse-primary-translucent-focus: randomColor("text");
	--text-inverse-primary-translucent-active: randomColor("text");
	--text-inverse-primary-translucent-disabled: randomColor("text");

	--text-inverse-secondary: randomColor("text");
	--text-inverse-secondary-hover: randomColor("text");
	--text-inverse-secondary-focus: randomColor("text");
	--text-inverse-secondary-active: randomColor("text");
	--text-inverse-secondary-disabled: randomColor("text");

	--text-inverse-secondary-translucent: randomColor("text");
	--text-inverse-secondary-translucent-hover: randomColor("text");
	--text-inverse-secondary-translucent-focus: randomColor("text");
	--text-inverse-secondary-translucent-active: randomColor("text");
	--text-inverse-secondary-translucent-disabled: randomColor("text");

	--text-inverse-tertiary: randomColor("text");
	--text-inverse-tertiary-hover: randomColor("text");
	--text-inverse-tertiary-focus: randomColor("text");
	--text-inverse-tertiary-active: randomColor("text");
	--text-inverse-tertiary-disabled: randomColor("text");

	--text-inverse-tertiary-translucent: randomColor("text");
	--text-inverse-tertiary-translucent-hover: randomColor("text");
	--text-inverse-tertiary-translucent-focus: randomColor("text");
	--text-inverse-tertiary-translucent-active: randomColor("text");
	--text-inverse-tertiary-translucent-disabled: randomColor("text");

	/*
	"syntaxNormal": "#1b1e23",
	"syntaxComment": "#a9b0bc",
	"syntaxNumber": "#20a5ba",
	"syntaxKeyword": "#3182bd",
	"syntaxAtom": "#10a778",
	"syntaxString": "#008ec4",
	"syntaxError": "#e7040f",
	"syntaxUnknownVariable": "#838383",
	"syntaxKnownVariable": "#005f87",
	"syntaxMatchbracket": "#20bbfc",
	"syntaxKey": "#6636b4",
	"syntaxForbidden": "#e377c2",
	"syntaxPrototypeKey": "#aaaaaa",
	*/

	--text-syntax-normal: randomColor("text");
	--text-syntax-comment: randomColor("text");
	--text-syntax-number: randomColor("text");
	--text-syntax-keyword: randomColor("text");
	--text-syntax-atom: randomColor("text");
	--text-syntax-string: randomColor("text");
	--text-syntax-error: randomColor("text");
	--text-syntax-unknownVariable: randomColor("text");
	--text-syntax-knownVariable: randomColor("text");
	--text-syntax-matchbracket: randomColor("text");
	--text-syntax-key: randomColor("text");
	--text-syntax-forbidden: randomColor("text");
	--text-syntax-prototypeKey: randomColor("text");

	--text-syntax-inverse-normal: randomColor("text");
	--text-syntax-inverse-comment: randomColor("text");
	--text-syntax-inverse-number: randomColor("text");
	--text-syntax-inverse-keyword: randomColor("text");
	--text-syntax-inverse-atom: randomColor("text");
	--text-syntax-inverse-string: randomColor("text");
	--text-syntax-inverse-error: randomColor("text");
	--text-syntax-inverse-unknownVariable: randomColor("text");
	--text-syntax-inverse-knownVariable: randomColor("text");
	--text-syntax-inverse-matchbracket: randomColor("text");
	--text-syntax-inverse-key: randomColor("text");
	--text-syntax-inverse-forbidden: randomColor("text");
	--text-syntax-inverse-prototypeKey: randomColor("text");

	/* Icon tokens */
	--icon-default: randomColor("icon");
	--icon-hover: randomColor("icon");
	--icon-focus: randomColor("icon");
	--icon-active: randomColor("icon");
	--icon-disabled: randomColor("icon");

	--icon-inverse-default: randomColor("icon");
	--icon-inverse-hover: randomColor("icon");
	--icon-inverse-focus: randomColor("icon");
	--icon-inverse-active: randomColor("icon");
	--icon-inverse-disabled: randomColor("icon");

	/* Icon negative tokens */
	--icon-negative-default: randomColor("negative");
	--icon-negative-hover: randomColor("negative");
	--icon-negative-focus: randomColor("negative");
	--icon-negative-active: randomColor("negative");
	--icon-negative-disabled: randomColor("negative");

	/* Icon positive tokens */
	--icon-positive-default: randomColor("positive");
	--icon-positive-hover: randomColor("positive");
	--icon-positive-active: randomColor("positive");
	--icon-positive-focus: randomColor("positive");
	--icon-positive-disabled: randomColor("positive");

	/* Icon warning tokens */
	--icon-warning-default: randomColor("warning");
	--icon-warning-hover: randomColor("warning");
	--icon-warning-active: randomColor("warning");
	--icon-warning-focus: randomColor("warning");
	--icon-warning-disabled: randomColor("warning");

	/* ============
    === Buttons ===
    ============ */

	/* Primary */
	--button-primary-default-bg: randomColor("bg");
	--button-primary-default-text: randomColor("text");
	--button-primary-default-enhancer: randomColor("icon");

	--button-primary-hover-bg: randomColor("bg");
	--button-primary-hover-text: randomColor("text");
	--button-primary-hover-enhancer: randomColor("icon");

	--button-primary-focus-bg: randomColor("bg");
	--button-primary-focus-text: randomColor("text");
	--button-primary-focus-enhancer: randomColor("icon");
	--button-primary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-primary-active-bg: randomColor("bg");
	--button-primary-active-text: randomColor("text");
	--button-primary-active-enhancer: randomColor("icon");

	--button-primary-disabled-bg: randomColor("bg");
	--button-primary-disabled-text: randomColor("text");
	--button-primary-disabled-enhancer: randomColor("icon");

	/* Secondary */
	--button-secondary-default-bg: randomColor("bg");
	--button-secondary-default-border: red;
	--button-secondary-default-text: randomColor("text");
	--button-secondary-default-enhancer: randomColor("icon");

	--button-secondary-hover-bg: randomColor("bg");
	--button-secondary-hover-border: red;
	--button-secondary-hover-text: randomColor("text");
	--button-secondary-hover-enhancer: randomColor("icon");

	--button-secondary-focus-bg: randomColor("bg");
	--button-secondary-focus-border: red;
	--button-secondary-focus-text: randomColor("text");
	--button-secondary-focus-enhancer: randomColor("icon");
	--button-secondary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-secondary-active-bg: randomColor("bg");
	--button-secondary-active-border: red;
	--button-secondary-active-text: randomColor("text");
	--button-secondary-active-enhancer: randomColor("icon");

	--button-secondary-disabled-bg: randomColor("bg");
	--button-secondary-disabled-border: red;
	--button-secondary-disabled-text: randomColor("text");
	--button-secondary-disabled-enhancer: randomColor("icon");

	/* Tertiary */
	--button-tertiary-default-bg: randomColor("bg");
	--button-tertiary-default-text: randomColor("text");
	--button-tertiary-default-enhancer: randomColor("icon");

	--button-tertiary-hover-bg: randomColor("bg");
	--button-tertiary-hover-text: randomColor("text");
	--button-tertiary-hover-enhancer: randomColor("icon");

	--button-tertiary-focus-bg: randomColor("bg");
	--button-tertiary-focus-text: randomColor("text");
	--button-tertiary-focus-enhancer: randomColor("icon");
	--button-tertiary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-tertiary-active-bg: randomColor("bg");
	--button-tertiary-active-text: randomColor("text");
	--button-tertiary-active-enhancer: randomColor("icon");

	--button-tertiary-disabled-bg: randomColor("bg");
	--button-tertiary-disabled-text: randomColor("text");
	--button-tertiary-disabled-enhancer: randomColor("icon");

	/*
     * CONTAINED BUTTON.
     */

	/* CONTAINED POSITIVE BUTTON. */
	--button-contained-positive-default-bg: randomColor("bg");
	--button-contained-positive-default-text: randomColor("text");
	--button-contained-positive-default-enhancer: randomColor("icon");

	--button-contained-positive-hover-bg: randomColor("bg");
	--button-contained-positive-hover-text: randomColor("text");
	--button-contained-positive-hover-enhancer: randomColor("icon");

	--button-contained-positive-focus-bg: randomColor("bg");
	--button-contained-positive-focus-text: randomColor("text");
	--button-contained-positive-focus-enhancer: randomColor("icon");
	--button-contained-positive-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-contained-positive-active-bg: randomColor("bg");
	--button-contained-positive-active-text: randomColor("text");
	--button-contained-positive-active-enhancer: randomColor("icon");

	--button-contained-positive-disabled-bg: randomColor("bg");
	--button-contained-positive-disabled-text: randomColor("text");
	--button-contained-positive-disabled-enhancer: randomColor("icon");

	/* CONTAINED NEGATIVE BUTTON. */
	--button-contained-negative-default-bg: randomColor("bg");
	--button-contained-negative-default-text: randomColor("text");
	--button-contained-negative-default-enhancer: randomColor("icon");

	--button-contained-negative-hover-bg: randomColor("bg");
	--button-contained-negative-hover-text: randomColor("text");
	--button-contained-negative-hover-enhancer: randomColor("icon");

	--button-contained-negative-focus-bg: randomColor("bg");
	--button-contained-negative-focus-text: randomColor("text");
	--button-contained-negative-focus-enhancer: randomColor("icon");
	--button-contained-negative-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-contained-negative-active-bg: randomColor("bg");
	--button-contained-negative-active-text: randomColor("text");
	--button-contained-negative-active-enhancer: randomColor("icon");

	--button-contained-negative-disabled-bg: randomColor("bg");
	--button-contained-negative-disabled-text: randomColor("text");
	--button-contained-negative-disabled-enhancer: randomColor("icon");

	/* CONTAINED PRIMARY BUTTON. */
	--button-contained-primary-default-bg: randomColor("bg");
	--button-contained-primary-default-text: randomColor("text");
	--button-contained-primary-default-enhancer: randomColor("icon");

	--button-contained-primary-hover-bg: randomColor("bg");
	--button-contained-primary-hover-text: randomColor("text");
	--button-contained-primary-hover-enhancer: randomColor("icon");

	--button-contained-primary-focus-bg: randomColor("bg");
	--button-contained-primary-focus-text: randomColor("text");
	--button-contained-primary-focus-enhancer: randomColor("icon");
	--button-contained-primary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-contained-primary-active-bg: randomColor("bg");
	--button-contained-primary-active-text: randomColor("text");
	--button-contained-primary-active-enhancer: randomColor("icon");

	--button-contained-primary-disabled-bg: randomColor("bg");
	--button-contained-primary-disabled-text: randomColor("text");
	--button-contained-primary-disabled-enhancer: randomColor("icon");

	/* CONTAINED SECONDARY BUTTON. */
	--button-contained-secondary-default-bg: randomColor("bg");
	--button-contained-secondary-default-text: randomColor("text");
	--button-contained-secondary-default-enhancer: randomColor("icon");

	--button-contained-secondary-hover-bg: randomColor("bg");
	--button-contained-secondary-hover-text: randomColor("text");
	--button-contained-secondary-hover-enhancer: randomColor("icon");

	--button-contained-secondary-focus-bg: randomColor("bg");
	--button-contained-secondary-focus-text: randomColor("text");
	--button-contained-secondary-focus-enhancer: randomColor("icon");
	--button-contained-secondary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-contained-secondary-active-bg: randomColor("bg");
	--button-contained-secondary-active-text: randomColor("text");
	--button-contained-secondary-active-enhancer: randomColor("icon");

	--button-contained-secondary-disabled-bg: randomColor("bg");
	--button-contained-secondary-disabled-text: randomColor("text");
	--button-contained-secondary-disabled-enhancer: randomColor("icon");

	/*
     * OUTLINED BUTTON.
     */

	/* OUTLINED PRIMARY BUTTON. */
	--button-outlined-primary-default-bg: randomColor("bg");
	--button-outlined-primary-default-border: red;
	--button-outlined-primary-default-text: randomColor("text");
	--button-outlined-primary-default-enhancer: randomColor("icon");

	--button-outlined-primary-hover-bg: randomColor("bg");
	--button-outlined-primary-hover-border: red;
	--button-outlined-primary-hover-text: randomColor("text");
	--button-outlined-primary-hover-enhancer: randomColor("icon");

	--button-outlined-primary-focus-bg: randomColor("bg");
	--button-outlined-primary-focus-border: red;
	--button-outlined-primary-focus-text: randomColor("text");
	--button-outlined-primary-focus-enhancer: randomColor("icon");
	--button-outlined-primary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-outlined-primary-active-bg: randomColor("bg");
	--button-outlined-primary-active-border: red;
	--button-outlined-primary-active-text: randomColor("text");
	--button-outlined-primary-active-enhancer: randomColor("icon");

	--button-outlined-primary-disabled-bg: randomColor("bg");
	--button-outlined-primary-disabled-border: red;
	--button-outlined-primary-disabled-text: randomColor("text");
	--button-outlined-primary-disabled-enhancer: randomColor("icon");

	/* OUTLINED SECONDARY BUTTON. */
	--button-outlined-secondary-default-bg: randomColor("bg");
	--button-outlined-secondary-default-border: red;
	--button-outlined-secondary-default-text: randomColor("text");
	--button-outlined-secondary-default-enhancer: randomColor("icon");

	--button-outlined-secondary-hover-bg: randomColor("bg");
	--button-outlined-secondary-hover-border: red;
	--button-outlined-secondary-hover-text: randomColor("text");
	--button-outlined-secondary-hover-enhancer: randomColor("icon");

	--button-outlined-secondary-focus-bg: randomColor("bg");
	--button-outlined-secondary-focus-border: red;
	--button-outlined-secondary-focus-text: randomColor("text");
	--button-outlined-secondary-focus-enhancer: randomColor("icon");
	--button-outlined-secondary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-outlined-secondary-active-bg: randomColor("bg");
	--button-outlined-secondary-active-border: red;
	--button-outlined-secondary-active-text: randomColor("text");
	--button-outlined-secondary-active-enhancer: randomColor("icon");

	--button-outlined-secondary-disabled-bg: randomColor("bg");
	--button-outlined-secondary-disabled-border: red;
	--button-outlined-secondary-disabled-text: randomColor("text");
	--button-outlined-secondary-disabled-enhancer: randomColor("icon");

	/* OUTLINED POSITIVE BUTTON. */
	--button-outlined-positive-default-bg: randomColor("bg");
	--button-outlined-positive-default-border: red;
	--button-outlined-positive-default-text: randomColor("text");
	--button-outlined-positive-default-enhancer: randomColor("icon");

	--button-outlined-positive-hover-bg: randomColor("bg");
	--button-outlined-positive-hover-border: red;
	--button-outlined-positive-hover-text: randomColor("text");
	--button-outlined-positive-hover-enhancer: randomColor("icon");

	--button-outlined-positive-focus-bg: randomColor("bg");
	--button-outlined-positive-focus-border: red;
	--button-outlined-positive-focus-text: randomColor("text");
	--button-outlined-positive-focus-enhancer: randomColor("icon");
	--button-outlined-positive-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-outlined-positive-active-bg: randomColor("bg");
	--button-outlined-positive-active-border: red;
	--button-outlined-positive-active-text: randomColor("text");
	--button-outlined-positive-active-enhancer: randomColor("icon");

	--button-outlined-positive-disabled-bg: randomColor("bg");
	--button-outlined-positive-disabled-border: red;
	--button-outlined-positive-disabled-text: randomColor("text");
	--button-outlined-positive-disabled-enhancer: randomColor("icon");

	/* OUTLINED NEGATIVE BUTTON. */
	--button-outlined-negative-default-bg: randomColor("bg");
	--button-outlined-negative-default-border: red;
	--button-outlined-negative-default-text: randomColor("text");
	--button-outlined-negative-default-enhancer: randomColor("icon");

	--button-outlined-negative-hover-bg: randomColor("bg");
	--button-outlined-negative-hover-border: red;
	--button-outlined-negative-hover-text: randomColor("text");
	--button-outlined-negative-hover-enhancer: randomColor("icon");

	--button-outlined-negative-focus-bg: randomColor("bg");
	--button-outlined-negative-focus-border: red;
	--button-outlined-negative-focus-text: randomColor("text");
	--button-outlined-negative-focus-enhancer: randomColor("icon");
	--button-outlined-negative-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-outlined-negative-active-bg: randomColor("bg");
	--button-outlined-negative-active-border: red;
	--button-outlined-negative-active-text: randomColor("text");
	--button-outlined-negative-active-enhancer: randomColor("icon");

	--button-outlined-negative-disabled-bg: randomColor("bg");
	--button-outlined-negative-disabled-border: red;
	--button-outlined-negative-disabled-text: randomColor("text");
	--button-outlined-negative-disabled-enhancer: randomColor("icon");

	/*
     * TEXT BUTTON.
     */

	/* TEXT PRIMARY BUTTON. */
	--button-text-primary-default-bg: randomColor("bg");
	--button-text-primary-default-text: randomColor("text");
	--button-text-primary-default-enhancer: randomColor("icon");

	--button-text-primary-hover-bg: randomColor("bg");
	--button-text-primary-hover-text: randomColor("text");
	--button-text-primary-hover-enhancer: randomColor("icon");

	--button-text-primary-focus-bg: randomColor("bg");
	--button-text-primary-focus-text: randomColor("text");
	--button-text-primary-focus-enhancer: randomColor("icon");
	--button-text-primary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-text-primary-active-bg: randomColor("bg");
	--button-text-primary-active-text: randomColor("text");
	--button-text-primary-active-enhancer: randomColor("icon");

	--button-text-primary-disabled-bg: randomColor("bg");
	--button-text-primary-disabled-text: randomColor("text");
	--button-text-primary-disabled-enhancer: randomColor("icon");

	/* TEXT SECONDARY BUTTON */
	--button-text-secondary-default-bg: randomColor("bg");
	--button-text-secondary-default-text: randomColor("text");
	--button-text-secondary-default-enhancer: randomColor("icon");

	--button-text-secondary-hover-bg: randomColor("bg");
	--button-text-secondary-hover-text: randomColor("text");
	--button-text-secondary-hover-enhancer: randomColor("icon");

	--button-text-secondary-focus-bg: randomColor("bg");
	--button-text-secondary-focus-text: randomColor("text");
	--button-text-secondary-focus-enhancer: randomColor("icon");
	--button-text-secondary-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-text-secondary-active-bg: randomColor("bg");
	--button-text-secondary-active-text: randomColor("text");
	--button-text-secondary-active-enhancer: randomColor("icon");

	--button-text-secondary-disabled-bg: randomColor("bg");
	--button-text-secondary-disabled-text: randomColor("text");
	--button-text-secondary-disabled-enhancer: randomColor("icon");

	/* TEXT POSITIVE BUTTON. */
	--button-text-positive-default-bg: randomColor("bg");
	--button-text-positive-default-text: randomColor("text");
	--button-text-positive-default-enhancer: randomColor("icon");

	--button-text-positive-hover-bg: randomColor("bg");
	--button-text-positive-hover-text: randomColor("text");
	--button-text-positive-hover-enhancer: randomColor("icon");

	--button-text-positive-focus-bg: randomColor("bg");
	--button-text-positive-focus-text: randomColor("text");
	--button-text-positive-focus-enhancer: randomColor("icon");
	--button-text-positive-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-text-positive-active-bg: randomColor("bg");
	--button-text-positive-active-text: randomColor("text");
	--button-text-positive-active-enhancer: randomColor("icon");

	--button-text-positive-disabled-bg: randomColor("bg");
	--button-text-positive-disabled-text: randomColor("text");
	--button-text-positive-disabled-enhancer: randomColor("icon");

	/* TEXT NEGATIVE BUTTON. */
	--button-text-negative-default-bg: randomColor("bg");
	--button-text-negative-default-text: randomColor("text");
	--button-text-negative-default-enhancer: randomColor("icon");

	--button-text-negative-hover-bg: randomColor("bg");
	--button-text-negative-hover-text: randomColor("text");
	--button-text-negative-hover-enhancer: randomColor("icon");

	--button-text-negative-focus-bg: randomColor("bg");
	--button-text-negative-focus-text: randomColor("text");
	--button-text-negative-focus-enhancer: randomColor("icon");
	--button-text-negative-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-text-negative-active-bg: randomColor("bg");
	--button-text-negative-active-text: randomColor("text");
	--button-text-negative-active-enhancer: randomColor("icon");

	--button-text-negative-disabled-bg: randomColor("bg");
	--button-text-negative-disabled-text: randomColor("text");
	--button-text-negative-disabled-enhancer: randomColor("icon");

	/* Outlined */
	--button-outlined-default-bg: randomColor("bg");
	--button-outlined-default-border: orange;
	--button-outlined-default-text: randomColor("text");
	--button-outlined-default-enhancer: randomColor("icon");

	--button-outlined-hover-bg: randomColor("bg");
	--button-outlined-hover-border: orange;
	--button-outlined-hover-text: randomColor("text");
	--button-outlined-hover-enhancer: randomColor("icon");

	--button-outlined-focus-bg: randomColor("bg");
	--button-outlined-focus-border: orange;
	--button-outlined-focus-text: randomColor("text");
	--button-outlined-focus-enhancer: randomColor("icon");
	--button-outlined-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-outlined-active-bg: randomColor("bg");
	--button-outlined-active-border: orange;
	--button-outlined-active-text: randomColor("text");
	--button-outlined-active-enhancer: randomColor("icon");

	--button-outlined-disabled-bg: randomColor("bg");
	--button-outlined-disabled-border: orange;
	--button-outlined-disabled-text: randomColor("text");
	--button-outlined-disabled-enhancer: randomColor("icon");

	/* Negative */
	--button-negative-default-bg: randomColor("bg");
	--button-negative-default-text: randomColor("text");
	--button-negative-default-enhancer: randomColor("icon");

	--button-negative-hover-bg: randomColor("bg");
	--button-negative-hover-text: randomColor("text");
	--button-negative-hover-enhancer: randomColor("icon");

	--button-negative-focus-bg: randomColor("bg");
	--button-negative-focus-text: randomColor("text");
	--button-negative-focus-enhancer: randomColor("icon");
	--button-negative-focus-ring: randomColor("neutral");

	/* `active` is used for :active, pressed, toggled, mouseDown, etc */
	--button-negative-active-bg: randomColor("bg");
	--button-negative-active-text: randomColor("text");
	--button-negative-active-enhancer: randomColor("icon");

	--button-negative-disabled-bg: randomColor("bg");
	--button-negative-disabled-text: randomColor("text");
	--button-negative-disabled-enhancer: randomColor("icon");

	/* Negative Light */
	--button-negative-light-default-bg: randomColor("bg");
	--button-negative-light-default-text: randomColor("text");
	--button-negative-light-default-enhancer: randomColor("icon");

	--button-negative-light-hover-bg: randomColor("bg");
	--button-negative-light-hover-text: randomColor("text");
	--button-negative-light-hover-enhancer: randomColor("icon");

	--button-negative-light-focus-bg: randomColor("bg");
	--button-negative-light-focus-text: randomColor("text");
	--button-negative-light-focus-enhancer: randomColor("icon");
	--button-negative-light-focus-ring: randomColor("neutral");

	--button-negative-light-active-bg: randomColor("bg");
	--button-negative-light-active-text: randomColor("text");
	--button-negative-light-active-enhancer: randomColor("icon");

	--button-negative-light-disabled-bg: randomColor("bg");
	--button-negative-light-disabled-text: randomColor("text");
	--button-negative-light-disabled-enhancer: randomColor("icon");

	/* Positive */
	--button-positive-default-bg: randomColor("bg");
	--button-positive-default-text: randomColor("text");
	--button-positive-default-enhancer: randomColor("icon");

	--button-positive-hover-bg: randomColor("bg");
	--button-positive-hover-text: randomColor("text");
	--button-positive-hover-enhancer: randomColor("icon");

	--button-positive-focus-bg: randomColor("bg");
	--button-positive-focus-text: randomColor("text");
	--button-positive-focus-enhancer: randomColor("icon");
	--button-positive-focus-ring: randomColor("neutral");

	--button-positive-active-bg: randomColor("bg");
	--button-positive-active-text: randomColor("text");
	--button-positive-active-enhancer: randomColor("icon");

	--button-positive-disabled-bg: randomColor("bg");
	--button-positive-disabled-text: randomColor("text");
	--button-positive-disabled-enhancer: randomColor("icon");

	/* Positive Light */
	--button-positive-light-default-bg: randomColor("bg");
	--button-positive-light-default-text: randomColor("text");
	--button-positive-light-default-enhancer: randomColor("icon");

	--button-positive-light-hover-bg: randomColor("bg");
	--button-positive-light-hover-text: randomColor("text");
	--button-positive-light-hover-enhancer: randomColor("icon");

	--button-positive-light-focus-bg: randomColor("bg");
	--button-positive-light-focus-text: randomColor("text");
	--button-positive-light-focus-enhancer: randomColor("icon");
	--button-positive-light-focus-ring: randomColor("neutral");

	--button-positive-light-active-bg: randomColor("bg");
	--button-positive-light-active-text: randomColor("text");
	--button-positive-light-active-enhancer: randomColor("icon");

	--button-positive-light-disabled-bg: randomColor("bg");
	--button-positive-light-disabled-text: randomColor("text");
	--button-positive-light-disabled-enhancer: randomColor("icon");
}
